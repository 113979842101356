<template>
<div class="w-full h-full rounded-lg overflow-y-scroll" :class="`${appearance.background} ${appearance.textColour}`">
    <main class="relative flex">
        <div class="w-full h-full ">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <div class="divide-y divide-gray-200 lg:col-span-12">
                    <!-- Profile section -->
                    <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
                        <div>
                            <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                                Edit the collection
                            </h2>

                        </div>

                        <div v-if="loading" class="items-center h-full w-full flex mt-10">
                            <loader class="mx-auto" />
                        </div>

                        <div v-if="!loading" class="grid grid-cols-12 gap-6">
                            <div class="sm:col-start-1  col-span-12">
                                <label for="title" class="block text-sm font-medium leading-5">Title of your collection</label>
                                <input @focus="hideDropdown()" v-model="title" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5 text-black" />
                            </div>
                            <div class="sm:col-start-1 col-span-12">
                                <label for="description" class="block text-sm font-medium leading-5">Description of the collection</label>
                                <textarea rows="4" v-model="description" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black" />
                                </div>
            
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div v-if="!loading" class="flex px-6 mb-6">
          <div
            @click="update()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Update the collection
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import Icon from "@/components/pages/TemplateIcon";
import loader from "@/components/pages/TemplateLoaderDark";
import {
    store
} from "../../store/index";
import globalMixin from "../../mixins/globalMixin";

export default {
    mixins: [globalMixin],
    data() {
        return {
            loading: false,
            title: store.state.recording.activeCollection.metaData.title,
            description: store.state.recording.activeCollection.metaData.description,

            active: false,
            errored: "",
            lastEvaluatedKey: "na",
            recordingOptions: false
        };
    },
    methods: {
        async update() {
            this.loading = true

            console.log(this.item)

            await this.$RecordingCollectionService.update(this.item);
            store.commit('showModal', null)
            this.loading = false
        }
    },
    computed: {

        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        },
        categories() {

            return store.state.recording.categories
        },
        item() {
            return {
                pk: store.state.recording.activeCollection.pk,
                sk: store.state.recording.activeCollection.sk,
                metaData:{
                    title: this.title,
                    description: this.description,
                    author: `${store.state.userData.metaData.fullName.fullName}`,
                    createdAt: store.state.recording.activeCollection.metaData.createdAt
                }
            };
        }
    },
    components: {
        loader,

    },

};
</script>
