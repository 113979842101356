import { store } from "@/store/index.js"
import { PulseRepo } from "@/repo/PulseRepo.js"
const pulseRepo = new PulseRepo();


export default class PulseService {
    async create(element) {
        let result = {
            status: 200
        }
        try {
            console.log(element)
            
            const res = await pulseRepo.create(element)
            
            if(res.status == 200){
                console.log(res.data)
                console.log('above is res data')
                res.data.display = true;
                store.state.posts.unshift(res.data);
                store.commit('pulseCheck/addIncompletedPulses', res.data)
            }

  
            return res

        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }


    async fetchAllPulses(lastEvaluatedKey){
      
        let nextPage = lastEvaluatedKey
    
        //define a variable of loop => in order to load all records
        let index = 0
        let pulseSurveys = []

        while (index < 20) {
            
            // console.log(index)

            const res = await pulseRepo.fetch(nextPage)

            try {
                res.data.Items.forEach(el => {
                    pulseSurveys.push(el)
                });

            } catch (error) {
                alert('Oops something went wrong please try again')
            }
            if (res.data.LastEvaluatedKey == undefined || res.data.LastEvaluatedKey == null) {
                break;
            } else {
                nextPage = res.data.LastEvaluatedKey
            }
            index = index + 1
        }

        const toTimestamp = (strDate) => {  
            const dt = Date.parse(strDate);  
            return dt / 1000;  
        } 

        pulseSurveys = pulseSurveys.sort(function(x, y){
            return toTimestamp(x.metaData.timestamp) - toTimestamp(y.metaData.timestamp);
        })

        store.commit("pulseCheck/pulses", pulseSurveys.reverse())

        return true
    }

    async fetch(lastEvaluatedKey){
      
        //This is a function to fetch the pulses that current user need to complete. 
        let result = {
            status: true
        }

        let nextPage = lastEvaluatedKey
    
        //define a variable of loop => in order to load all records
        let index = 0
        let pulseSurveys = []
        let pulseSurveyIds = []

        while (index < 10) {
            
            // console.log(index)

            const res = await pulseRepo.fetch(nextPage)

            try {
               
                let pulsesCurrentUserNeedToComplete = store.state.userData.notifications
                
                res.data.Items.forEach(el => {
                    let findItem = pulsesCurrentUserNeedToComplete.find(userPulsePk => userPulsePk == el.pk)
                    if(findItem){

                        let exist = pulseSurveys.filter(data => data.pk == el.pk)
                        if(exist.length == 0){
                            pulseSurveys.push(el)
                            pulseSurveyIds.push(el.pk)
                        }
                    }
                });

            } catch (error) {
                result.status = false
                result.error = error;
            }

            

            if (res.data.LastEvaluatedKey == undefined || res.data.LastEvaluatedKey == null) {
                break;
            } else {
                nextPage = res.data.LastEvaluatedKey
            }

            index = index + 1
        }


        store.commit("pulseCheck/incompletedPulses", [])
        store.commit("pulseCheck/incompletedPulses", pulseSurveys)

        return result
    }
}