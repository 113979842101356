<template>
  <div class="w-full h-full rounded-lg overflow-y-scroll" :class="`${appearance.background} ${appearance.textColour}`">
    <main class="relative flex">
      <div class="w-full h-full ">
        <div
          class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
        >
          <div class="divide-y divide-gray-200 lg:col-span-12">
            <!-- Profile section -->
            <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
              <div>
                <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                  Create a team
                </h2>
              </div>

              <div class="grid grid-cols-12 gap-6">
                <div class="sm:col-start-1  col-span-12">
                  <label
                    for="title"
                    class="block text-sm font-medium leading-5"
                    :class="appearance.textColour"
                    >Team title</label
                  >
                  <input
                    v-model="title"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5"
                  />
                </div>

                <!-- <div class="sm:col-start-1 col-span-12">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5"
                    :class="appearance.textColour"
                    >Team leader emails</label
                  >
                  <input
                    v-model="emails"
                    id="emails"
                    type="text"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5"
                  />
                  <span class="text-xs text-blue-500"
                    >Comma seperated. Used to send alert emails about team members</span
                  >
                </div> -->

                <div class="col-span-12 col-span-12">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5"
                    :class="appearance.textColour"
                    >Color your team</label
                  >
                  <v-swatches v-model="color" inline></v-swatches>
                </div>

      
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div class="flex px-6 mb-6">
          <div
            @click="createTeam()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Create Team
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import moment from "moment";
import VSwatches from "vue-swatches";
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css";

export default {
  mixins: [globalMixin],
  data() {
    return {
      title: "",
      color: "",
      // emails: "",
      errored: "",
      lastEvaluatedKey: "na"
    };
  },
  methods: {

    createTeam() {
      // Validation
      if (
        this.title == "" ||
        this.color == "" 
      ) {
        this.errored = "All fields are required";
        return;
      }

      let config = {
        headers: {
          Authorization: this.token.IdToken
        }
      };

      // const _emails = this.emails.match(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g);

      let body = {
        metaData: {
          title: this.title,
          color: this.color
          // emails: _emails
        }
      };

      this.axios
        .post(
          `${process.env.VUE_APP_API_URL}/teams`,
          body,
          config
        )
        .then(res => {
          if (res.data) {
            // store add new team
            let _teams = store.state.teams
            _teams.Items.push(res.data)
            store.commit("teams", _teams)
            // also select that team
            store.commit("selectedTeam", res.data)
            // also set the level
            store.commit("level", "team")
            
            this.closeModal();
          }
        })
        .catch(async (error) => {
          this.errored = error;
        })
        .finally(() => (this.loading = false));
    },
    
  },
  computed: {
    appearance(){
      return store.state.light ?  this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
    },
    categories() {
      return store.state.teams;
    },
    item() {
      return {
        title: this.title,
        categoryData: this.category,
        description: this.description,
        features: this.features,
        link: this.link,
        author: `${this.userData.metaData.fullName}`,
        createdAt: moment().format("MMM DD, YYYY"),
        active: false,
        whiteList: this.selectedItems
      };
    }
  },
  mounted() {
    store.commit("categories", []);
  },
  components: { VSwatches }
};
</script>
