<template>
<div class="bg-blue-900 w-full rounded-lg flex flex-wrap h-screen">
    <div class="w-1/5 bg-blue-900"></div>
    <div class="w-3/5 p-3 bg-blue-900 ">

        <img :src="require(`@/assets/${audio.image}`)" class="w-full object-cover rounded-xl shadow-xl" />

        <div class="text-center font-bold text-2xl text-green-400 my-4 ">
            Now Playing
        </div>
        <div class="text-center font-bold text-3xl leading-none header text-white">
            {{ audio.title }}
        </div>
        <div class="text-center font-bold text-white header mt-2">
            With {{ audio.author }}
        </div>

    </div>
    <div class="w-1/5 bg-blue-900"></div>
</div>
<!-- <div class="bg-blue-900 fixed top-0 left-0 h-screen -mt-40 pt-40 w-full">
    <div class="bottom-0 top-0 absolute h-full w-full btmGradient"></div>

    <div class="h-full w-full relative">
      <div class="w-full p-10 relative">
        <img
          :src="require(`@/assets/${audio.image}`)"
          class="w-full h-full object-cover rounded-xl shadow-xl"
        />

        <div class="text-center font-bold text-2xl text-green-400 my-4">
          Now Playing
        </div>
        <div
          class="text-center font-bold text-3xl leading-none header text-white"
        >
          {{ audio.title }}
        </div>
        <div class="text-center font-bold text-white header mt-2">
          With {{ audio.author }}
        </div>
      </div>
      <div
        @click="closeModalFull()"
        class="absolute top-0 right-0 cursor-pointer hover:text-primaryBlue"
      >
        <svg
          class="w-10 h-10 text-white"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  </div> -->
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import {
    store
} from "../../store/index"
export default {
    mixins: [globalMixin],
    computed: {
        audio() {
            return store.state.audio;
        },
    }
};
</script>
