<template>
  <div id="app">
    <!-- Small Modal  -->
    <modal>
      <template v-slot:body>
        <modal-edit-event v-if="showModal == 'editEvent'" />
        <modal-delete-event v-if="showModal == 'deleteEvent'" />
        <modal-show-welcome v-if="showModal == 'showWelcome'" />
        <modal-show-loader v-if="showModal == 'showLoader'" />
        <modal-book-demo v-if="showModal == 'bookDemo'" />
        <modal-show-quick-note v-if="showModal == 'showQuickNote'" />
        <modal-create-team v-if="showModal == 'createTeam'" />
        <modal-delete-team v-if="showModal == 'deleteTeam'" />
        <modal-create-event v-if="showModal == 'createEvent'" />
        <modal-create-ticket v-if="showModal == 'createTicket'" />

        <modal-create-recording-collection
          v-if="showModal == 'createRecordingCollection'"
        />
        <modal-edit-recording-collection
          v-if="showModal == 'editeRecordingCollection'"
        />
        <modal-edit-team v-if="showModal == 'editTeam'" />
        <modal-edit-recording v-if="showModal == 'editRecording'" />
        <modal-event-details v-if="showModal == 'eventDetails'" />
        <modal-emoji v-if="showModal == 'emoji'" />
        <modal-pulse v-if="showModal == 'pulse'" />
        <modal-create-system v-if="showModal == 'createSystem'" />
        <modal-show-invite v-if="showModal == 'showInvite'" />
        <modal-show-intervention v-if="showModal == 'showIntervention'" />
        <modal-invite-emails v-if="showModal == 'inviteEmails'" />
        <modal-invite-email-manager v-if="showModal == 'inviteEmailManager'" />
        <modal-employee-settings v-if="showModal == 'employeeSettings'" />
        <modal-reset-password v-if="showModal == 'resetPassword'" />
        <modal-create-resource v-if="showModal == 'createResource'" />
        <modal-create-category v-if="showModal == 'createCategory'" />
        <modal-delete-post v-if="showModal == 'deletePost'" />
        <modal-create-pulse-survey v-if="showModal == 'createPulseSurvey'" />
        <modal-delete-resource v-if="showModal == 'deleteResource'" />
        <modal-edit-resource v-if="showModal == 'editResource'" />
        <modal-resource-category-list
          v-if="showModal == 'showResourceCategoryList'"
        />
        <modal-edit-category v-if="showModal == 'editCategory'" />
        <modal-show-info v-if="showModal == 'showInfo'" />
        <modal-show-resource v-if="showModal == 'showResource'" />
        <modal-delete-category v-if="showModal == 'deleteCategory'" />
        <modal-delete-recording v-if="showModal == 'deleteRecording'" />
        <modal-delete-recording-collection
          v-if="showModal == 'deleteRecordingCollection'"
        />
         <modal-create-recording v-if="showModal == 'createRecording'" />
      </template>
    </modal>

    <!-- Medium Modal -->
    <modal-medium>
      <template v-slot:body>
        <modal-show-recording v-if="showModalMedium == 'showRecording'" />
      </template>
    </modal-medium>

    <!-- Large Modal -->
    <modal-large>
      <template v-slot:body>
       
        <modal-graph v-if="showModalLarge == 'graphEmployee'" />
        <modal-graph v-if="showModalLarge == 'graph'" />
        <modal-management v-if="showModalLarge == 'management'" />
        <!-- <modal-create-action v-if="showModalLarge == 'createAction'" /> -->
      </template>
    </modal-large>

    <!-- Full Modal -->
    <modal-full>
      <template v-slot:body>
        <modal-show-meditation v-if="showModalFull == 'showMeditation'" />
      </template>
    </modal-full>

    <div
      class="relative"
      :class="showModal ? 'h-screen w-full overflow-hidden bg-dkBlue' : ''"
    >
      <div class="flex" :class="showModal ? 'blurScreen' : ''">
        <!-- Sidebar for business plugin  -->
        <!-- <business-sidebar v-if="authorized" class="hidden sm:flex-shrink" /> -->
        <transition name="navSlide">
          <business-sidebar-admin v-if="authorized" class="sm:hidden" />
        </transition>
        <!-- Main content  -->
       <router-view class="flex-grow"/>

        <!-- <router-view
          class="flex-grow"
          :class="userData && userData.hasRole != 'employee' ? 'sm:ml-64' : ''"
        /> -->
      </div>
    </div>

    <!-- <audio-container class="sm:hidden" /> -->
  </div>
</template>

<script>
//
// import audioContainer from "./components/pages/AudioContainer.vue";
// import businessSidebar from "./components/pages/AdminBusinessSideBar";
// import businessSidebarAdmin from "./components/pages/AdminBusinessSideBarMobile";
import userNavItemData from "./data/userNavItemData.json";
import globalMixin from "./mixins/globalMixin";
// Modals
import modal from "./components/pages/TemplateModal";
import modalMedium from "./components/pages/TemplateModalMedium";
import modalLarge from "./components/pages/TemplateModalLarge";
import modalFull from "./components/pages/TemplateModalFull";
import ModalCreateTicket from "./components/modals/createTicket.vue";
import ModalEditTeam from "./components/modals/editTeam.vue";
import ModalDeleteTeam from "./components/modals/deleteTeam.vue";
import ModalShowWelcome from "./components/modals/showWelcome.vue";
import ModalShowQuickNote from "./components/modals/showQuickNote.vue";
import ModalBookDemo from "./components/modals/bookDemo";
import ModalEventDetails from "./components/modals/eventDetails";
import ModalEmoji from "./components/modals/emoji";
import ModalPulse from "./components/modals/pulse";
import ModalGraph from "./components/modals/graph";
import ModalManagement from "./components/modals/management";
import ModalCreateTeam from "./components/modals/createTeam";
import ModalCreateEvent from "./components/modals/createEvent.vue";
import ModalCreateSystem from "./components/modals/createSystem";
import ModalShowInvite from "./components/modals/showInvite";
import ModalShowIntervention from "./components/modals/showIntervention";
import ModalShowMeditation from "./components/modals/showMeditation";
import ModalInviteEmails from "./components/modals/inviteEmails";
import ModalInviteEmailManager from "./components/modals/inviteEmailManager";
import ModalEmployeeSettings from "./components/modals/employeeSettings";
import ModalCreateResource from "./components/modals/createResource";
import ModalCreateCategory from "./components/modals/createCategory";
// import ModalCreateAction from "./components/modals/createAction";
import ModalResetPassword from "./components/modals/resetPassword";
import ModalDeletePost from "./components/modals/deletePost";
import ModalCreatePulseSurvey from "./components/modals/createPulseSurvey";
import ModalCreateRecordingCollection from "./components/modals/createRecordingCollection";
import ModalEditRecordingCollection from "./components/modals/editRecordingCollection";
import ModalDeleteRecordingCollection from "./components/modals/deleteRecordingCollection";
import ModalDeleteResource from "./components/modals/deleteResource";
import ModalEditResource from "./components/modals/editResource";
import ModalResourceCategoryList from "./components/modals/showResourceCategoryList";
import ModalEditCategory from "./components/modals/editCategory";
import ModalEditEvent from "./components/modals/editEvent";
import ModalShowInfo from "./components/modals/showInfo";
import ModalShowResource from "./components/modals/showResource";
import ModalDeleteCategory from "./components/modals/deleteCategory";
import ModalCreateRecording from "./components/modals/createRecording.vue";
import ModalEditRecording from "./components/modals/editRecordingData.vue";
import ModalShowRecording from "./components/modals/showRecording.vue";
import ModalDeleteRecording from "./components/modals/deleteRecording.vue";
import ModalDeleteEvent from "./components/modals/deleteEvent.vue";
import ModalShowLoader from "./components/modals/showLoader.vue";

import { store } from "@/store/index";
export default {
  mixins: [globalMixin],
  computed: {},
  data() {
    return {
      showDemo: false,
      userNavItems: userNavItemData,
      showNav: false,
      showUserNav: false,
    };
  },
  components: {
    //audioContainer,
    // businessSidebar,
    // businessSidebarAdmin,
    modal,
    modalMedium,
    modalLarge,
    modalFull,
    ModalBookDemo,
    ModalEventDetails,
    ModalEmoji,
    ModalPulse,
    ModalGraph,
    ModalManagement,
    ModalCreateSystem,
    ModalShowInvite,
    ModalShowMeditation,
    ModalEmployeeSettings,
    ModalCreateResource,
    ModalCreateTeam,
    ModalCreateCategory,
    ModalCreateEvent,
    ModalShowWelcome,
    ModalEditEvent,
    ModalDeleteEvent,
    ModalEditTeam,
    ModalDeleteTeam,
    ModalCreateTicket,
    // ModalCreateAction,
    ModalResetPassword,
    ModalInviteEmails,
    ModalInviteEmailManager,
    ModalDeletePost,
    ModalCreatePulseSurvey,
    ModalDeleteResource,
    ModalEditResource,
    ModalResourceCategoryList,
    ModalEditCategory,
    ModalShowInfo,
    ModalShowResource,
    ModalDeleteCategory,
    ModalShowIntervention,
    ModalCreateRecording,
    ModalShowRecording,
    ModalEditRecording,
    ModalDeleteRecording,
    ModalCreateRecordingCollection,
    ModalEditRecordingCollection,
    ModalDeleteRecordingCollection,

    ModalShowLoader,
    ModalShowQuickNote,
  },

  mounted() {
    if (store.state.token) this.loadInitialData();

    if (performance.type == performance.TYPE_RELOAD) {
      store.commit("showModal", null);
      store.commit("showModalFull", null);
      store.commit("showModalLarge", null);
      store.commit("showModalMedium", null);
    }

    let iframes = document.getElementsByTagName("iframe");
    if (iframes.length > 0) {
      iframes.forEach((element) => {
        console.log(element);
        let source = element.getAttribute("src");

        if (source) {
          if (source.includes("https://stonly.com")) {
            element.setAttribute("style", "z-index: 6000px;");
          }
        }
      });
    }
  },
};
</script>
<style>
.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .25s ease;
}
</style>
<style lang="postcss">
.blurScreen {
  filter: blur(4px);
  /* transform: scale(1.03) */
}
.navItem {
  @apply border-b border-b-2 border-transparent cursor-pointer text-white h-20 flex items-center px-6;
}

.navItem:hover {
  @apply border-white;
}

.navItemHighlight {
  @apply rounded-b cursor-pointer bg-green-500 text-white h-20 flex items-center px-6;
}

.navItemHighlight:hover {
  @apply bg-green-600;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for <2.1.8 */ {
  opacity: 0;
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}

.flipX-enter-active {
  transition: all 0.4s ease;
}

.flipX-leave-active {
  display: none;
}

.flipX-enter,
.flip-leave {
  transform: rotateX(90deg);
  opacity: 0;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url(./fonts/Gilroy-Bold.woff) format("woff");
}
@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"), url(./fonts/Gilroy-Medium.woff) format("woff");
}
@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"), url(./fonts/Gilroy-Regular.woff) format("woff");
}

.bg-royalBlue {
  background: rgb(0, 134, 255);
  font-family: "Gilroy";
  background: linear-gradient(
    81deg,
    rgba(0, 134, 255, 1) 0%,
    rgba(0, 109, 255, 1) 100%
  );
}

body,
html {
  font-family: "GilroyMedium";
}

h1 {
  font-family: "Gilroy";
}
h2,
h3,
h4,
label,
input,
select {
  font-family: "GilroyMedium" !important;
}

/* can be configured in tailwind.config.js */
.group:hover .group-hover\:block {
  display: block;
}
.hover\:w-64:hover {
  width: 45%;
}

/* NO NEED THIS CSS - just for custom scrollbar which can also be configured in tailwind.config.js*/
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #2d3748;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #2b6cb0;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.navSlide-leave-active {
  transition: all 0.3s;
}

.navSlide-enter-active {
  transition: all 0.3s;
}

.navSlide-enter {
  transform: translateX(-300px);
}
.navSlide-leave-to {
  transform: translateX(-300px);
}

.templatePage-leave-active {
  transition: all 0.1s;
}

.templatePage-enter-active {
  transition: all 0.1s;
}

.templatePage-enter {
  opacity: 0;
  transform: translateY(-30px);
}
.templatePage-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.listPage-leave-active,
.listPageBack-leave-active,
.listPage-enter-active,
.listPageBack-enter-active {
  transition: all 0.5s;
}

.listPage-enter {
  transform: translateX(-3000px);
}
.listPage-leave-to,
.listPageBack-leave-to,
.listPageBack-enter {
  transform: translateX(3000px);
}

.translateXFull-leave-active {
  transition: all 1s;
}

.translateXFull-enter-active {
  transition: all 1s;
}

.translateXFull-enter-from {
  opacity: 0;
}
.translateXFull-leave-to {
  opacity: 0;
}

.error-item {
  display: inline-block;
  margin-right: 10px;
}
.error-enter-active,
.error-leave-active {
  transition: all 0.3s;
}
.error-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.error-enter {
  opacity: 0;
  transform: translateX(-30px);
}

/* Jumping dots  */
div#wave {
  position: relative;
  text-align: center;
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
}
.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 2px;
  background: #fff;
  animation: wave 1.3s linear infinite;
}
.dot:nth-child(2) {
  animation-delay: -1.1s;
}

.dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-10px);
  }
}

.audio-enter-active,
.audio-leave-active {
  transition: all 0.4s;
}
.audio-enter, .audio-leave-to /* .list-leave-active below version 2.1.8 */ {
  transform: translateY(130px);
}

.slideLeftNav-leave-active {
  transition: all 0.3s;
}

.slideLeftNav-enter-active {
  transition: all 0.3s;
}

.slideLeftNav-enter {
  transform: translateX(-230px);
}
.slideLeftNav-leave-to {
  transform: translateX(-230px);
}

.slideDownNav-leave-active {
  transition: all 0.5s;
}

.slideDownNav-enter-active {
  transition: all 0.5s;
}

.slideDownNav-enter {
  transform: translateY(-100px);
}
.slideDownNav-leave-to {
  transform: translateY(-100px);
}

.templatePageX-leave-active {
  transition: all 0.3s;
}

.templatePageX-enter-active {
  transition: all 0.5s;
}

.templatePageX-enter {
  opacity: 1;
  transform: translateX(1000px);
}
.templatePageX-leave-to {
  opacity: 1;
  transform: translateX(500px);
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
</style>
