<template>
<div class="flex flex-wrap items-center h-full w-full bg-dkBlue rounded-lg">

    <div @click="closeModal()" class="w-full min-h-10 py-5">
    </div>

    <div class="p-10 flex w-full" v-if="loading">
      <loader  class="mx-auto" />
    </div>


    <div v-if="!loading" class="w-full text-center py-3 tracking-tight mb-2 font-bold text-lg text-white">
        Are you sure?
    </div>

    <div v-if="!loading" class="w-full flex flex-wrap justify-center">
        <div class="w-1/2 mb-10">
            <div @click="destroy()" class="text-base rounded text-center rounded-l relative p-2 cursor-pointer bg-primaryBlue hover:bg-purple-600 text-white">
                Yes
            </div>
        </div>
    </div>

</div>
</template>

<script>
import loader from "@/components/pages/TemplateLoaderDark";
import globalMixin from "../../mixins/globalMixin";
import {
    store
} from "../../store/index";

export default {
    mixins: [globalMixin],
    data() {
        return {
            loading: false
        };
    },
    computed: {
        event() {
            return store.state.event.activeEvent
        }
    },

    methods: {

        async destroy() {
            this.loading = true

            console.log(this.event)
            await this.$EventService.delete(this.event);
            this.loading = false
            store.commit('showModal', null)
        },
    },

    components:{
        loader
    }
};
</script>
