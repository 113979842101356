<template>
  <footer class="bg-gray-800 flex-wrap flex">

    <div class="w-full bg-primaryBlue p-10 md:p-20">
      <div class="flex container mx-auto">
        <div
          class="flex flex-wrap md:3/4 font-bold mx-auto w-full mx-auto text-lg md:text-xl text-center text-white"
        >
          <div class="w-full sm:w-1/2 mx-auto">
            "Why just create an online course, when you can offer your clients a purpose built, customised app."
          </div>
          <div @click="openModal('bookDemo')" class="w-full text-center mt-10">

            <span
              class="cursor-pointer hover:bg-white hover:text-primaryBlue rounded-full border-4 border-white rounded-full px-6 py-4"
             
            >
              Create Your Account
              <svg
                class="inline-block w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </span>
     
          </div>
        </div>
      </div>
    </div>

    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="flex flex-wrap justify-center mx-auto py-10">
      <div
        class="flex-none mx-2 text-gray-200 cursor-pointer hover:text-primaryBlue"
      >
        <router-link to="terms-and-conditions">
          Terms &amp; Conditions
        </router-link>
      </div>
      <div
        class="flex-none mx-2 text-gray-200 cursor-pointer hover:text-primaryBlue"
      >
        <router-link to="privacy-policy">
          Privacy Policy
        </router-link>
      </div>
      <div
        class="flex-none mx-2 text-gray-200 cursor-pointer hover:text-primaryBlue"
      >
        <router-link to="auth">
          Login
        </router-link>
      </div>
      <div class="w-full flex mt-4 pt-4 border-t border-gray-700">
        <div class="w-full text-center text-base text-gray-400">
          &copy; 2021 Euda, Inc. All rights reserved.
        </div>
      </div>

      <div class="flex justify-center mt-2">
        <a
          href="https://www.facebook.com/euda.corpwellbeing"
          target="_blank"
          class="text-gray-400 hover:text-gray-300"
        >
          <span class="sr-only">Facebook</span>
          <svg
            class="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clip-rule="evenodd"
            />
          </svg>
        </a>

        <a
          href="https://www.instagram.com/euda.co/?hl=en"
          target="_blank"
          class="text-gray-400 hover:text-gray-300"
        >
          <span class="sr-only">Instagram</span>
          <svg
            class="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
export default {
  mixins: [globalMixin]
};
</script>
