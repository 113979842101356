import { store } from "@/store/index.js"
import { TeamsRepo } from "@/repo/TeamsRepo.js";
const teamsRepo = new TeamsRepo();

export default class TeamsService {
    async fetch(lastEvaluatedKey){
        const res = await teamsRepo.fetch(lastEvaluatedKey)
        let result = {
            status: true,
            lastEvaluatedKey: "na"
        }
        try {
            if (res.data.LastEvaluatedKey) {
                result.lastEvaluatedKey = res.data.lastEvaluatedKey;
            } else {
                result.lastEvaluatedKey = "na"
            }
            
            store.commit('teams', res.data)
        } catch (error) {
            result.status = false
            result.error = error;
        }
        return result
    }

    async update(element){
        
        let result = {
            status: true
        }
        try {
            const res = await teamsRepo.update(element)    
           
            if(res.status == 200){
            
            let teamItems = store.state.teams.Items.filter(el => el.pk != element.pk)
            let obj = {Count: store.state.teams.Count, 
                       Items: teamItems,
                       ScannedCount: store.state.teams.ScannedCount}
                
                store.commit('teams', null)
                store.commit('teams', obj)
                store.commit('addTeams', element)
                store.commit('selectedTeam', element)
            }
            return res
            
        } catch (error) {
            result.status = false
            result.error = error;
        }
        return result
    }

    async delete(element){
        let result = {
            status: 200
        }
        try {
           
            const res = await teamsRepo.delete(element)
            
            if(res.status == 200){
                let teamItems = store.state.teams.Items.filter(el => el.pk != element.pk)
                let obj = {Count: store.state.teams.Count, 
                    Items: teamItems,
                    ScannedCount: store.state.teams.ScannedCount}
             
                store.commit('teams', null)
                store.commit('teams', obj)
                store.commit('selectedTeam', null)
                store.commit("level", "admin");
            } 

        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }

}