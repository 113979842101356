<template>
<div class="flex flex-wrap" >
    <div class="w-full">
        <div class="w-full py-2 text-base">
            {{ internalResource.metaData.title }}
        </div>
        <!-- description -->
        <div class="w-full rounded border-t py-2" :class="`${appearance.background} ${appearance.textColour}`">
            {{ internalResource.metaData.description}}

            <div class="w-full mt-10 flex justify-end border-b pb-4">
                Posted on {{ internalResource.metaData.createdAt}}
            </div>
            <!-- Show tags -->
            <div class="w-full flex flex-wrap">
                <span class="mt-2 flex rounded-full mt-1 px-2 py-1 items-center mr-2 font-extrabold text-white text-xs" :style="`background-color:` + internalResource.metaData.categoryData.metaData.color">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 mr-1">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z">
                        </path>
                    </svg>
                    {{ internalResource.metaData.categoryData.metaData.title }}
                </span>

                <span v-for="(feature, index) in internalResource.metaData.features" v-bind:key="index" class="mt-2 flex bg-green-700 rounded-full px-2 py-1 items-center mr-2 text-white text-xs">
                    <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 mr-1">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z">
                        </path>
                    </svg>
                    {{ feature.title }}
                </span>
            </div>

        </div>
        <!-- description -->

    </div>
</div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
import {
    store
} from "@/store/index";
// import TopNav from "@/components/pages/TemplateHeader"
// import NavMenu from "@/components/pages/TemplateNav"

export default {
    mixins: [globalMixin],
    data() {
        return {
            search: {
                placeholder: "Search the Resource",
                target: "internal-resources"
            },
            topRightBtns: [{
                btnTxt: "View the Resource",
                btnBg: "bg-primaryBlue",
                whereTo: store.state.selectedResource.metaData.link
            }],

            loading: true,
            errored: "",
            lastEvaluatedKey: "na"
        };
    },
    methods: {
        changeTemplate(status) {
            store.commit("showResource", status);
            store.commit("showReport", status);
        },
    },
    // components: {
    //     TopNav,
    //     NavMenu,
    // },

    computed: {
        internalResource() {
            return store.state.selectedResource
        },
        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        }
    },

};
</script>
