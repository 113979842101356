<template>
  <div  class="w-full p-2">
    <div class="w-full grid grid-cols-3 sm:grid-cols-3 gap-2">
      <permah-box
        v-for="(element, index) in metric.elements"
        :key="index"
        :snaps="snaps"
        :element="element"
        backgroundImage="https://images.unsplash.com/photo-1498568715259-5c1dc96aa8e7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
      />
    </div>
  </div>
</template>
<script>

import permahBox from "../permahBox.vue";

export default {
  props: ["metric", "snaps"],
  components: {
    permahBox,
  },

};
</script>