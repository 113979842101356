import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import VueFeather from "vue-feather";
import axios from "axios";
import VueAxios from "vue-axios";
import UUID from "vue-uuid";
import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import "./registerServiceWorker";
import "./registerServiceWorker";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import VueTimeago from "vue-timeago";
import AOS from "aos";
import "aos/dist/aos.css";
import VueClipboard from 'vue-clipboard2'
import vueSmoothScroll from 'vue2-smooth-scroll'
import Trend from "vuetrend"
import VueApexCharts from 'vue-apexcharts'
import VueCarousel from 'vue-carousel';

import "@/services/Stonely.js";
import '@/assets/fonts/font.css';
import Datetime from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

//Services
import InternalResourceService from "@/services/InternalResourceService.js";
import InternalResourceCategoryService from "@/services/InternalResourceCategoryService.js";
import InterventionService from "@/services/InterventionService.js"
import AppearanceService from "@/services/AppearanceService.js";
import RecordingService from "@/services/RecordingService.js";
import RecordingCollectionService from "@/services/RecordingCollectionService.js";
import EmployeeService from "@/services/EmployeeService.js";
import TenantService from "@/services/TenantService.js";
import UtilService from "@/services/UtilService.js";
import PulseService from "@/services/PulseService.js";
import TeamsService from "@/services/TeamsService.js";
import EventService from "@/services/EventService.js";
import HubSpotService from "@/services/HubSpotService.js";
import ApiService from "@/services/ApiService.js";

import vGoogleTranslate from 'v-google-translate';

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   Vue,
//   dsn: "https://69c47ab383fc4f34848472e476ba7998@o906344.ingest.sentry.io/5843804",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });
Vue.prototype.$ApiService= new ApiService();
Vue.prototype.$InternalResourceService= new InternalResourceService();
Vue.prototype.$InternalResourceCategoryService= new InternalResourceCategoryService();
Vue.prototype.$InterventionService= new InterventionService();
Vue.prototype.$AppearanceService= new AppearanceService();
Vue.prototype.$RecordingService= new RecordingService();
Vue.prototype.$RecordingCollectionService= new RecordingCollectionService();
Vue.prototype.$EmployeeService= new EmployeeService();
Vue.prototype.$TenantService= new TenantService();
Vue.prototype.$UtilService= new UtilService();
Vue.prototype.$PulseService= new PulseService();
Vue.prototype.$TeamsService= new TeamsService();
Vue.prototype.$EventService= new EventService();
Vue.prototype.$HubSpotService= new HubSpotService();

Vue.use(vGoogleTranslate)
Vue.use(Datetime)
Vue.use(VueCarousel);
Vue.use(VueApexCharts)
Vue.use(Trend)
Vue.use(vueSmoothScroll)
Vue.use(VueClipboard)
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en" // Default locale
});

Vue.use(VuePlyr, { plyr: {} });
Vue.use(VueFusionCharts, FusionCharts, Charts);
Vue.use(UUID);
Vue.use(VueAxios, axios);
Vue.use(VueFeather);
Vue.config.productionTip = false;
Vue.use(router);


import device from "vue-device-detector"
Vue.use(device)


new Vue({
  router,
  store,
  created() {
    AOS.init();
  },
  render: h => h(App)
}).$mount("#app");
