<template>
  <div
    class="bg-white h-full relative rounded-lg overflow-hidden w-full text-center py-10"
  >
    <loader v-if="loading" class="h-64 flex items-center" />

    <transition name="flip">
      <div v-if="!loading && metric.elements.length > 0">
        <div
          v-if="step == 1"
          class="relative overflow-hidden w-full flex flex-wrap justify-center text-center"
        >
          <div  class="flex flex-wrap w-full px-10">
            <div class="text-left font-bold header text-gray-600">
              <span class="text-xs text-gray-400"
                >Step {{ question + 1 }}/{{ metric.elements.length }}</span
              >
              <br />
              {{ metric.elements[question].title }}
            </div>

            <div
              :style="{ color: metric.elements[question].color }"
              class="text-2xl text-center w-full md:text-4xl text-left leading-none mt-10 font-bold header"
            >
              {{ metric.elements[question].question }}
            </div>
          </div>
          
          <div class="w-full z-50 flex-none justify-center flex mt-4">
            <div
              class="flex rating z-10 flex-shrink"
              v-for="(score, sIndex) in metric.elements[question].scores.length"
              :key="sIndex"
            >
              <input
                type="radio"
                v-model="rating[question]"
                :value="sIndex + 1"
                name="rating"
              />
              <label @click="nextQuestion(sIndex + 1)" @touchstart="activeIndex(sIndex)" @mouseover="activeIndex(sIndex)" :data-checkin-index="`${sIndex}`" class="check-in-stars"></label>
            </div>
          </div>
        </div>
        <div
          v-if="step == 2"
          class="bg-primaryTeal p-10 overflow-hidden w-full h-full text-center text-purple-700"
        >
          <h2 class="text-xl text-gray-600">{{ metric.finalSlide }}</h2>



          <h2 class="text-xl text-gray-600 my-8" v-if="actionLabel && actionTitle">
            One of your wellness opportunities is to focus on {{ getLowest().title }}. 
            
            <span v-html="getLowest().action.length > 0 ? `Why not try the following - ${getLowest().action}` : ''"></span>
          </h2>

          <div
            @click="openDashboard()"
            class="bg-primaryBlue rounded hover:bg-purple-600 text-white cursor-pointer p-4 my-4"
          >
            See your Insights
          </div>
        </div>
      </div>
      <div v-if="metric.elements.length <= 0" class="p-10 text-gray-500">Sorry there is something wrong please contact admin for help</div>
    </transition>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
import Loader from "@/components/pages/TemplateLoaderLight.vue";
import { store } from "../../store/index";
export default {
  mixins: [globalMixin],
  data() {
    return {
      metric: null,
      step: 1,
      rating: [],
      showQuestions: true,
      loading: false,
      actionLabel: "",
      actionTitle: "",
      questions: [
        {
          title: "Positive Emotion",
          color: { backgroundColor: "#08131e" },
          rotate: { transform: "rotate(" + 40 + "deg)" },
          question: "Are you experiencing positive emotion?",
          hint: "Try meditating, announcing what you are grateful for.",
        },
        {
          title: "Engagement",
          color: { backgroundColor: "#08131e" },
          rotate: { transform: "rotate(" + 50 + "deg)" },
          question: "How engaged are you?",
          hint: "Try forcing youreslf out of your comfort zone",
        },
        {
          title: "Relationships",
          color: { backgroundColor: "#08131e" },
          rotate: { transform: "rotate(" + 50 + "deg)" },
          question: "How are your relationships?",
          hint: "Try meeting up with a friend",
        },
        {
          title: "Meaning",
          color: { backgroundColor: "#08131e" },
          rotate: { transform: "rotate(" + 63 + "deg)" },
          question: "Do you feel fulfilled?",
          hint: "You might need some reflecting time",
        },
        {
          title: "Accomplishment",
          color: { backgroundColor: "#08131e" },
          rotate: { transform: "rotate(" + 40 + "deg)" },
          question: "Are you kicking goals?",
          hint: "Set short term goals and smash them",
        },
        {
          title: "Health",
          color: { backgroundColor: "#08131e" },
          rotate: { transform: "rotate(" + 65 + "deg)" },
          question: "How is your physical health?",
          hint: "Go for a walk, breath and listen to your body",
        },
      ],
      question: 0,

    };
  },
  computed: {
    avgResult() {
      let score = 0;
      console.log("rating", this.rating);
      this.rating.forEach((i) => {
        score += i;
      });
      const avg = score / this.rating.length;
      return avg;
    },
  },
  methods: {

    getLowest(){
      let lowest = {index: 0, value: this.rating[0]}
      let index = 0
      this.rating.forEach(element => {

        if(element < lowest.value){
          lowest.index = index
          lowest.value = element
        }

        index = index + 1
      });

      // let final = {
      //   action: this.metric.elements[lowest.index].scores[lowest.value].action,
      //   title: this.metric.elements[lowest.index].title
      // }
      //  return final

      this.actionLabel = this.parseLink(this.metric.elements[lowest.index].scores[lowest.value].action)
      this.actionTitle = this.metric.elements[lowest.index].title

     
    },

    parseLink(content){
      // probably there is a better way for this 
      // but as a interim solution, this wil do the job...
      
      let arr = content.split(" ")
      let newarr = [];
      arr.forEach(element => {
        if(element.includes('http')){
          let value = `<a href="${element}" target="_blank" class="text-blue-700">${element}</a>`
          newarr.push(value)
        }else{
          newarr.push(element)
        }
      });
      
      let final = newarr.join(" ")

      return final
    },


    openDashboard() {
      this.closeModal();
      store.commit("appTemplate", "graph");
    },
    getMetric() {
      let config = {
        headers: {
          Authorization: this.token.IdToken,
        },
      };

      this.axios
        .get(`${process.env.VUE_APP_API_URL}/metrics`, config)
        .then((response) => {
          if(response.data.Item.metaData && response.data.Item.metaData.elements){
          this.metric = {
            title: response.data.Item.metaData.title,
            finalSlide: response.data.Item.metaData.finalSlide,
            description: response.data.Item.metaData.description,
            elements: response.data.Item.metaData.elements,
          };
          }
        });
    },

    activeIndex(index){
      let stars = document.getElementsByClassName('check-in-stars')
      stars.forEach(element => {
        element.classList.remove("before-target")
        let checkinIndex = element.getAttribute('data-checkin-index');
        if(checkinIndex <= index){
          element.classList.add('before-target')
        }
      });
    },
    nextQuestion(_score) {
      this.rating.push(_score);
      this.showQuestions = false;
      this.loading = true;
      setTimeout(async () => {
        this.showQuestions = true;

        if (this.question != this.metric.elements.length - 1) {
          this.question += 1;
          this.loading = false;
        } else {
          this.step = 2;
          await this.saveSnap();
          this.loading = false;
        }
      }, 600);
    },

    async saveSnap() {

      let body = {
        type: "saveSnaps",
        teamId: this.userData.team.split("#")[1],
        metaData: {
          avgScore: this.avgResult.toFixed(2),
          score: this.rating,
        },
      };

      console.log('============')
      console.log(body)
      console.log('============')
      // Update survey array in tenant
      let config = {
        headers: {
          Authorization: this.token.IdToken,
        },
      };
      await this.axios
        .put(`${process.env.VUE_APP_API_URL}/snaps`, body, config)
        .then(() => {
          this.loadInitialData();
        })
        .catch(async (error) => {
          this.errored = error;
        });
    },
  },
  components: {
    Loader,
  },
  mounted() {
    this.getMetric();
  },
};
</script>

<style lang="postcss">
.spinner {
  transform-origin: 50% 50%;
  animation: rotate 0.3s ease-in;
}
.spinnertext {
}
@keyframes rotate {
  0% {
    transform: rotate();
    filter: blur(0.2rem);
  }
  100% {
    transform: rotate(1.1turn);
    filter: blur(0.1rem);
  }
}

.triangle2 {
  margin-top: 50px;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 170px;
  border-left-width: 170px;
  border-bottom: 100px solid #1a2129;
}

.triangle {
  margin-top: -100px;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 56px;
  border-left-width: 56px;
  border-bottom: 100px solid #1a2129;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating > input {
  display: none;
}

.rating > label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23dddddd' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  transition: 0.3s;
}

.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23ffed75' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23f9e038' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.rating > .before-target {
   background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23f9e038' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

#rating-1:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-100px);
}
#rating-2:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-200px);
}
#rating-3:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-300px);
}
#rating-4:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-400px);
}
#rating-5:checked ~ .emoji-wrapper > .emoji {
  transform: translateY(-500px);
}

.emoji-wrapper {
  width: 100%;
  text-align: center;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.emoji-wrapper:before,
.emoji-wrapper:after {
  content: "";
  height: 15px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.emoji-wrapper:before {
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}

.emoji-wrapper:after {
  bottom: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 0) 100%
  );
}

.emoji {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}

.emoji > svg {
  margin: 15px 0;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}
</style>
