import Vue from "vue";
import VueRouter from "vue-router";
// Authorization
import Auth from "../views/Auth";
import Authorization from "../views/Authorization";
import DownloadApp from "../views/DownloadApp.vue"

//Console || Setting
// import Console from "../views/Console/Console.vue"
import Users from "../views/AdminUser"

// Invitations
// import acceptInvite from "@/views/AcceptInvite.vue";
// Pricing
// import Pricing from "../views/Pricing.vue";
import TestEnv from "../views/TestEnv.vue";

// Clients
// import Events from "../views/Events.vue";
// import Settings from "../views/Settings.vue";
// import Employees from "../views/Teams.vue";
// import SystemBuilder from "../views/SystemBuilder";
// import SystemBuilderForm from "../views/SystemBuilderForm";
// import Resources from "../views/Resources";
// import PulseSurvey from "../views/PulseSurvey.vue";
// import SystemArchitect from "../views/SystemArchitect"

// import CourseIndex from "../views/Course/Index.vue"
// import CourseShow from "../views/Course/Show.vue"

// Employees
// import EmployeeDashboard from "../views/MyToolKit"
import LandingPage from "../views/LandingPage.vue";


// Public Pages
import ForgotPassword from "../views/ForgotPassword.vue"
import Home from "../views/Home.vue";
// import Experts from "../views/Experts";
// import Workplaces from "../views/Workplaces";
// import PrivacyPolicy from "../views/PrivacyPolicy";
// import TermsAndConditions from "../views/TermsAndConditions";

// //Intervention
// import Intervention from "../views/Intervention/Intervention.vue";

// //Recording
// import Recording from "../views/AdminCinema.vue"


//NOT FOUND
import NotFound from "../views/404"



import { store } from "../store/index";




Vue.use(VueRouter);

const routes = [
  {
    path: "/users",
    name: "Users",
    component: Users,
    beforeEnter: (to, from, next) => {
      if (store.state.userData && store.state.userData.hasRole == 'admin') {
        next();
      } else {
        next("/");
      } 
    },
    meta: {
      title: "Users"
    }
  },

  {
    path: "/test-env",
    component: TestEnv,
  },
  {
    path: "/clear-cookies-and-redirect",
    beforeEnter: (to, from, next) => {
      let state = store.state;
      let newState = {};

      Object.keys(state).forEach(key => {
        newState[key] = null; // or = initialState[key]
      });

      store.replaceState(newState);
      next('/auth');

    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      // if (store.state.userData && store.state.userData.hasRole == 'admin') {
      //   next('/teams');
      // } 
      try{
        if((store.state.userData && store.state.userData.hasRole == 'manager') ||  (store.state.userData && store.state.userData.hasRole == 'admin')) {
          next('/my-toolkit')
        } else {
          next('/auth');
        }
      }catch(e){
        next('/auth');
      }
    },
    meta: {
      title: "Wellbeing toolkits for coaches, trainers and practitioners",
      metaTags: [
        {
          name: "description",
          content: "The home page of our example app."
        },
        {
          property: "og:description",
          content: "The home page of our example app."
        }
      ]
    }
  },
  {
    path: "/deposit",
    beforeEnter: (to, from, next) => {
      window.location.href = "https://buy.stripe.com/dR6bL56bl7qOaZO00e"
      console.log(to)
      console.log(from)
      console.log(next)
    }
  },

  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (store.state.userData && store.state.userData.hasRole == 'admin') {
        next('/teams');
      } else if(store.state.userData && store.state.userData.hasRole == 'manager') {
        next('/my-toolkit')
    } else {
        next();
      }
    }
  },

  {
    path: "/downloadApp",
    name: "DownloadApp",
    component: DownloadApp,
    beforeEnter: (to, from, next) => {
      next();
    }
  },

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      next();
    }
  },
  

  // {
  //   path: "/cinema",
  //   name: "Recording",
  //   component: Recording
  // },
  // {
  //   path: "/intervention",
  //   name: "Intervention",
  //   component: Intervention,
  // },
  // {
  //   path: "/console",
  //   name: "Console",
  //   component: Console,
  // },
  // {
  //   path: "/experts",
  //   name: "Experts",
  //   component: Experts,
  //   meta: {
  //     title: "Experts"
  //   }
  // },
  // {
  //   path: "/workplaces",
  //   name: "Workplaces",
  //   component: Workplaces,
  //   meta: {
  //     title: "Workplaces"
  //   }
  // },
  // {
  //   path: "/terms-and-conditions",
  //   name: "TermsAndConditions",
  //   component: TermsAndConditions,
  //   meta: {
  //     title: "Terms and Conditions"
  //   }
  // },
  // {
  //   path: "/privacy-policy",
  //   name: "PrivacyPolicy",
  //   component: PrivacyPolicy,
  //   meta: {
  //     title: "Privacy Policy"
  //   }
  // },
  {
    path: "/authorization",
    name: "Authorization",
    component: Authorization
  },
  {
    path: "/my-toolkit",
    name: "EmployeeDashboard",
    component: LandingPage,
    beforeEnter: (to, from, next) => {
      if (store.state.token) {
        next();
      } else {
        next("/");
      }
    },
    meta: {
      title: "Dashboard"
    }
  },
  // {
  //   path: "/course",
  //   name: "CourseIndex",
  //   component: CourseIndex,
  //   // beforeEnter: (to, from, next) => {
  //   //   if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //   //     next();
  //   //   } else {
  //   //     next("/");
  //   //   } 
  //   // },
  //   meta: {
  //     title: "CourseIndex"
  //   }
  // },
  
  // {
  //   path: "/course/:id",
  //   name: "CourseShow",
  //   component: CourseShow,
  //   // beforeEnter: (to, from, next) => {
  //   //   if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //   //     next();
  //   //   } else {
  //   //     next("/");
  //   //   } 
  //   // },
  //   meta: {
  //     title: "CourseShow"
  //   }
  // },

  // {
  //   path: "/teams",
  //   name: "Employees",
  //   component: Employees,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     } 
  //   },
  //   meta: {
  //     title: "Teams"
  //   }
  // },

  // {
  //   path: "/pricing",
  //   name: "Pricing",
  //   component: Pricing,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     } 
  //   },
  //   meta: {
  //     title: "Pricing"
  //   }
  // },
  // {
  //   path: "/events",
  //   name: "Events",
  //   component: Events,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     } 
  //   },
  //   meta: {
  //     title: "Events"
  //   }
  // },
  // {
  //   path: "/pulse-survey",
  //   name: "PulseSurvey",
  //   component: PulseSurvey,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  //   meta: {
  //     title: "Pulse Survey"
  //   }
  // },
  // {
  //   path: "/resources",
  //   name: "Resources",
  //   component: Resources,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  //   meta: {
  //     title: "Resources"
  //   }
  // },
  // {
  //   path: "/system-builder",
  //   name: "SystemBuilder",
  //   component: SystemBuilder,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  //   meta: {
  //     title: "System Builder"
  //   }
  // // },
  // {
  //   path: "/system-architect",
  //   name: "SystemArchitect",
  //   component: SystemArchitect,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   },
  //   meta: {
  //     title: "System Architect"
  //   }
  // },
  // {
  //   path: "/system-builder-form",
  //   name: "SystemBuilderForm",
  //   component: SystemBuilderForm,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   }
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   component: Settings,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData && store.state.userData.hasRole == 'admin') {
  //       next();
  //     } else {
  //       next("/");
  //     }
  //   }
  // },
  {
    path: "/login",
    name: "Login",
    beforeEnter: (to, from, next) => {
      next('/auth');
    }
  },
  {
    path: "/signup",
    name: "SignUp",
    beforeEnter: (to, from, next) => {
      next('/auth?signUp=true');
    }
  },

  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/*",
    beforeEnter: function(to, from, next) {
      next('/my-toolkit')
    }
  }

  // {
  //   path: "/accept-invite",
  //   name: "acceptInvite",
  //   component: acceptInvite,
  //   beforeEnter: (to, from, next) => {
  //     if (store.state.userData) {
  //       next("/");
  //     } else {
  //       next();
  //     }
  //   },
  //   meta: {
  //     title: "Employee Invite Acceptance"
  //   }
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...

export default router;





