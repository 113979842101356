export const RecordingModule = {
    namespaced: true,
    state : {
        currentPage: "directory",
        recordings: [],
        categories: [
            "Wellbeing Sessions",
            "Yoga and Meditations",
            "Company Training Videos",
            "2020 Company Awards",
            "Company AGM",
            "Employee Motivation Sessions",
            "Fitness with Fiona"
        ],
        activeRecording: null,
        collections: [],
        activeCollection: null,
        
        redirectToRecordingFirstPage: false
    },
    mutations : {
        activeRecording(state, payload){
            state.activeRecording = payload
        },

        activeCollection(state, payload){
            state.activeCollection = payload
        },
        redirectToRecordingFirstPage(state, payload){
            state.redirectToRecordingFirstPage = payload
        },

        currentPage(state, payload){
            state.currentPage = payload
        },
        collections(state, payload){
            state.collections = payload
        },
        addcollections(state,payload){
            state.collections.unshift(payload) 
        },
        recordings(state, payload){
            state.recordings = payload
        },
        addrecordings(state,payload){
            state.recordings.unshift(payload)
        }
        
    },
}