<template>
  <transition name="listVertical">
    <div
      v-if="showModalLarge"
      style="z-index: 99999"
      class="px-4 fixed top-0 left-0 w-full h-screen flex items-center bg-black bg-opacity-50"
    >
      <div
      style="max-height: 90%"
        class="relative overflow-scroll flex flex-wrap rounded-xl md:w-1/2 w-full shadow-lg mt-4 mx-auto"
      >
      <div
          @click="closeModalLarge()"
          class="z-50 fixed rounded text-primaryBlue bg-white absolute top-0 right-0 m-4 cursor-pointer hover:text-blue-600"
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>

        <slot name="body"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";

export default {
  mixins: [globalMixin]
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
