<template>
  <div class="h-screen flex items-center justify-center bg-gray-200 w-full">
    <loader-dark class="mx-auto flex-shrink" />
  </div>
</template>

<script>
import globalMixin from "../mixins/globalMixin";
import LoaderDark from "../components/pages/TemplateLoaderDark";
// import moment from "moment";
// import {store} from "../store/index"

export default {
  mixins: [globalMixin],
  components: {
    LoaderDark,
  },
  watch: {
    // whenever userdata changes, this function will run
    userData: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.saveUser();
      }
    },
  },
  methods: {
     saveUser() {
      let newMetaData = this.userData.metaData
      // newMetaData.status = 'active'

      let body = {
        type: "loginCount",
        metaData: newMetaData
      };

      // Update survey array in tenant
      let config = {
        headers: {
          Authorization: this.token.IdToken
        }
      };
      this.axios
        .put(
          `${process.env.VUE_APP_API_URL}/users`,
          body,
          config
        )
        .then(() => {
          this.authorize();
         
        })
        .catch(error => {
          console.log("Error saving user")
          this.logout();
          this.errored = error;
        });
    },
    async authorize() {
      // Do something if you like
      // Redirect to relevant pages based on role
      if(this.userData.loginCount <= 0 && this.userData.hasRole == 'admin') this.$router.push("/my-toolkit?firstAdmin=true");
      // else if(this.userData.loginCount <= 0 && this.userData.hasRole == 'employee') this.$router.push("/my-toolkit?firstPerson=true");
      else this.$router.push("/my-toolkit");

    },
  },
  mounted() {
    // On load we load the initial data from our mixin
    this.loadInitialData();
    // This will updae the userData store in vuex
    // We created a watcher above to look for change in the UserData
    // When user data changes is runs the authorize method
    // temporarily add plugins locally 
    
          // 
  },
};
</script>
