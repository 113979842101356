import { store } from "@/store/index.js"

import { EmployeeRepo } from "@/repo/EmployeeRepo.js";
const employeeRepo = new EmployeeRepo();

export default class EmployeeService {
    async fetch(){
        let result = {
            status: 200
        }
        try {
            const res = await employeeRepo.fetch()
            store.commit("employees", res.data);

        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }
    async create(employee) {
        let result = {
            status: 200
        }
        let creatorData;

        try {
            creatorData = {
                companyName: store.state.companyData.metaData.company,
                userName: store.state.companyData.metaData.company,
                logo: store.state.companyData.metaData.logo !== 'https://res.cloudinary.com/eudawellbeing/image/upload/v1620177059/assets/defaultAvatar_owsq6e.png' ? store.state.companyData.metaData.logo : '',
            }
        } catch (error) {
            creatorData = {
                companyName: "",
                userName: store.state.companyData.metaData.company,
                logo: store.state.companyData.metaData.logo !== 'https://res.cloudinary.com/eudawellbeing/image/upload/v1620177059/assets/defaultAvatar_owsq6e.png' ? store.state.companyData.metaData.logo : '',
            }
        }
        
        try {
  
            const res = await employeeRepo.create(employee, creatorData)
            return res

        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }
    async nudge(employee) {
        const res = await employeeRepo.update(employee, 'nudge')
        
        if(res.status == 200){
            alert('The reminder email has been sent to the user.')
        }
        return res
    }
    async update(employee, type) {
        let result = {
            status: true
        }
        try {
            const res = await employeeRepo.update(employee, type)

            if (res.status == 200) {
                console.log(employee)
                //filter the employees currently updating
                let employeesExceptTheEdittingOne = store.state.employees[0].Items.filter(emp => emp.pk != employee.pk)
                let theEmployeeCurrentlyUpdating = store.state.employees[0].Items.find(emp => emp.pk == employee.pk)
                
                if(type == 'settings'){
                   theEmployeeCurrentlyUpdating.metaData = employee.metaData
                   
                }else if(type == 'team'){
                   theEmployeeCurrentlyUpdating.team = employee.team
                
                }else if(type == 'hasRole'){
                    theEmployeeCurrentlyUpdating.hasRole = employee.hasRole
                }
                employeesExceptTheEdittingOne.push(theEmployeeCurrentlyUpdating)

                //manage the employee's visibility on current team's screen.
                employeesExceptTheEdittingOne.forEach(el => {
                    if(el.team == store.state.selectedTeam.pk){
                        el.display = true;
                    }else{
                        el.display = false;
                    }
                });

                //handle vuex process - also hiding the chart for now.
                store.commit('level', 'team')

                store.commit('setEmployeesItems', [])
                store.commit('setEmployeesItems', employeesExceptTheEdittingOne)

                   
            }
            return result

        } catch (error) {
            result.status = false;
            result.msg = error;
            return result
        }
    }
}