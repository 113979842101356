<template>
  <div
    class="w-full h-full rounded-lg overflow-y-scroll"
    :class="`${appearance.background} ${appearance.textColour}`"
  >
    <div class="p-10 flex w-full" v-if="loading">
      <loader class="mx-auto" />
    </div>
    <main class="relative flex" v-else>
      <div class="w-full h-full ">
        <div
          class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
        >
          <div class="divide-y divide-gray-200 lg:col-span-12">
            <!-- Profile section -->
            <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
              <div>
                <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                  Create an Event
                </h2>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Hosting an event? Allow your people access by adding them
                  here.
                </p>
              </div>

              <div class="grid grid-cols-12 gap-6">
                <div class="sm:col-start-1  col-span-12">
                  <label for="title" class="block text-sm font-medium leading-5"
                    >Title of your event</label
                  >
                  <input
                    v-model="title"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5 text-black"
                  />
                </div>

                <div class="sm:col-start-1 col-span-12 flex">
                  <div class="flex-shrink">
                    <img
                      :src="resizedImage"
                      alt="Uploaded Image"
                      class="w-20 h-20 rounded object-cover"
                    />
                  </div>
                  <div class="flex-grow px-4">
                    <div class="flex w-full items-center justify-start h-full">
                      <label
                        v-if="attachmentUploadStatus != 'uploading'"
                        class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white hover:bg-purple-600 hover:text-white"

                      >
                        <span class="text-xs leading-normal"
                          >Upload a file</span
                        >
                        <input
                          type="file"
                          multiple
                          label="Add your files"
                          @change="uploadFileToCloudinary($event)"
                          class="hidden"
                        />
                      </label>
                       <label
                        v-else
                        class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white"

                      >
                        <span class="text-xs leading-normal">
                          <Icon :target="{name: `preloader`, style: `margin:auto;background:#transparent;display:block;width:20px;height:20px`}" />
                        </span
                        >
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  v-if="isError"
                  class="bg-red-100 text-red-500 text-sm rounded p-2"
                >
                  {{ errorText }}
                </div>

                <div class="sm:col-start-1 col-span-12">
                  <label
                    for="description"
                    class="block text-sm font-medium leading-5"
                    >Description of the event</label
                  >
                  <textarea
                    rows="4"
                    v-model="description"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                  />
                </div>

                <div class="sm:col-start-1 col-span-12">
                  <label for="email" class="block text-sm font-medium leading-5"
                    >Link to your event
                  </label>
                  <div class="flex flex-wrap w-full">
                    <input
                      v-model="link"
                      id="link"
                      class="focus:shadow-outline mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                    />
                  </div>
                  <span class="text-xs text-blue-500"
                    >Paste a link to your event</span
                  >
                </div>
                <div class="sm:col-start-1 col-span-12">
                  <label for="email" class="block text-sm font-medium leading-5"
                    >When
                  </label>
                  <div class="flex flex-wrap w-full">
                    <Datetime
                        v-model="when" 
                        :format="{ year: 'numeric', month: 'long', day: 'numeric'}"
                        placeholder="when is the event?"
                        class="focus:shadow-outline mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black cursor-pointer" />
                  </div>
                </div>

                <div class="sm:col-start-1 col-span-12">
                  <label for="email" class="block text-sm font-medium leading-5"
                    >Start time
                  </label>
                  <div class="flex flex-wrap w-full">
                    <input
                      v-model="start"
                      type="time"
                      placeholder="What time does the event start?"
                      class="focus:shadow-outline mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                    />
                  </div>
                </div>

                <div class="sm:col-start-1 col-span-12">
                  <label for="email" class="block text-sm font-medium leading-5"
                    >End time
                  </label>
                  <div class="flex flex-wrap w-full">
                    <input
                      v-model="end"
                      type="time"
                      placeholder="What time does the event end?"
                      class="focus:shadow-outline mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div class="flex px-6 mb-6">
          <div
            @click="create()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Create Event
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import loader from "@/components/pages/TemplateLoaderDark";
import Icon from "@/components/pages/TemplateIcon";
import { Datetime } from 'vue-datetime';
export default {
  mixins: [globalMixin],
  data() {
    return {
      imgstatus: '',
      uploadedImage:
        "https://res.cloudinary.com/eudawellbeing/image/upload/v1629899523/assets/websiteImages/iu_njcmbo.png",
      resizedImage:
        "https://res.cloudinary.com/eudawellbeing/image/upload/v1629899523/assets/websiteImages/iu_njcmbo.png",
      files: [],
      isError: false,
      errorText: "",
      loading: false,
      title: "",
      description: "",
      link: "",
      errored: "",
      when: "",
      beforeFormatWhen: "",
      start: "",
      end: "",
    };
  },
  methods: {
    validateUrl() {
      let x = this.link;
      this.link = "https://" + x.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    },

    async create() {
      this.loading = true;
      this.validateUrl();

      await this.$EventService.create(this.element);
      this.loading = false;
      store.commit("showModal", null);
      // if(res.status == 200){

      // }
    },
    openDateSelector(){
      this.$refs.whenSelectForCreatingEvent.click()
      console.log(this.$refs.whenSelectForCreatingEvent)
    },
  
    uploadFileToCloudinary(evt) {
      this.imgstatus = 'uploading'

      return new Promise((resolve, reject) => {
        //Ideally these to lines would be in a .env file
        const CLOUDINARY_URL =
          "https://api.cloudinary.com/v1_1/eudawellbeing/upload";
        const CLOUDINARY_UPLOAD_PRESET = "myyaups8";
        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        formData.append("folder", "resourceImages");
        formData.append("file", evt.target.files[0]);
        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            let _img = response.secure_url.split("upload/");
            _img = _img[0] + "/upload/c_scale,w_500/" + _img[1];
            this.uploadedImage = _img;
            this.resizedImage = _img;
            this.imgstatus = "uploaded"
            resolve(response);
          }
          // Not successfull, let find our what happened
          if (request.status !== 200) {
            this.imgstatus = "errored"
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },
  },
  computed: {
    attachmentUploadStatus: {
           get(){
             return this.imgstatus
           },
           set(status){
             return status
           } 
    },
    appearance() {
      return store.state.light
        ? this.$AppearanceService.init("light")
        : this.$AppearanceService.init("dark");
    },

    element() {
      return {
        title: this.title,
        description: this.description,
        link: this.link,
        when: this.when,
        start: this.start,
        end: this.end,
        image: this.uploadedImage
      };
    },
  },
  components: {
    loader,
    Icon,
    Datetime
  },
};
</script>
<style>
  .vdatetime .vdatetime-input{
    width: 100% !important;
  }
</style>
