<template>
<div class="w-full h-full rounded-lg overflow-y-scroll" :class="appearance.background">
    <main class="relative flex">
        <div class="w-full h-full">
            <div class="divide-y lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <div class="divide-y lg:col-span-12">
                    <!-- Profile section -->
                    <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
                        <div>
                            <h2 class="text-lg font-bold leading-6 text-primaryBlue">
                                Category List
                            </h2>
                        </div>
                        <div class="flex flex-col">
                        <div class="-my-2 sm:-mx-6 lg:-mx-8">
                            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow border-b border-gray-200 sm:rounded-lg">
                                <table class="w-full" :class="appearance.tileColour">
                                <thead class="border-b border-gray-500" :class="appearance.borderColour">
                                    <tr>
                                        <th scope="col" colspan="2" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                            {{ categories.length}} categories
                                        </th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="w-full"   v-for="(category, index) in categories" v-bind:key="index"> 
                                        <td class="px-6 py-4 text-sm font-medium text-white w-8/12">
                                            <span
                                                class="rounded-full p-2"
                                                :style="`background-color:` + category.metaData.color">
                                                    {{ category.metaData.title }}
                                            </span>
                                        </td>
                                        <td class="py-4 pr-4 text-xs text-gray-500 w-4/12 text-right">
                                            <span class="cursor-pointer" @click="openModal('editCategory'), selectCategory(category)">
                                                Edit
                                            </span>
                                             | 
                                            <span class="cursor-pointer" @click="openModal('deleteCategory'), selectCategory(category)">
                                                Delete
                                            </span>
                                        </td>

                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>

                    </div>
                    
                </div>
            </div>

        </div>
    </main>
</div>
</template>

<script>
import { store } from "../../store/index";
import globalMixin from "@/mixins/globalMixin";
export default {
    mixins: [globalMixin],
    data() {
        return {

        }
    },
    methods: {
        selectCategory(category){
            store.commit('selectCategory', category)
        }
    },
    computed: {
        categories(){
            return store.state.categories
        },
        appearance(){
            return store.state.light ?  this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        }
    }
}
</script>
