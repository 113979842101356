export const UtilModule = {
    namespaced: true,
    state : {
        popupTxt: "",
    },
    mutations : {
        popupTxt(state, payload){
            state.popupTxt = payload
        },
    }
}