<template>
<div class="w-full flex antialiased overflow-hidden" :class="`${appearance.background} ${appearance.textColour}`">
    <div class="flex-1 flex flex-col">
        <main class="flex-grow flex flex-row h-screen">
            <!-- Main body content  -->
            <div class="overflow-y-scroll h-full relative flex flex-col flex-auto border-l border-gray-800">

                <div class="absolute top-0 left-0 w-full">
                    <nav-menu />
                    <!-- Header -->
                    <div class="h-32 border-b mt-10" :class="`${appearance.secondBorderColour}`">
                        <div class="grid grid-cols-2 w-full p-6">
                            <div class="col-span-1 grid grid-cols-1" v-on:mouseleave="manageTooltipe(false)">
                                <div class="text-2xl sm:text-4xl font-bold text-gray-700">Users</div>

                                <div class="hidden sm:flex text-gray-600 text-xs font-normal">
                                    <span class="hover:text-primaryBlue cursor-pointer" v-on:mouseover="manageTooltipe(true)"><svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 inline-block -mt-1 hover:text-primaryBlue cursor-pointer">
                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path>
                                        </svg>
                                        What is the users app?
                                    </span>
                                </div>
                                <div v-if="showToolTip" class="mt-2 absolute z-50">
                                    <div class="p-3 bg-black w-8/12 text-white rounded-lg ">
                                        The Users app provides Euda Admin and Manager users the ability to review and analyse the wellbeing results of their team and individuals over time. It also offers simple tips and suggestions on what actions can be taken to help improve each PERMAH emotion. Simply use the team filter and status options within this screen to review and identify the wellbeing trends from team members and individuals. This app allows also Admin users to manage internal team structure and hierarchy, and also add and edit Euda users.
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <!-- Body -->
                    <div class="w-full flex flex-wrap min-h-screen " :class="appearance.mainBackground">
                        <div class="w-full mx-2 sm:mx-10 pb-10 h-full">
                            <div class="flex flex-col">
                                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 h-full">

                                    <div class="h-full container mx-auto py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 flex">

                                        <div class="w-full mx-auto h-1/2 my-10">
                                            <div class="overflow-hidden">

                                                <div class="grid grid-cols-4 w-full gap-3">

                                                    <div class="border bg-white rounded-lg p-2 flex">
                                                        <select class="w-full py-3 outline-none">
                                                            <option class="w-full text-center">Euda</option>
                                                            <option class="w-full text-center">HR</option>
                                                        </select>
                                                    </div>

                                                    <div class="border bg-white rounded-lg px-2 col-span-3 grid grid-cols-3 gap-3 py-5">
                                                        <div class="border-r w-full text-center grid grid-cols-1">
                                                            <span class="text-thin">Total Users</span>
                                                            <span class="text-3xl font-extrabold">45</span>
                                                        </div>
                                                        <div class="border-r w-full text-center grid grid-cols-1">
                                                            <span class="text-thin">Active Users</span>
                                                            <span class="text-3xl font-extrabold text-green-600">20</span>
                                                        </div>
                                                        <div class="w-full text-center grid grid-cols-1">
                                                            <span class="text-thin">InActive Users</span>
                                                            <span class="text-3xl font-extrabold text-red-600">20</span>
                                                        </div>
                                                    </div>

                                                    <div class="bg-white col-span-4 pb-10 rounded-lg">
                                                        <div class="w-full px-5 pt-5">
                                                            <input type="text" class="w-2/5 border rounded py-2 px-1"/>
                                                        </div>
                                                        <!-- This example requires Tailwind CSS v2.0+ -->
                                                        <div class="px-4 sm:px-6 lg:px-8">
                                                           
                                                            <div class="mt-8 flex flex-col">
                                                                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                                        <table class="min-w-full divide-y divide-gray-300">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">Name</th>
                                                                                    <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Title</th>
                                                                                    <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Email</th>
                                                                                    <th scope="col" class="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">Role</th>
                                                                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                                                                                        <span class="sr-only">Edit</span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody class="divide-y divide-gray-200">
                                                                                <tr>
                                                                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">Lindsay Walton</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Front-end Developer</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">lindsay.walton@example.com</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Member</td>
                                                                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                                                                        <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, Lindsay Walton</span></a>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">Lindsay Walton</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Front-end Developer</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">lindsay.walton@example.com</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Member</td>
                                                                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                                                                        <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, Lindsay Walton</span></a>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">Lindsay Walton</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Front-end Developer</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">lindsay.walton@example.com</td>
                                                                                    <td class="whitespace-nowrap py-4 px-3 text-sm text-gray-500">Member</td>
                                                                                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                                                                                        <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span class="sr-only">, Lindsay Walton</span></a>
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </main>

    </div>
</div>
</template>

<script>
import {
    store
} from "@/store/index";
import NavMenu from "@/components/pages/TemplateNav"
import globalMixin from "@/mixins/globalMixin";

export default {
    mixins: [globalMixin],
    data() {
        return {
            showToolTip: false,
        }
    },
    methods: {
        manageTooltipe(status) {
            this.showToolTip = status
        },
    },
    computed: {
        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        }
    },
    components: {
        NavMenu,
    }

}
</script>
