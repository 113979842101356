<template>
<div class="flex flex-wrap items-center h-full w-full rounded-lg py-10" :class="appearance.background">
    <div @click="closeModal()" class="w-full py-5"></div>

    <div v-if="!loadingPosts">
        <!-- STEP 1 -->
        <!-- Creating a new Survey -->
        <div v-if="!confirmation" class="w-full">

            <div class="px-10 space-y-3 lg:pb-8 text-primaryBlue">
                <h2 class="font-bold text-lg">
                    Send your team a new pulse survey
                </h2>

                <div class="flex flex-wrap w-full">
                    <div class="w-full flex flex-wrap">
                        <div @click="manageDropdown" class="w-full p-3 border flex flex-wrap" v-bind:class="[dropdownExpanded ? 'rounded-t-lg' : 'rounded-lg' ]">
                            <div class="w-10/12">
                                {{ teamSelectDropdwnTxtForPulse }}
                            </div>
                            <div class="w-2/12 flex justify-end">
                                <span>
                                    <Icon v-if="dropdownExpanded" :target="{name: 'chevron-up-v2', class: 'h-6 w-6'}" />
                                    <Icon v-else :target="{name: 'chevron-down-v2', class: 'h-6 w-6'}" />
                                </span>
                            </div>
                        </div>
                        <div v-if="dropdownExpanded" class="w-full flex flex-wrap border h-24 bg-white overflow-scroll">
                            
                            <div @click="manageSelectTeam(team)" v-for="(team, index) in selectedTeams" v-bind:key="index" class="w-full flex flex-wrap text-sm hover:bg-gray-100 cursor-pointer px-3 py-2">
                                <div class="w-1/12">
                                    <Icon v-if="team.selected" :target="{name: 'checked', class: 'h-6 w-6 text-green-600'}" />
                                </div>
                                <div class="w-11/12">{{ team.metaData.title }}</div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="flex-wrap flex w-full">
                        <div class="w-full">
                            <textarea v-model="inputData.body" v-on:keyup.enter="confirmToSubmit()" cols="30" rows="5" placeholder="Ask your team a question" class="w-full" :class="`${appearance.tileColour} border ${appearance.textColour} mt-4 outline-none p-4 text-lg w-full rounded-lg`"></textarea>

                            <transition name="error">
                                <div v-if="errored" class="text-center bg-red-100 text-red-600 text-xs my-1 rounded-full py-1 px-2 my-2">
                                    {{ errored }}
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="w-full">
                        <div @click="confirmToSubmit()" class="mt-2 text-center px-5 py-4 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer">
                            Send
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="w-full">
            <!-- STEP 2 -->
            <!-- Confirmation -->
            <div class="px-10 space-y-3 lg:pb-8 text-primaryBlue">
                <h2 class="font-bold text-2xl">
                    Are you sure?
                </h2>
                <p class="text-gray-500 text-sm leading-tight">
                    Clicking 'yes' will send this survey to everyone
                </p>

                <div class="mt-6 sm:flex sm:flex-row-reverse">
                    <button @click="createPost()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primaryBlue text-base font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                        Yes
                    </button>
                    <button @click="closeModal()" type="button" :class="appearance.buttonColour" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium text-gray-600  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="w-full pb-5">
        <!-- STEP 3 -->
        <!-- Show the preloader -->
        <div class="px-10 space-y-3 lg:pb-8 text-gray-900 w-full flex lg:h-48">
            <div class="items-center h-full w-full flex flex-wrap">
                <div class="w-full flex justify-center">
                    <loader class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import {
    store
} from "../../store/index";
import moment from "moment";
import Loader from "@/components/pages/TemplateLoaderDark";
import Icon from "@/components/pages/TemplateIcon";

export default {
    mixins: [globalMixin],
    data() {
        return {
            dropdownExpanded: false,
            loadingPosts: false,
            errored: "",
            confirmation: false,
            inputData: {
                body: "",
                timestamp: "",
                user: ""
            },
        };
    },
    computed: {
        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        },

        pulse() {
            return {
                metaData: "",
                logo: ""
            }
        },
        teamSelectDropdwnTxtForPulse(){
            let teamsCount = store.state.pulseCheck.selectedTeams.length
            let selectedTeamsCount = store.state.pulseCheck.selectedTeams.filter(el => el.selected == true).length
            let text;
            teamsCount == selectedTeamsCount ? text = 'All' : text = `${selectedTeamsCount} team(s) selected`

            return text
        },
        selectedTeams(){
            return store.state.pulseCheck.selectedTeams.sort((a, b) => a.metaData.title.localeCompare(b.metaData.title))
        }
    },
    watch: {
        inputData() {
            //the error msg should be gone when there are any data entered.
            if (this.inputData.body.length > 0 && this.errored.length > 0) {
                this.errored = "";
            }
        },
    },
    methods: {
        manageSelectTeam(team){
            team.selected = !team.selected
            let foundTeam = this.selectedTeams.filter(el => el.pk != team.pk)
            store.commit('pulseCheck/selectedTeams', [])
            store.commit('pulseCheck/selectedTeams', foundTeam)
            store.commit('pulseCheck/addSelectedTeam', team)
        

           
        },
        manageDropdown(){
            this.dropdownExpanded = !this.dropdownExpanded
        },
        confirmToSubmit() {
            if (this.inputData.body.length > 0) {

                this.confirmation = true;
            } else {
                //validating the input to ensure not passing the empty filed to the server
                this.errored = "You can't issue an empty pulse";
            }
        },

        async createPost() {

            this.loadingPosts = true;

            // However inputData.body might still have data ie: double click
            // We don't clear it until we get a response from the server
            // So, we create an instance of the inputData and submit that AFTER we clear the original inputData
            // New instance

            let newData = this.inputData;

            // Also update the timestamp
            newData.timestamp = moment();
            // Now we can clear the inputData for a fresh use next submission

            // this.inputData.body = ""
            // this.inputData.timestamp = ""
            // this.inputData.user = ""
            // add user to newData
            newData.user = this.userData.metaData.fullName
        
            this.pulse.logo = this.companyData.metaData.logo
            this.pulse.metaData = newData

            let res = await this.$PulseService.create(this.pulse);
            store.commit('pulseCheck/addPulse', res.data)
            
            store.commit('showModal', null)
            this.loadingPosts = false;
        },
    },
    components: {
        Loader,
        Icon
    },
};
</script>
