<template>
<div class="w-full h-full rounded-lg overflow-y-scroll" :class="`${appearance.background} ${appearance.textColour}`">
    <div class="p-10 flex w-full" v-if="loading">
        <loader class="mx-auto" />
    </div>
    <main class="relative flex" v-else>
        <div class="w-full h-full ">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <div class="divide-y divide-gray-200 lg:col-span-12">
                    <!-- Profile section -->
                    <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
                        <div>
                            <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                                Create an ticket
                            </h2>
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                Let us know what you think about Euda
                            </p>
                        </div>

                        <div class="grid grid-cols-12 gap-6">
                            <div class="sm:col-start-1  col-span-12">
                                <label for="subject" class="block text-sm font-medium leading-5">Title</label>
                                <input v-model="subject" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5 text-black" />
                            </div>

                            <div class="sm:col-start-1 col-span-12">
                                <label for="description" class="block text-sm font-medium leading-5">Description</label>
                                <textarea rows="4" v-model="content" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black" />
                            </div>

               
                        </div>
              
                    </div>
                </div>
            </div>



        <div class="flex px-6 mb-6">
          <div
            @click="create()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Submit
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
    store
} from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import loader from "@/components/pages/TemplateLoaderDark";
export default {
    mixins: [globalMixin],
    data() {
        return {
            loading: false,
            subject: "",
            content: "",
        };
    },
    methods: {
       
        async create() {
            this.loading = true
          
            await this.$HubSpotService.create(this.element);
            this.loading = false
            store.commit('showModal', null)
           
        }
    },
    computed: {
        appearance() {
            return store.state.light ?
                this.$AppearanceService.init("light") :
                this.$AppearanceService.init("dark");
        },

        element() {
            return {
                subject: this.subject,
                content: this.content,
                from: store.state.userData.metaData.fullName ? store.state.userData.metaData.fullName.fullName : "",
                contact: store.state.userData.metaData.email
            }
        }
    },
    components: {
        loader
    }
};
</script>
