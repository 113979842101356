<template>
<div class="flex flex-wrap items-center h-full w-full bg-dkBlue rounded-lg">

    <div @click="closeModal()" class="w-full min-h-10 py-5">
    </div>
    <div class="w-full text-center py-3 tracking-tight mb-2 font-bold text-lg text-white">
        Are you sure?
    </div>

    <div class="w-full flex flex-wrap justify-center">
        <div class="w-1/2 mb-10">
            <div @click="deletePost()" class="text-base rounded text-center rounded-l relative p-2 cursor-pointer bg-primaryBlue hover:bg-purple-600 text-white">
                Yes
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    EventBus
} from "@/services/bus.js"
import globalMixin from "../../mixins/globalMixin";
import {
    store
} from "../../store/index";

export default {
    mixins: [globalMixin],
    data() {
        return {

        };
    },
    computed: {
        demoData() {
            return store.state.demoData
        }
    },

    methods: {

        async deletePost() {
            store.commit('showModal', null)

            let item = store.state.selectedPost
            if (this.demoData) return;
            let config = {
                headers: {
                    Authorization: this.token.IdToken,
                },
                params: {
                    pk: item.pk,
                    sk: item.sk,
                },
            };

            this.axios
                .delete(
                    `${process.env.VUE_APP_API_URL}/posts`,
                    config
                )
                .then((response) => {
                    if(response.status == 200){
                        //find out posts excluding the deleted item
                        let excPosts = store.state.posts.filter(item => item.pk != store.state.selectedPost.pk)
                        store.commit("posts", []);
                        store.commit("posts", excPosts)
                        store.commit('selectedPost', null)

                    
                        //init pulse
                        this.fetchPulsesUserNeedToComplete()
                        EventBus.$emit('loadPulses');
                    }
                })
                .catch(async (error) => {
                    this.errored = error;
                })
                .finally(() => (this.loading = false));
        },



        async fetchPulsesUserNeedToComplete(){
            await this.$PulseService.fetch("na");
        }
    }
};
</script>
