<template>
<div class="cursor-pointer z-50 bg-white hover:bg-gray-200 rounded px-1" @click="initPulse(pulse)">

        <div class="flex  rounded py-2 ">
            <div>
                <div class="flex w-8 h-8 bg-primaryBlue rounded items-center text-white">
                    <Icon :target="{name: 'speech-burble', class: 'h-5 w-5 m-auto'}"></Icon>

                </div>
            </div>
            <div class="pr-1 leading-none w-full pl-4 flex-wrap items-center text-gray-700 text-sm">
                <div class="w-20 truncate text-primaryBlue mb-1 mt-1">
                    {{ pass(pulse, "body") }}
                </div>
                <div class="text-gray-400 header block text-xs truncate w-26">
                    <timeago :datetime="pass(pulse, 'timestamp')"></timeago>
                </div>
                <div class="text-gray-400 header block text-xs truncate w-26">
                    {{ pass(pulse, "fullName") }}
                </div>
            </div>
            <div class="flex-grow">
                <div class="flex items-center h-10 w-10">

                    <Icon :target="{name: 'chevron-right-v2', class: 'w-6 h-6 text-gray-400 ml-auto'}"></Icon>

                </div>
            </div>
        </div>
  
</div>
</template>

<script>
import Icon from "@/components/pages/TemplateIcon";
import {
    store
} from "@/store/index";
import globalMixin from "@/mixins/globalMixin";
export default {
    mixins: [globalMixin],
    props: ["pulse", "notificationIndex"],
    data() {
        return {
            //   loading: true,
        };
    },
    components: {
        Icon
    },
    methods: {
        pass(pulse, type){
            try {
                switch (type) {
                    case "body":
                        return pulse.metaData.postData.metaData.body
                    case "timestamp":
                        return pulse.metaData.postData.metaData.timestamp
                    case "fullName":
                        return pulse.metaData.postData.metaData.user.fullName
                    default: 
                        return pulse
                }
            } catch (error) {
                return ""
            }
        },
        initPulse(_pulse) {
            this.showNotifications = !this.showNotifications;
            store.commit("pulse", _pulse);
            this.openModal("pulse");
        },
    },
};
</script>
