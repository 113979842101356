export const EventModule = {
    namespaced: true,
    state : {
        events: [],
        activeEvent: null
    },
    mutations : {
        events(state, payload){
          state.events = payload  
        },
        activeEvent(state, payload){
            state.activeEvent = payload
        },
        addEvent(state,payload){
            state.events.unshift(payload) 
        },  
    },
}