import { store } from "@/store/index.js"
import axios from 'axios'

export class InternalResourceRepo {
    
    async update(selectdedResource) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            type: "internal",
            pk: selectdedResource.pk,
            metaData: selectdedResource.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/resources`,
                body,
                config
            )
    }

    async fetch(lastEvaluatedKey) {
        // let lastkey = lastEvaluatedKey;
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                key: {},
                type: "internal",
                LastEvaluatedKey: lastEvaluatedKey
            }
        };

        return axios.get(
            `${process.env.VUE_APP_API_URL}/resources`,
            config
        )
    }


    async delete(resource){
        let authorisation = store.state.token.IdToken;

        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                pk: resource.pk,
                sk: resource.sk
            }
        }
        
        return axios.delete(
                `${process.env.VUE_APP_API_URL}/resources`,
                config
            )
    }
}