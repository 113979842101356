
<template>
  <div
  v-if="appTemplate == 'dashboard' || this.$route.path != '/my-toolkit'"
  :class="$route.fullPath == '/my-toolkit' ? 'bg-gray-800 text-white ' : 'bg-gray-800 text-white'"
  class="z-50 mx-auto fixed top-0 left-0 w-full h-12 flex items-center sm:px-10">
    <div class="flex w-full h-10">
      <div class="mx-6 my-3">
        <router-link :to="'/'">
        <img
              src="https://res.cloudinary.com/eudawellbeing/image/upload/v1629195772/assets/websiteImages/euda-white_1_xdulsf.png"
              alt="Euda Logo"
              width="50"
            />
        </router-link>
      </div>

      <div class="hidden sm:flex flex-shrink ml-auto h-10 items-center">
        <div

          class="flex-none cursor-pointer text-gray-600 header mr-6 text-sm"
        > 
          <v-google-translate />
        </div>
        <div

          class="flex-none cursor-pointer  header mr-6 text-sm"
        >
          <a href="https://euda-resources.s3.ap-southeast-2.amazonaws.com/Euda+FAQ.pdf" target="_blank">FAQ</a>
        </div>
        <div
          @click="createTicket()"
          class="flex-none cursor-pointer  header mr-6 text-sm"
        >
          Support 
        </div>
        <div
          @click="logout()"
          class="flex-none cursor-pointer  header mr-6 text-sm"
        >
          Logout
        </div>
      </div>
    </div>

    <div>
      <router-link :to="`/console`">
        <img v-if="userData.metaData.fullName && userData.metaData.fullName.fullName"
          class="w-10 h-10 rounded ml-4 cursor-pointer object-cover"
          :src='"https://avatar.oxro.io/avatar.svg?name=" + userData.metaData.fullName.fullName'
          alt=""
        />
        <img v-else
          class="w-10 h-10 rounded ml-4 cursor-pointer object-cover"
          :src='"https://avatar.oxro.io/avatar.svg?name="+ userData.metaData.email+".svg"'
          alt=""
        />
      </router-link>
    </div>

    <div class="relative ml-4 p-2 sm:p-0 sm:rounded" :class="light ? 'text-white' : 'text-white'">
      <div v-if="notificationData"
        :class="
          notificationData != null && notificationData.length > 0
            ? 'bg-pink-600 text-white'
            : 'bg-gray-500'
        "
        class="rounded sm:rounded w-10 h-10 hover:bg-pink-600 cursor-pointer flex items-center transition duration-500 ease-in-out"
        @click="showNotifications = !showNotifications"
      >
          <Icon 
                :target="{name: 'notification', class: notificationData != null && notificationData.length > 0 ? `bell sm:w-6 sm:h-6 w-8 h-8 mx-auto` : `sm:w-6 sm:h-6 w-8 h-8 mx-auto`}">
          </Icon>
      </div>

      <transition name="list">
        <div
          v-if="showNotifications"
          class="absolute z-20 top-0 right-0 mt-10 w-56 p-2 text-black"
          style="z-index:999999"
        >
        
          <div v-if="notificationData != null && notificationData.length < 1" class="bg-white p-4 rounded-md shadow text-gray-500 text-sm text-center">
            <Icon 
                :target="{name: 'thumbs-up', class: `mb-4 mx-auto h-20 w-20`}">
            </Icon>
          
            <span class="header font-bold text-lg leading-none">No new notifcations</span>
          </div>
          
          <div v-if="notificationData != null && notificationData.length > 0" class="overflow-scroll h-36 z-20">
            <div v-for="(pulse, index) in notificationData" :key="index">
              <each-pulse-row v-if="pulse.metaData.postData.metaData.body && pulse.metaData.postData.metaData.body.length > 0" 
                              :pulse="pulse" :notificationIndex="index">
              </each-pulse-row>
              <!-- <notification-item :item="i" :notificationIndex="index" /> -->
            </div>
          </div>

        </div>
      </transition>
    </div>

    <div class="sm:hidden">
      <div
          @click="logout()"
          :class="userData.hasRole == 'employee' ? '' : 'hidden'"
          class="flex-none cursor-pointer  header mr-6 text-sm text-gray-500"
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
        </div>
       <div
          @click="triggerNav()"
          :class="userData.hasRole != 'employee' ? '' : 'hidden'"
          class="flex-none cursor-pointer  header mr-2 text-sm text-gray-500"
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
        </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/pages/TemplateIcon.vue";
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
// import NotificationItem from "../notificationItems.vue";
import EachPulseRow from "@/components/pages/PulseCheckNotifications"
export default {
  mixins: [globalMixin],
  data() {
    return {
      showNotifications: false,
      
      //For loading pulses
      lastEvaluatedKey: "na",
    };
  },
  components: {
    EachPulseRow,
    Icon
    // NotificationItem,
  },
  
  computed: {
    appearance() {
      return store.state.light
        ? this.$AppearanceService.init("light")
        : this.$AppearanceService.init("dark");
    },
    appTemplate() {
      return store.state.appTemplate;
    }
  },
  methods:{
    triggerNav(){
      store.commit("toggleBusinessSidebar", !store.state.toggleBusinessSidebar)
    },
    async fetchPulsesUserNeedToComplete(){
      await this.$PulseService.fetch(this.lastEvaluatedKey);
    },
    changeTemplate(_template){
      store.commit("appTemplate", _template);
    },
    createTicket(){
      store.commit('showModal', 'createTicket')
    }
  },
  created(){
    this.fetchPulsesUserNeedToComplete()

  
  }
};
</script>