<template>
  <div class="w-full h-full rounded-lg overflow-y-scroll" :class="appearance.background">
    <main class="relative flex">
      <div class="w-full h-full">
        <div
          class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
        >
          <div class="divide-y divide-gray-200 lg:col-span-12">
            <!-- Profile section -->
            <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
              <div>
                <h2 class="text-lg font-bold leading-6 text-primaryBlue">
                  Create a resource category
                </h2>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Create a category in order to group your resources
                </p>
              </div>

              <div class="grid grid-cols-12 gap-6">
                <div class="sm:col-start-1 col-span-12">
                  <label
                    for="title"
                    class="block text-sm font-medium leading-5"
                    :class="appearance.textColour"
                    >Title of your category</label
                  >
                  <input
                    v-model="title"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5"
                  />
                </div>

                <!-- <div class="col-span-12 col-span-12">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5"
                    :class="appearance.textColour"
                    >Color your category</label
                  >
                  <v-swatches v-model="color" inline></v-swatches>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div class="flex px-6 mb-6">
          <div
            @click="createResource()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Create Resource Category
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
// import VSwatches from "vue-swatches";
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css";

export default {
  mixins: [globalMixin],
  data() {
    return {
      title: "",
      color: "#041138",
      errored: ""
    };
  },
  computed:{
    appearance(){
      return store.state.light ?  this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
    },
  },
  // components: { VSwatches },
  methods: {
    updateFeatures() {
      if (this.featuresString.includes(",")) {
        const x = this.featuresString.split(",")[0];
        const obj = { title: x };
        this.features.push(obj);
        this.featuresString = "";
      }
    },
    removeFeatures(i) {
      this.features.splice(this.features.indexOf(i), 1);
    },
    createResource() {
      // Validation
      if (this.title == "" || this.color == "") {
        this.errored = "All fields are required";

        return;
      }

      let config = {
        headers: {
          Authorization: this.token.IdToken
        }
      };

      let body = {
        type: "categories",
        metaData: {
          title: this.title,
          color: this.color
        }
      };

      this.axios
        .post(
          `${process.env.VUE_APP_API_URL}/resources`,
          body,
          config
        )
        .then(res => {
          if (res.data) {
            this.closeModal();
            store.commit("addCategories", body);
            // this.openModal("createResource");
          }
        })
        .catch(async (error) => {
          this.errored = error;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style>
  .vue-swatches__container.vue-swatches--inline{
    background: transparent !important;
  }
</style>