import { store } from "@/store/index.js"
import { InternalResourceCategoryRepo } from "@/repo/InternalResourceCategoryRepo.js"
import { InternalResourceRepo } from "@/repo/InternalResourceRepo.js";

const internalResourceCategoryRepo = new InternalResourceCategoryRepo();
const internalResourceRepo = new InternalResourceRepo();


export default class InternalResourceCategoryService {

    async fetch(lastEvaluatedKey) {
        const res = await internalResourceCategoryRepo.fetch(lastEvaluatedKey)
        let result = {
            status: true,
            lastEvaluatedKey: "na"
        }

        try {
            if (res.data.LastEvaluatedKey) {
                result.lastEvaluatedKey = res.data.lastEvaluatedKey;
            } else {
                result.lastEvaluatedKey = "na"
            }

            store.commit("categories", [])

            res.data.Items.forEach(x => {
                x.display = true
                // if (cheeckIfexist.length == 0) {
                store.commit("addCategories", x);
                // }
            });

        } catch (error) {
            result.status = false
            result.error = error;
        }

        return result
    }

    async update(updatedCategory){
        let result = {
            status: true
        }
        try {
            const res = await internalResourceCategoryRepo.update(updatedCategory)

            if (res.status == 200) {
                let category = store.state.categories.filter(item => item.pk == updatedCategory.pk)[0]
                category.metaData = updatedCategory.metaData
                
                console.log(updatedCategory)

                //The below code can improve
                let resources = store.state.internalResources.filter(resource => resource.metaData.categoryData.pk == updatedCategory.pk)
                // let finalResources = store.state.internalResources.filter(resource => resource.metaData.categoryData.pk != updatedCategory.pk)
                

                //updating related resources
                resources.forEach(element => {
                    let cloneItem = element
                    cloneItem.metaData.categoryData.metaData = updatedCategory.metaData
                    
                    //update single resource
                    internalResourceRepo.update(cloneItem)
                });
                store.commit('internalResources',[])
                store.commit('internalResources', resources)
            }

            return result

        } catch (error) {
            result.status = false;
            result.msg = error;
            return result
        }
    }

    async delete() {
        // EventBus.$emit('resource-page-loader', true);
        store.commit("showModal", null)
        //prepare variables
        let category = store.state.selectCategory;
        let demoData = store.state.demoData;
        let result = {
            status: true
        }

        if (!demoData) {
            try {
                const res = await internalResourceCategoryRepo.delete(category)

                let relatedResources = store.state.internalResources.filter(item => item.metaData.categoryData.pk == category.pk)
                let finalResources = store.state.internalResources.filter(resource => resource.metaData.categoryData.pk != category.pk)
                let finalCategories = store.state.categories.filter(el => el.pk != category.pk)
                if(res.status == 200){
                    //when the category is deleted, deleting all other resources as well.
                    relatedResources.forEach(element => {
                        internalResourceRepo.delete(element)

                    });
                    store.commit('internalResources',[])
                    store.commit('internalResources', finalResources)
                    
                    store.commit("categories", [])
                    store.commit("categories", finalCategories)
                    store.commit("selectCategory", null)
                }
            } catch (error) {
                result.error = error;
            }
        } else {
            return true
        }

    }

}