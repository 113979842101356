import {
  store
} from "../store/index";
const globalMixin = {

  data() {
    return {
      showNotifications: false
    }
  },

  methods: {
    getNotifications() {
      if (this.pushNotifications.length > 0) {
        setInterval(() => {
          this.getNotifications()
        }, 5000)
      }
    },

    //Dashboard
    changeTemplate(_template) {
      store.commit("appTemplate", _template);
    },

    // Modals
    closeModal() {
      store.commit("showModal", null);
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal(template) {

      store.commit("showModal", template);
      
      if (template == "bookDemo") {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: "9182226",
              formId: "e4c94678-66be-4f43-8ccb-ab9fdb113669",
              target: "#hubspotForm"
            });
          }
        });
        document.querySelector("body").classList.add("overflow-hidden");
      }

    },
   
    closeModalLarge() {
      store.commit("showModalLarge", null);
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModalMedium(template) {
      store.commit("showModalMedium", template);
    },
    closeMediumModal(){
      store.commit("showModalMedium", null);
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModalLarge(template) {
      store.commit("showModalLarge", template);
    },
    closeModalFull() {
      store.commit("showModalFull", null);
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModalFull(template) {
      store.commit("showModalFull", template);
    },
    // Reset store on logout
    logout() {
      store.commit("showModal", null);
      store.commit("showModalMedium", null);
      store.commit("showModalLarge", null);
      store.commit("refreshToken", null);
      store.commit("token", null);
      store.commit("authorized", false);
      store.commit("userData", null);
      store.commit("companyData", null);
      store.commit("notificationData", null);
      store.commit("email", "");
      store.commit("pluginSettings", null)
      this.$router.push("/auth");
    },

    // These below methods should be chained properly
    // Load refresh token
    loadInitialData() {
      this.initiateRefreshToken();
    },

    async initiateRefreshToken() {
      if(store.state.token != null){
        // Use refresh token to generate a new token
        await this.axios
          .post(
            `${process.env.VUE_APP_API_URL}/auth/refreshToken`, {}, {
            headers: {
              refreshtoken: store.state.token.RefreshToken
            }
          }
          )
          .then(response => {
            
            // Store the Token Body
            let t = this.token
            t.IdToken = response.data.AuthenticationResult.IdToken
            t.AccessToken = response.data.AuthenticationResult.AccessToken
            store.commit("token", t);
            store.commit("authorized", true);
            
            // Now validate the token by retreiving some useful data
            this.validation();
            // Load all initial data - userData, companyData, notificationData
          })
          .catch(() => {
            this.logout();
          });
        }else{
          console.log('Token is null')
        }
    },

    async validation() {
      await this.getUserData();
      await this.getCompanyData();
      await this.getNotificationData();
    },

    async getUserData() {
      let config = {
        headers: {
          Authorization: this.token.IdToken
        },
        params: {
          type: "employees"
        }
      };
      // Get custom fields
      await this.axios
        .get(
          `${process.env.VUE_APP_API_URL}/employees`,
          config
        )
        .then(res => {
          store.commit("userData", res.data);
        })
    },

    async getCompanyData() {
      let config = {
        headers: {
          Authorization: this.token.IdToken
        }
      };
      // Get custom fields
      await this.axios
        .get(
          `${process.env.VUE_APP_API_URL}/tenants`,
          config
        )
        .then(res => {
          console.log(res.data);
          store.commit("companyData", res.data);
          store.commit("pluginSettings", res.data.plugins)
        })
    },
    
    async getNotificationData() {
      let config = {
        headers: {
          Authorization: this.token.IdToken
        },
        params: {
          LastEvaluatedKey: "na"
        }
      };
      // Get custom fields
      await this.axios
        .get(
          `${process.env.VUE_APP_API_URL}/notifications`,
          config
        )
        .then(res => {
          store.commit("notificationData", res.data);
        })
        .catch(() => {
        })
    },

  },
  computed: {
    // mobile sidebar
    toggleBusinessSidebar(){
      return store.state.toggleBusinessSidebar
    },
    // plugins
    plugins(){
      return store.state.plugins
    },
    // theme
    light() {
      return store.state.light
    },
    // Modals
    tour() {
      return store.state.tour;
    },
    showModal() {
      return store.state.showModal;
    },
    showModalMedium() {
      return store.state.showModalMedium;
    },
    showModalLarge() {
      return store.state.showModalLarge;
    },
    showModalFull() {
      return store.state.showModalFull;
    },
    pushNotifications() {
      return store.state.pushNotifications;
    },
    // Navigation
    selectedPage() {
      return store.state.selectedPage;
    },
    // Authorization
    authorized() {
      return store.state.authorized;
    },
    token() {
      return store.state.token;
    },
    refreshToken() {
      return store.state.refreshToken;
    },
    // Generic Data
    userData() {
      return store.state.userData;
    },
    companyData() {
      return store.state.companyData;
    },
    notificationData() {
      return store.state.notificationData;
    }
  },
  mounted(){
    
  }
};

export default globalMixin;