<template>
  <div class="bg-gray-900 w-full p-10 rounded-lg container">
      
      <h2 class="header font-bold text-white text-xl break-all" v-html="parseLink(selectedIntervention)"></h2>

  </div>
</template>

<script>
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
export default {
  mixins: [globalMixin],
  computed:{
    selectedIntervention(){
      return store.state.selectedIntervention
    },
    
  },

  methods:{
    parseLink(content){
      let arr = content.split(" ")
      let newarr = [];
      arr.forEach(element => {
        if(element.includes('http')){
          let value = `<a href="${element}" target="_blank">${element}</a>`
          newarr.push(value)
        }else{
          newarr.push(element)
        }
      });
      
      let final = newarr.join(" ")

      return final
    }
  }

}
</script>