<template>
<div class="w-full h-full rounded-lg overflow-y-scroll" :class="`${appearance.background} ${appearance.textColour}`">
    <main class="relative flex">
        <div class="w-full h-full ">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <div class="divide-y divide-gray-200 lg:col-span-12">
                    <!-- Profile section -->
                    <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
                        <div>
                            <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                                Edit the resource
                            </h2>
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                edit the resource and distribute it to your users.
                            </p>
                        </div>

                        <div class="grid grid-cols-12 gap-6">
                            <div class="sm:col-start-1  col-span-12">
                                <label for="title" class="block text-sm font-medium leading-5">Title of your resource</label>
                                <input v-model="title" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5 text-black" />
                            </div>

                            <div class="sm:col-start-1  col-span-12">
                                <label for="label" class="block text-sm font-medium leading-5">Assign to a category</label>
                                <select v-model="category" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black">
                                    <option v-for="(c, cIndex) in categories" :key="cIndex" :value="c" class="text-dkBlue">{{ c.metaData.title }}</option>
                                </select>
                                <span @click="openModal('createCategory')" class="cursor-pointer hover:text-purple-600 text-xs text-blue-500">Or create a new one</span>
                            </div>

                            <div class="sm:col-start-1 col-span-12">
                                <label for="description" class="block text-sm font-medium leading-5">Description of the resource</label>
                                <textarea rows="4" v-model="description" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black" />
                                </div>

                <div class="sm:col-start-1 col-span-12">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5"
                    >Link to your resource</label
                  >
                  <div class="flex flex-wrap w-full">
                   
                    <input
                      v-model="link"
                      id="link"
                      class="focus:shadow-outline mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
          
                    />
                  </div>
                  <span class="text-xs text-blue-500"
                    >Paste a link to your resource</span
                  >
                </div>

                   <div class="sm:col-start-1 col-span-12 flex">
                                <div class="flex-shrink">
                                    <img :src="resizedImage" alt="Uploaded Image" class="w-20 h-20 rounded object-cover" />
                                </div>
                                <div class="flex-grow px-4">
                                    <div class="flex w-full items-center justify-start h-full">
                                        <label v-if="attachmentUploadStatus != 'uploading'" class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white hover:bg-purple-600 hover:text-white">
                                            <span class="text-xs leading-normal">Upload a thumbnail</span>
                                            <input type="file" multiple label="Add your files" @change="uploadFileToCloudinary($event)" class="hidden" />
                                        </label>
                                        <label v-else class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white">
                                            <span class="text-xs leading-normal">
                                                <Icon :target="{name: `preloader`, style: `margin:auto;background:#transparent;display:block;width:20px;height:20px`}" />
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

           
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div class="flex px-6 mb-6">
          <div
            @click="updateResource()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Update Resource
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
    store
} from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import moment from "moment";
import Icon from "@/components/pages/TemplateIcon";

export default {
    mixins: [globalMixin],
    data() {
        return {
            imgstatus: '',
            uploadedImage: store.state.selectedResource.metaData.image,
            resizedImage: store.state.selectedResource.metaData.image,

            title: store.state.selectedResource.metaData.title,
            description: store.state.selectedResource.metaData.description,
            link: store.state.selectedResource.metaData.link,
            category: store.state.selectedResource.metaData.categoryData,
            // featuresString: [],
            // features: store.state.selectedResource.metaData.features,
            active: store.state.selectedResource.metaData.active,
            errored: "",
            lastEvaluatedKey: "na"
        };
    },
    methods: {
        // updateFeatures() {
        //   if (this.featuresString.includes(",")) {
        //     const x = this.featuresString.split(",")[0];
        //     const obj = { title: x };
        //     this.features.push(obj);
        //     this.featuresString = "";
        //   }
        // },
        // removeFeatures(i) {
        //   this.features.splice(this.features.indexOf(i), 1);
        // },
        uploadFileToCloudinary(evt) {
            this.imgstatus = 'uploading'

            return new Promise((resolve, reject) => {
                //Ideally these to lines would be in a .env file
                const CLOUDINARY_URL =
                    "https://api.cloudinary.com/v1_1/eudawellbeing/upload";
                const CLOUDINARY_UPLOAD_PRESET = "myyaups8";
                let formData = new FormData();
                formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
                formData.append("folder", "resourceImages");
                formData.append("file", evt.target.files[0]);
                let request = new XMLHttpRequest();
                request.open("POST", CLOUDINARY_URL, true);
                request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                request.onreadystatechange = () => {
                    // File uploaded successfully
                    if (request.readyState === 4 && request.status === 200) {
                        let response = JSON.parse(request.responseText);
                        let _img = response.secure_url.split("upload/");
                        _img = _img[0] + "/upload/c_scale,w_500/" + _img[1];
                        this.uploadedImage = _img;
                        this.resizedImage = _img;
                        this.imgstatus = "uploaded"
                        resolve(response);
                    }
                    // Not successfull, let find our what happened
                    if (request.status !== 200) {
                        this.imgstatus = "errored"
                        let response = JSON.parse(request.responseText);
                        let error = response.error.message;
                        this.errorText = "error uploading files " + error;
                        this.isError = true;
                        reject(error);
                    }
                };
                request.onerror = (err) => {
                    alert("error: " + err);
                    reject(err);
                };
                request.send(formData);
            });
        },

        async updateResource() {
            // Validation
            if (
                this.title == "" ||
                this.description == "" ||
                this.link == "" ||
                this.category == ""
            ) {
                this.errored = "All fields are required";
                return;
            }
            const res = await this.$InternalResourceService.update(this.item)
            if (res.status == true) this.closeModal()
        },

    },
    computed: {

        attachmentUploadStatus: {
            get() {
                return this.imgstatus
            },
            set(status) {
                return status
            }
        },

        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        },
        categories() {
            return store.state.categories;
        },
        item() {
            return {
                title: this.title,
                categoryData: this.category,
                description: this.description,
                // features: this.features,
                link: `${this.link}`,
                author: `${this.userData.metaData.fullName}`,
                createdAt: moment().format("MMM DD, YYYY"),
                active: this.active,
                image: this.uploadedImage

                // whiteList: this.selectedItems
            };
        }
    },
    created() {
        this.category.display = true;
    },

    components: {
        Icon
    }
};
</script>
