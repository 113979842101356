import { store } from "@/store/index.js"
import axios from 'axios'

export class PulseRepo {
    
    async fetch(lastEvaluatedKey){
        
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
              key: {},
              type: "pulseChecks",
              LastEvaluatedKey: lastEvaluatedKey,
            }
        };

        return axios.get(
            `${process.env.VUE_APP_API_URL}/posts`,
            config
        )
    }

    async create(element){
     
        let authorisation = store.state.token.IdToken;
  
        let config = {
            headers: {
                Authorization: authorisation
            }
        };

        let selectedTeams = store.state.pulseCheck.selectedTeams.filter(el => el.selected == true)
        selectedTeams = selectedTeams.map(item => { return { pk: item.pk, title: item.metaData.title }});

        console.log(selectedTeams)
        let body = {
            type: "pulseCheck",
            metaData: element.metaData,
            logo: element.logo,
            teams: selectedTeams
        }

        console.log(body)

        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/posts`,
                body,
                config
            )
    }

}