import axios from 'axios'
import { store } from "@/store/index.js"

export class EventRepo {
    async create(metaData){
        console.log(metaData)
        let authorisation = store.state.token.IdToken;
  
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
                metaData: metaData,
            };
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/events`,
                body,
                config
            )
    }

    async fetch(lastEvaluatedKey) {
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                LastEvaluatedKey: lastEvaluatedKey
            }
        };

        return axios.get(
            `${process.env.VUE_APP_API_URL}/events`,
            config
        )
    }

    async update(element) {
        const authorisation = store.state.token.IdToken;
       
        let config = {
            headers: {
                Authorization: authorisation
            }
        };

        let body = {
            pk: element.pk,
            metaData: element.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/events`,
                body,
                config
            )
    }

    async delete(element) {
        const authorisation = store.state.token.IdToken;
       
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                pk: element.pk,
                sk: element.sk
            }
        };
        return axios
            .delete(
                `${process.env.VUE_APP_API_URL}/events`,
                config
            )
    }

    


}