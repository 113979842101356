import { store } from "@/store/index.js"
// import { EventBus } from "@/services/bus.js"
import { RecordingRepo } from "@/repo/RecordingRepo.js"
const recordingRepo = new RecordingRepo();

export default class RecordingService {
    async create(metaData){
        let result = {
            status: true
        }
        try {
            const res = await recordingRepo.create(metaData)
            if(res.status == 200){

                // let thumbnail = metaData.link.split("/")
                //     if(metaData.link.includes('youtu')){
                //         thumbnail = "https://img.youtube.com/vi/" + thumbnail[4] + "/0.jpg"
                //     }else if(metaData.link.includes('vimeo')){
                //         let vimeodata = await recordingRepo.getVimeoThumb(thumbnail[4])
                //         thumbnail = vimeodata.data.pictures.sizes[2].link
                //     }
                // res.data.metaData.thumbnail = thumbnail


                store.commit("recording/addrecordings", res.data);
            }
            return result
        } catch (error) {
            result.status = false;
            result.msg = error;
            console.log(error)
            return result
        }
    }

    async fetch(lastEvaluatedKey){
        const res = await recordingRepo.fetch(lastEvaluatedKey)
        let result = {
            status: true,
            lastEvaluatedKey: "na"
        }
        try {
            // let index = 0
            if (res.data.LastEvaluatedKey) {
                result.lastEvaluatedKey = res.data.lastEvaluatedKey;
            } else {
                result.lastEvaluatedKey = "na"
            }


            store.commit("recording/recordings", [])

            // console.log('recording list start')
            // console.log(res.data.Items)
            // console.log('recording list end')
            // console.log('updated..')

            res.data.Items.forEach(async el => {

                // if(el.metaData.thumbnail == undefined || el.metaData.thumbnail == ""){
                //     let thumbnail = el.metaData.link.split("/")
                //     if(el.metaData.link.includes('youtu')){
                //         thumbnail = "https://img.youtube.com/vi/" + thumbnail[4] + "/0.jpg"
                //     }else if(el.metaData.link.includes('vimeo')){
                //         let vimeodata = await recordingRepo.getVimeoThumb(thumbnail[4])
                //         thumbnail = vimeodata.data.pictures.sizes[2].link
                //     }

                //     index = index + 1
                //     el.metaData.thumbnail = thumbnail;
                // }
                
                el.metaData.display = true;
                store.commit("recording/addrecordings", el);
            });

            store.state.recording.recordings.sort((a, b) => a.metaData.title.localeCompare(b.metaData.title))
            // var checker = setInterval(() => {
            //     console.log("num of items:" + res.data.Items.length)
            //     console.log(index)
            //     if (res.data.Items.length === index) {
            //             clearInterval(checker);
            //             return result
            //     }
            // }, 1000);
            return result

        } catch (error) {
            result.status = false
            result.error = error;
            console.log(result.error)
        }
    }

    async update(updatedRecording){
        let result = {
            status: true
        }
        try {
            const res = await recordingRepo.update(updatedRecording)
            if (res.status == 200) {
                //action goes here
                // console.log(res)
                let item = store.state.recording.recordings.filter(item => item.pk == updatedRecording.pk)[0]
                

                //GET THUMBNAIL 
                // let thumbnail = updatedRecording.metaData.link.split("/")
                    // if(updatedRecording.metaData.link.includes('youtu')){
                    //     thumbnail = "https://img.youtube.com/vi/" + thumbnail[4] + "/0.jpg"
                    // }else if(updatedRecording.metaData.link.includes('vimeo')){

                    //     let vimeodata = await recordingRepo.getPirvateVimeoThumb(thumbnail[4])
                    //     thumbnail = vimeodata.data.thumbnail_url
                    // }
                // updatedRecording.metaData.thumbnail = thumbnail
                    
            
                item.metaData = updatedRecording.metaData

            }
            return result
        } catch (error) {
            result.status = false;
            result.msg = error;
            console.log(error)
            return result
        }
    }


    async delete() {
   
       
        //prepare variables
        let recording = store.state.recording.activeRecording;
        let demoData = store.state.demoData;
        let result = {
            status: true
        }

        if (!demoData) {
            try {
                const res = await recordingRepo.delete(recording)
                let finalRecordings = store.state.recording.recordings.filter(item => item.pk != recording.pk)
  
                if(res.status == 200){
                    store.commit('recording/recordings',[])
                    store.commit('recording/recordings', finalRecordings)
                
                }
            } catch (error) {
                result.error = error;
            }
        } else {
            return true
        }

    }

    async getVimeoThumb(video_id){
        try{
            const res = await recordingRepo.getVimeoThumb(video_id)
            return res
        }catch(e){

            return {"status" : 500}
        }
    }

    async getPirvateVimeoThumb(video_id){
        const res = await recordingRepo.getPirvateVimeoThumb(video_id)
        return res
    }

    async validateSource(url){
       
            let res = {status: 200, link: null, msg: "Paste the Youtube or Vimeo link", class: "text-blue-600", thumbnail: "", visibleLink: ""}
            try {
                if(url.includes('youtu')){
                    // //the passed recording link is youtube
                    // let link;
                    // if(url.includes('watch')){
                    //     // e.g https://www.youtube.com/watch?v=XOIko4RnQq4
                    //     // This is the case when user copys the url directly from the URL
                    //     link = url.split("/")
                    //     link = link[3].split('watch?v=')

                    //     if(link[1].includes('&')){

                    //         res.link = `https://www.youtube.com/embed/${link[1].split('&')[0]}`
                    //         res.thumbnail = "https://img.youtube.com/vi/" + link[1].split('&')[0] + "/0.jpg"
                    //         res.visibleLink = `https://youtu.be/${link[1].split('&')[0]}`

                    //     }else{
                    //         res.link = `https://www.youtube.com/embed/${link[1]}`
                    //         res.thumbnail = "https://img.youtube.com/vi/" + link[1] + "/0.jpg"
                    //         res.visibleLink = `https://youtu.be/${link[1]}`
                    //     }
                    // }else if(url.includes('youtu.be')){
                    //     //https://youtu.be/XOIko4RnQq4
                    //     // This is the case when user gets the URL from share button
                    //     link = url.split("/")
                    //     res.link = `https://www.youtube.com/embed/${link[3]}`
                    //     res.thumbnail = "https://img.youtube.com/vi/" + link[3] + "/0.jpg"
                    //     res.visibleLink = `https://youtu.be/${link[3]}`
            
                    // }else if(url.includes('iframe')){
                    //     // This is the case when user pass the iframe URL directly.
                    //     let parser = new DOMParser();
                    //     let htmlDoc = parser.parseFromString(url, 'text/html');
                    //     let iframeSrc = htmlDoc.querySelectorAll("iframe");
                    //     link = iframeSrc[0].src.split("/")
                    //     res.link = `https://www.youtube.com/embed/${link[4]}`
                    //     res.thumbnail = "https://img.youtube.com/vi/" + link[4] + "/0.jpg"
                    //     res.visibleLink = `https://youtu.be/${link[4]}`
                    // }
                    
            
                }else if(url.includes('vimeo')){
                    let link;

                    // if(type == 'private'){
                        
                    //     link = url.split("/")
                    //     res.link = `https://player.vimeo.com/video/${link[3]}`
                    //     let vimeodata = await this.getPirvateVimeoThumb(link[3])
           
                    //     res.thumbnail = vimeodata.data.thumbnail_url
                    //     res.visibleLink = `https://vimeo.com/${link[3]}`
                        
                    //     // console.log(vimeodata)
                        
                    // }else{

                        if(url.includes('iframe')){
                            // This is the case when user pass the iframe URL directly.
                            let parser = new DOMParser();
                            let htmlDoc = parser.parseFromString(url, 'text/html');
                            let iframeSrc = htmlDoc.querySelectorAll("iframe");
                            link = iframeSrc[0].src.split("/")
                            link = link[4].split('?')

                            res.link = `https://player.vimeo.com/video/${link[0]}`
                            res.status = 200;
                            

                            // let vimeodata = await this.getVimeoThumb(link[0])
                            // res.thumbnail = vimeodata.data.pictures.sizes[2].link
                            // res.visibleLink = `https://vimeo.com/${link[0]}`
                        }else{

                            
                            // This is the case when user pass the just url.
                            link = url.split("/")
                            res.link = `https://player.vimeo.com/video/${link[link.length -1]}`
                            // let vimeodata = await this.getVimeoThumb(link[3])
                            // console.log(vimeodata)
                            // res.thumbnail = vimeodata.data.pictures.sizes[2].link
                            res.visibleLink = `https://vimeo.com/${link[link.length -1]}`
                            res.status = 200;
                        }
                        
                    // }            
                }else{
                    console.log('not support')
                    res.status = 500
                    res.class = "text-red-600"
                    res.msg = `Oops, the source doesn't seem from Youtube and Vimeo. We currently support Youtube and Vimeo videos only.`
                }

                return res
            } catch (error) {
                res.status = 500
                res.msg = error
                res.visibleLink = url
                
                return res
            }

    }
}