<template>
  <!-- :style="{'background-image':'url('+backgroundImage+')'}"  -->
  <div
    v-if="score"
    @click="toggleModal()"
    :class="element ? 'cursor-pointer hover:bg-opacity-75' : ''"
    class="bg-cover bg-center rounded-md col-span-1 text-center text-white text-xs flex items-center"
  >
    <div
      class="w-full grid grid-cols-12 sm:gap-4 bg-gray-900 rounded-md h-full p-4 relative"
    >
      <div class="absolute bottom-0 right-0">
        <svg
          class="w-3 h-3 text-gray-100 m-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          ></path>
        </svg>
      </div>
      <div
        class="col-span-12 sm:col-span-8 text-center sm:text-left h-full items-center flex"
      >
        <div class="w-full truncate">
          <h2 class="block truncate text-sm sm:text-base">{{ element.title }}</h2>
          <div class="w-full truncate text-sm text-gray-600">
            {{action}}
          </div>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-4">
        <div
          class="rounded py-2 w-full my-1 sm:my-2"
          :class="
            score.data[score.data.length - 30].y >
            score.data[score.data.length - 29].y
              ? 'bg-teal-600'
              : 'bg-red-400'
          "
        >
          <h3 class="font-bold text-xl sm:text-4xl">
            {{ score.data[score.data.length - 30].y }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
import { store } from "../store/index";
export default {
  mixins: [globalMixin],
  props: ["snaps", "element"],
  data() {
    return {
      score: null,
      scoreIndex: null,
      
    };
  },
  computed:{
    action(){
      let _action;
      this.element.scores.forEach(s => {
        if((s.score + 1) == this.score.data[this.score.data.length - 30].y){
          _action = s.action
        }
      })
      
      return _action
    }
  },
  methods: {
    loadScore() {
      console.log("EL TITLE", this.element.title);
      this.snaps.forEach((s, index) => {
        console.log("snaps", s);
        if (s.name == this.element.title) {
          console.log("found score", s);
          this.scoreIndex = index;
          this.score = s;
          console.log("element", this.element)
          this.element.scores.forEach((s) => {
            console.log("scorez", s.score)
            if (s.score == this.score.data[this.score.data.length].x)
              this.action = s.action;
          });
        }
      });
    },
    toggleModal(){
      if(this.action){
        store.commit("selectedIntervention", this.action)
        this.openModal('showIntervention')
      }
    }
  },
  watch: {
    snaps() {
      this.loadScore();
    },
  },
  mounted() {
    this.loadScore();
  },
};
</script>

<style lang="postcss">
.gradTile {
  background: rgb(15, 22, 34);
  background: linear-gradient(
    180deg,
    rgba(15, 22, 34, 1) 0%,
    rgba(15, 22, 34, 0.75) 100%
  );
}
</style>
