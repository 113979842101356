export const PulseCheckModule = {
    namespaced: true,
    state : {
        incompletedPulses: [],
        selectedTeams: [],
        pulseCheckComments: [],
        pulses: []
    },
    mutations : {
        incompletedPulses(state, payload){
          state.incompletedPulses = payload  
        },
        addIncompletedPulses(state,payload){
            state.incompletedPulses.unshift(payload) 
        },
        selectedTeams(state, payload){
            state.selectedTeams = payload
        },
        addSelectedTeam(state, payload){
            state.selectedTeams.unshift(payload) 
        },
       
        pulseCheckComments(state, payload){
            state.pulseCheckComments = payload
        },

        addPulseCheckComments(state, payload){
            state.pulseCheckComments.unshift(payload)
        },

        addPulse(state, payload){
            state.pulses.unshift(payload)
        },
        
        pulses(state, payload){
            state.pulses = payload
        }

    },
}