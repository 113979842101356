<template>
  <div class="w-full h-full bg-white rounded-lg overflow-y-scroll">
    <main class="relative flex">
      <div class="w-full h-full">
        <div
          class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
        >
          <div class="divide-y divide-gray-200 lg:col-span-12">
            <!-- Profile section -->
            <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
              <div>
                <h2 class="text-lg font-bold leading-6 text-gray-900">
                  Reset your password
                </h2>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  {{ employee.metaData.fullName.firstName }} will be required to
                  reset the password
                </p>
              </div>

              <div class="grid grid-cols-12 gap-6">
                <div class="sm:col-start-1 col-span-12">
                  <label
                    for="title"
                    class="block text-sm font-medium leading-5 text-gray-700"
                    >Enter a new password</label
                  >

                  <input
                    v-model="password"
                    id="password"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div class="flex px-6 mb-6">
          <div
            @click="resetPassword()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Reset Password
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import {store} from "../../store/index"
export default {
  mixins: [globalMixin],
  data() {
    return {
      password: "",
      errored: ""
    };
  },
  computed:{
    employee(){
      return store.state.employee
    }
  },
  methods: {
    resetPassword() {
      let config = {
        headers: {
          Authorization: this.token.IdToken
        }
      };

      let body = {
        username: this.employee.metaData.email,
        password: this.password,
        permanent: false
      };

      this.axios
        .post(
          `${process.env.VUE_APP_API_URL}/auth/adminSetUserPassword`,
          body,
          config
        )
        .then(res => {
          if (res.data) {
            this.closeModal();
          }
        })
        .catch(async (error) => {
          this.errored = error;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
