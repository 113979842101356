<template>
  <div class="p-10 bg-white">
    <div class="w-full text-2xl font-bold">Book your free demo</div>
    <div class="w-full text-lg">
      Fill out the form below and we'll send your details to the next available
      consultant.
    </div>
    <div id="hubspotForm" class="w-full mt-2"></div>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
export default {
  mixins: [globalMixin]
};
</script>
