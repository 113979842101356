// import { store } from "@/store/index.js"
import { UtilRepo } from "@/repo/UtilRepo.js"
const utilRepo = new UtilRepo();

export default class UtilService {

    async uploadFileToCloudinary(evt) {
        const res = await utilRepo.uploadImg(evt);
       
        // let _img = res.secure_url.split("upload/");
        // _img = _img[0] + "/upload/c_scale,w_500/" + _img[1];

        // console.log(_img);
        // console.log(res);
        return res;
    }

    async requestForCodeToResetPwd(email){
        let result = {
            status: true
        }
        try {
            const res = await utilRepo.requestForCodeToResetPwd(email)
            if (res.status == 200) {
                console.log('Success')
            }
            return result

        } catch (error) {
            result.status = false;
            result.msg = error;
            return result
        }
    }

    async resetPassword(email, confirmationCode, password){
        let result = {
            status: true
        }
        try {
            const res = await utilRepo.resetPassword(email, confirmationCode, password)
            if(res.status == 200){
                return result
            }else{
                result.status = false;
                return result
            }

        } catch (error) {
            result.status = false;
            result.msg = error;
            return result
        }  
    }

    convertDateToString(timestamp){
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let date = new Date(timestamp);
        let res = date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
        let time = this.formatAMPM(date)
        
        return res + ' ' + time;
    }

    formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      }
}