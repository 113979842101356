import { store } from "@/store/index.js"
// import { EventBus } from "@/services/bus.js"
import { InternalResourceRepo } from "@/repo/InternalResourceRepo.js"

const internalResourceRepo = new InternalResourceRepo();

export default class InternalResourceService {
    
    async update(metaData) {
        let selectdedResource = store.state.selectedResource
        selectdedResource.metaData = metaData

        let result = {
            status: true
        }
        try {
            const res = await internalResourceRepo.update(selectdedResource)
            
            if(res.status == 200){
                //DO ACTION..
            }

            return result

        } catch (error) {
            result.status = false;
            result.msg = error;
            return result
        }
    }

    // Try
    async delete() {
        // EventBus.$emit('resource-page-loader', true);
        // store.commit("showModal", null)
        //prepare variables
        let resource = store.state.selectedResource;
        let demoData = store.state.demoData;
        let result = {
            status: true
        }

        if (!demoData) {
            try {
                const res = await internalResourceRepo.delete(resource)

                console.log(res)

                if(res.status == 200){
                    console.log('here!')
                    let selected = store.state.selectedResource
                    let resources = store.state.internalResources.filter(el => el.pk != selected.pk)


                    console.log(selected)
                    console.log(resources)

                    store.commit("internalResources", [])
                    store.commit("internalResources", resources)
                    store.commit("selectedResource", null)
                }
                
                // store.commit("removeInternalResources", resource)
                // store.commit("selectedResource", null)
                // EventBus.$emit('resource-page-loader', false);

            } catch (error) {
                result.error = error;
                // EventBus.$emit('resource-page-loader', false);
            }
        } else {
            return true
        }

    }

    async fetch() {
        
      
        let result = {status: true}

        try {

            let loadData = true
            let lastEvaluatedKey = 'na'
            store.commit('internalResources', [])
            while (loadData == true) {
                const res = await internalResourceRepo.fetch(lastEvaluatedKey)
                let resources = store.state.internalResources

                res.data.Items.forEach(el => {
                    let exist = resources.filter(data => data.pk == el.pk)
                    if(exist.length == 0){
                        el.display = true
                        store.commit("addInternalResources", el);
                    }
                });

                if(res.data.LastEvaluatedKey == 'na' || res.data.LastEvaluatedKey == undefined){
                    loadData = false
                }else{
                    lastEvaluatedKey = res.data.LastEvaluatedKey
                }
            }

            store.state.internalResources.sort((a, b) => a.metaData.title.localeCompare(b.metaData.title))

            return result
    
        } catch (error) {
            result.status = false
            result.error = error;
        }

        return result
    }
}