<template>
    <div class="bg-white w-full flex flex-col items-center justify-center">
     <div class="header-download">
          <div class="logos">
              <div class="image">
                  <img :src="logo" alt="" width="100px">
              </div>
              <div class="divisor"></div>
              <div class="image">
                  <img src="https://cdn.shopify.com/s/files/1/0557/1621/6972/files/euda-navy_360x.png?v=1637920976" alt="" width="100px">
              </div>
          </div>
     </div>
     <div class="content">
          <div class="about-euda">
              <div class="title">About Euda</div>
              <div class="euda-content">
                  <div class="description">
                    Welcome and congratulations on taking the first steps on your wellbeing journey with Euda.<br>
                    To get started please complete the following:
                    <div class="font-bold">
                        
                        <ul class="leading-7">
                            <li class="number-list">Download the Euda app from the App Store.</li>
                            <li class="number-list">Sign in to the App using your email (be sure to use the email address this email has been sent to), and  
                                <span v-if="passwordParams" style="font-family: Gilroy" class="font-extrabold text-indigo-600">
                                    {{ password }}
                                </span>
                                <span v-else-if="tenant && tenant == 'cb0a4066-8d63-48af-9e00-ffd26002bdbe'">
                                    the generic password: <span style="font-family: Gilroy" class="font-extrabold text-indigo-600">applus-euda</span>
                                </span>
                                
                            </li>
                            <li class="number-list">You will then be prompted to complete a simple account set up and create a new password.</li>
                            <li class="number-list">Finally, enjoy Euda.</li>
                        </ul>
                    </div>
                    
                    Euda is a holistic wellbeing hub based on positive psychology, created to help you learn, grow and be supported. The App is full of expert led content, and provides personalised weekly wellbeing activities.<br>
                    Within the App you will find:
                      <ul>
                       <li>Inspiring video content</li>
                       <li>Mindful meditations</li>
                       <li>Self development courses</li>
                       <li>Wellbeing check-in tools and insights</li>
                      </ul>
                        
                   </div>
                  <div class="video">
                    <iframe src="https://player.vimeo.com/video/865403242?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="height:100%;width: 100%" title="EUDA_EmailIntro"></iframe>
                  </div>
              </div>
          </div>
          <div class="download-web">
              <div class="title center">Download Euda</div>
              <div class="text">Scan the QR code with your phone or tablet to download Euda now:</div>
              <div class="qr-code">
                <span>Download from your <b>IOS</b></span>
                <span>Download from your <b>Android</b></span>
              </div>
              <div class="qr-code">
                  <img class="qr-code-img" src="../assets/qrcodeIOS-EUDA.png" alt="QR code for IOS">
                  <img class="qr-code-img" src="../assets/qrcode-EUDA.png" alt="QR code for Android">
                
              </div>
              <div class="text"><i>Alternatively, search for Euda on your app store.</i></div>
          </div>
          <div class="download-app">
            <div class="download-btn">
                <a style="z-index: 9;" target="_blank" href="https://play.google.com/store/apps/details?id=com.euda.euda_app" title="Download the Euda android App from Google Play" class="app-icon">
                    <Icon :target="{name: 'download-android'}" />
                </a>
                <a style="z-index: 9;" target="_blank" href="https://apps.apple.com/au/app/euda/id1598073737" title="Download the Euda IOS App from AppleStore" class="app-icon">
                    <Icon :target="{name: 'download-apple'}" />
                </a>
            </div>
              <img src="../assets/footer.png" class="footer-img">
          </div>
      </div>
    </div>
  </template>

<script>

import Icon from "@/components/pages/TemplateIcon.vue"
export default {
    data() {
        return {
            username: this.$route.query.username,
            password: this.$route.query.password,
            logo: this.$route.query.logo,
            tenant: this.$route.query.tenant
        }
    },
    computed:{
        passwordParams(){
            return this.$route.query.password
        }
    },
    components: {
        Icon
    },

    created() {
        //console.log('USERNAME: ', this.username);
        //console.log('PASSWORD: ', this.password);
        console.log('TENANT:', this.tenant);
    }
}
</script>

  
  <style>
      *{
          font-family: 'GilroyRegular';
  
      }

        ul {
            margin: 1rem 2rem!important;
        }

        li {
           list-style: outside; 
        }

        .number-list {
            list-style: decimal  !important;;
        }

        .header-download {
            display: flex;
            justify-content: center;
            width: 100%; 
            height:10vh; 
            box-sizing: border-box; 
            padding: 2rem 2rem 0 2rem;
            justify-content: center; 
            align-items: center; 
            max-width: 1500px;
        }

        .logos {
            width: 300px; 
            height: 100%; 
            display: flex; 
            align-items: center; 
            justify-content: space-between;
        }

        .divisor {
            height: 100%; 
            width: 1px; 
            background-color: #354251
        }

        .about-euda {
            width: 100%; 
            display: flex; 
            flex-direction: column; 
            box-sizing: border-box; 
            padding: 2rem;
        }

        .euda-content {
            width: 100%; 
            display: flex;
        }

        .description {
            font-weight: 400;
        }

        .qr-code {
            width: 100%; 
            display: flex;
            justify-content: space-around;
            max-width: 700px;
        }

        .qr-code-img {
            width: 133px;
        }
  
        .content {
            max-width: 1500px;
            width: 100%; 
            height: 90vh; 
            box-sizing: border-box; 
            padding: 2rem;
        }
  
        .title {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            font-size: 25px;
            font-family: Gilroy;
            font-weight: 600;
            color: #354251;
        }

        .center {
            justify-content: center!important;;
        }
  
        .text {
            width: 100%;
            font-weight: 300;
            padding: 1rem 0;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
        }
  
        .description {
            padding: 1em;
            width: 50%;
            order: 0;
        }
  
        .video {
            width: 50%;
            order: 1;
            padding: 1em;
            max-height: 370px;
        }

        .vp-center{
            align-items: flex-start!important;
        }

        .button {
            background-color: #006E80;
            width: 250px; 
            height: 40px; 
            border-radius: 6px; 
            color: white; 
            border: none;
            z-index: 999;
        }
  
        .footer-img {
            width: 100vw;
            position: relative;
            z-index: 0;
            left: 0;
            bottom: 0;
        }
  
        .footer-img-web {
            width: 50vw;
            position: absolute;
            z-index: 0;
            left: 50%;
            bottom: 0!important;
        }

        .download-app {
            display: none;
            flex-direction: column;
        }
  
        .download-web {
            display: flex;
            width: 100%; 
            flex-direction: column; 
            box-sizing: border-box; 
            padding: 0 2rem;
            align-items: center;
        }

        .download-btn {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
  
        @media screen and (max-width: 500px) {
            .content {
                padding: 0;
            }
           .download-app {
                display: flex;
            }
  
            .download-web {
                display: none;
            }

            .footer-img-web {
                display: none;
            }
        }
  
        @media screen and (max-width: 768px) {
            .title {
                justify-content: center;
            }
            .euda-content {
                flex-wrap: wrap;
            }
  
            .description {
                width: 100%;
                order: 1;
            }
  
            .video {
                display: flex;
                justify-content: center;
                width: 100%;
                order: 0;
            }
            .footer-img-web {
                position: relative;
            }
        }
  
  
</style>


  