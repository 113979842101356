<template>
  <div class="bg-gray-900 rounded-lg w-full">
    <div class="p-10" v-if="currentSlide == 1">
      <h2 class="text-2xl text-primaryBlue leading-none">
        Assign a team to {{ employee.metaData.fullName.firstName }}
      </h2>
      <h3 class="my-4 text-gray-400 leading-tight">
        Enter one or more emails to assign them to
        {{ employee.metaData.fullName.firstName }}. We'll send your team an
        introductory email to get started.

        <span class="italice">Please separate multiple emails with a comma ( , )</span>
      </h3>

      <div class="w-full flex flex-wrap mt-4 rounded-full">
        <textarea
          v-model="emails"
          class="w-full bg-gray-800 rounded p-4 text-sm text-gray-500 outline-none"
          placeholder="Copy and paste emails here"
          cols="30"
          rows="4"
        ></textarea>

        <div
          @click="saveEmails()"
          class="w-full bg-primaryBlue hover:bg-purple-600 mt-4 text-white p-4 mt-2 rounded-lg text-center shadow-lg cursor-pointer font-bold"
        >
          Invite team
        </div>
      </div>

      <h3 class="my-4 text-gray-500 text-xs leading-tight pt-4">
        Each team member that signs up via this link can be managed by
        {{ employee.metaData.fullName.firstName }}. This includes updating
        details, viewing wellbeing data and sending pulse surveys.
      </h3>
    </div>

        <transition name="flip">
      <div class="p-10" v-if="currentSlide == 2">
        <h2 class="text-2xl text-primaryBlue leading-none ">
        We have sent an invitation to each one of your team members.
      </h2>
      <p class="text-gray-300 text-sm mt-4">
        Be sure they check their spam folder if they don't see it right away. Your team will be instructed on how to sign up. After which their data will be accessible by {{ employee.metaData.fullName.firstName }} and admin users. 
      </p>

      <div
          @click="closeModal()"
          class="w-full bg-primaryBlue hover:bg-purple-600 mt-4 text-white p-4 mt-2 rounded-lg text-center shadow-lg cursor-pointer font-bold"
        >
          Finished
        </div>

      </div>
     </transition>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import { store } from "../../store/index";
import chunks from "split-array-into-chunks";

export default {
  mixins: [globalMixin],
  data() {
    return {
      currentSlide: 1,
      emails: "",
    };
  },
  methods: {
    saveEmails() {
      this.currentSlide = 2
      const _emails = this.emails.match(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g);
      const _chunks = chunks(_emails, 20);

      _chunks.forEach((_chunk) => {
        let config = {
          headers: {
            Authorization: this.token.IdToken,
          },
        };

        let body = {
          type: "emailInvite",
          emails: _chunk,
          manager: this.employee,
          company: this.companyData
        };

        // Make api call
        this.axios
          .post(
            `${process.env.VUE_APP_API_URL}/invitations`,
            body,
            config
          )
          .then(() => {
          })
          .catch((error) => {
            this.errored = error;
          });
      });
    },
  },
  computed: {
    employee() {
      return store.state.employee;
    },
  }
};
</script>
