<template>
<div class="flex flex-wrap items-center h-full w-full rounded-lg" :class="appearance.background">

    <div @click="closeModal()" class="w-full min-h-10 py-5">
    </div>
    <div class="w-full text-center py-3 tracking-tight mb-2 font-bold text-lg" :class="appearance.textColour">
        Are you sure?
    </div>

    <div class="w-full flex flex-wrap justify-center">
        <div class="w-1/2 mb-10">
            <div @click="deleteResource()" class="text-base rounded text-center rounded-l relative p-2 cursor-pointer bg-primaryBlue hover:bg-purple-600 text-white">
                Yes
            </div>
        </div>
    </div>

</div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import { store } from "../../store/index";

export default {
    mixins: [globalMixin],
    methods: {
        async deleteResource(){
            await this.$InternalResourceService.delete()
            store.commit("showModal", null)
        }
    },
    computed: {
        appearance(){
            return store.state.light ?  this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        },
    }
};
</script>