// import { store } from "@/store/index.js"
import axios from 'axios'

export class UtilRepo {

   async requestForCodeToResetPwd(email){
        let credential = {username: email}
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/auth/forgotPassword`,
                credential
            )
   }

   async resetPassword(email, confirmationCode, password){
        return axios
            .post(
            `${process.env.VUE_APP_API_URL}/auth/confirmForgotPassword`,
            {
                username: email,
                confirmationCode: confirmationCode,
                password: password
            }
            )
   }


   async uploadImg(evt){

    return new Promise((resolve, reject) => {
        //Ideally these to lines would be in a .env file
        const CLOUDINARY_URL =
            "https://api.cloudinary.com/v1_1/eudawellbeing/upload";
        const CLOUDINARY_UPLOAD_PRESET = "myyaups8";
        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        formData.append("folder", "resourceImages");
        formData.append("file", evt.target.files[0]);
        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
            // File uploaded successfully
            if (request.readyState === 4 && request.status === 200) {
                let response = JSON.parse(request.responseText);
                // let _img = response.secure_url.split("upload/");
                // _img = _img[0] + "/upload/c_scale,w_500/" + _img[1];
                // console.log(_img);
                // finalImgLink = _img;
       
                // this.imgstatus = "uploaded"
                resolve(response);
            }
            // Not successfull, let find our what happened
            if (request.status !== 200) {
                // this.imgstatus = "errored"
                let response = JSON.parse(request.responseText);
                let error = response.error.message;
                // this.errorText = "error uploading files " + error;
                // this.isError = true;
                reject(error);
            }
        };
        request.onerror = (err) => {
            alert("error: " + err);
            reject(err);
        };
        request.send(formData);
        // return finalImgLink;
    });
   }

}