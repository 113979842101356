<template>
  <div class="py-10 bg-white w-full h-full rounded-xl">
    <div
      @click="closeModalLarge()"
      class="rounded text-primaryBlue bg-white absolute top-0 right-0 m-4 cursor-pointer hover:text-blue-600"
    >
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>

    <div class="w-full h-full overflow-y-scroll px-10">
      <div class="w-full text-2xl font-bold text-dkBlue">
        User Management
      </div>
      <div class="w-full text-gray-500 leading-none mt-3">
        We recommend you take some action for this user. If you like you can
        send this directly to the manager or you can set up a trigger to auto
        send actions to your managers when an user is feeling distressed.
      </div>
      <fieldset>
        <legend class="sr-only">Privacy setting</legend>

        <!-- <div
        class="flex my-10 rounded bg-purple-100 border border-purple-600 p-4"
      >
        <div class="flex-none text-purple-600 font-bold mt-2">Actions</div>
        <div class="ml-auto flex-none flex">
          <div
            class="hover:bg-purple-600 cursor-pointer rounded bg-purple-500 text-white p-2 text-center flex-none"
          >
            Send actions to manager
          </div>
        </div>
        <div class="ml-2 flex-none flex">
          <div
            class="hover:bg-purple-600 cursor-pointer rounded bg-dkBlue text-white p-2 text-center flex-none"
          >
            <svg
              class="inline-block w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
              ></path>
            </svg>
            Set automated trigger
          </div>
        </div>
      </div> -->

        <div class="flex w-full mb-4 mt-10">
          <div class="font-bold text-dkBlue">Action items</div>
          <div
            class="mt-1 ml-auto cursor-pointer hover:text-purple-600 text-xs text-primaryBlue flex-none ml-2"
          >
            <router-link to="/system-builder">
              <div @click="closeModalLarge()">Manage Wellbeing Systems</div>
            </router-link>
          </div>
        </div>

        <div class="bg-white -space-y-px rounded-lg ">
          <div
            v-for="(i, index) in missions"
            :key="index"
            @click="selected = index"
            :class="
              selected == index ? 'bg-blue-100 border-primaryBlue z-10' : ''
            "
            class="cursor-pointer hover:bg-gray-100 relative border p-4 flex"
          >
            <label
              for="settings-option-0"
              class="ml-3 flex flex-col cursor-pointer"
            >
              <span class="text-dkBlue block text-sm font-bold">
                {{ i.title }}
              </span>
              <span class="text-primaryBlue block text-sm">
                {{ i.description }}
              </span>
            </label>
          </div>
        </div>
      </fieldset>

      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="mt-10 bg-gray-100 rounded p-6">
        <div class="font-bold text-dkBlue">Archived Actions</div>
        <ul class="divide-y divide-gray-200">
          <li class="py-4">
            <div class="flex space-x-3">
              <img
                class="h-6 w-6 rounded-full"
                src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixqx=gfctlLqjCG&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                alt=""
              />
              <div class="flex-1 space-y-1">
                <div class="flex items-center justify-between">
                  <h3 class="text-sm font-bold">Impliment vacation strategy</h3>
                  <p class="text-xs text-gray-800 font-bold">
                    Date: 20-12-2020
                  </p>
                </div>
                <p class="text-sm text-gray-500">
                  Manager assigned:
                  <span class="text-primaryBlue hover:underline cursor-pointer"
                    >Jackie McQuay</span
                  >
                </p>
                <div class="text-sm text-gray-500 mt-4">
                  <svg
                    class="inline-block w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  No action taken
                </div>
              </div>
            </div>
          </li>
          <li class="py-4">
            <div class="flex space-x-3">
              <img
                class="h-6 w-6 rounded-full"
                src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixqx=gfctlLqjCG&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                alt=""
              />
              <div class="flex-1 space-y-1">
                <div class="flex items-center justify-between">
                  <h3 class="text-sm font-bold">
                    Issue complimentary stress relief day
                  </h3>
                  <p class="text-xs text-gray-800 font-bold">
                    Date: 20-12-2020
                  </p>
                </div>
                <p class="text-sm text-gray-500">
                  Manager assigned:
                  <span class="text-primaryBlue hover:underline cursor-pointer"
                    >Jackie McQuay</span
                  >
                </p>
                <div class="text-sm text-teal-500 leading-tight mt-4">
                  <svg
                    class="w-4 h-4 inline-block text-green-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  "I met with Rebecca and thanked her for her hard work. She'll
                  be taking a stress day tomorrow."
                </div>
              </div>
            </div>
          </li>

          <!-- More items... -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
export default {
  mixins: [globalMixin],
  data() {
    return {
      selected: -1,
      missions: [
        {
          title: "Meet about professional goals",
          description:
            "It looks like there is a lack of drive and motivation here. Try setting some clear short term goals. Set calendar reminders to follow up on each of them."
        },
        {
          title: "Issue stress leave",
          description:
            "A high stress trigger has been tripped. If appropriate, consider offering some time off."
        }
      ]
    };
  }
};
</script>
