import { store } from "@/store/index.js"
import axios from 'axios'

export class TenantRepo {
    async update(edittedItem) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            pk: edittedItem.pk,
            sk: edittedItem.sk,
            metaData: edittedItem.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/tenants`,
                body,
                config
            )
    }
}

