<template>
  <div class="flex flex-wrap w-full bg-white rounded-lg items-center h-full">
    <div class="p-10 flex w-full" v-if="loading">
      <loader class="mx-auto" />
    </div>
    
    <div
      v-if="!loading && activeQuestion == 0"
      class="p-10 flex flex-wrap w-full"
    >
      <div
        v-if="errored"
        class="w-full text-center  bg-red-100 rounded-full p-1 text-red-600 text-xs"
      >
        {{ errored }}
      </div>
      
  
      <div class="w-full text-center my-4">
        <h2 class="text-gray-700 font-bold leading-none text-2xl">
          {{ pulse.metaData.postData.metaData.body }}
        </h2>
      </div>

      <textarea
        v-model="inputData.body"
        cols="30"
        rows="5"
        placeholder="Respond here, then check if you want to submit anonymously"
        class="mt-4 outline-none p-4 text-lg w-full rounded-lg shadow-lg bg-gray-100 focus:border-primaryBlue border-2 border-transparent hover:border-primaryBlue focus:bg-white"
      ></textarea>

      <div class="flex w-full">
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <div class="flex-none">
          <button
            @click="inputData.anonymous = !inputData.anonymous"
            type="button"
            :class="inputData.anonymous ? 'bg-green-500' : 'pl-1 bg-gray-200'"
            class="my-6 w-12 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            aria-pressed="false"
          >
            <span class="sr-only">Use setting</span>
            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
            <span
              :class="inputData.anonymous ? 'translate-x-5' : 'translate-x-0'"
              class="pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            >
              <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
              <span
                :class="
                  inputData.anonymous
                    ? 'opacity-0 ease-out duration-100'
                    : 'opacity-100 ease-in duration-200'
                "
                class="ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                aria-hidden="true"
              >
                <svg
                  class="bg-white h-3 w-3 text-gray-400"
                  fill="none"
                  viewBox="0 0 12 12"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
              <span
                :class="
                  inputData.anonymous
                    ? 'opacity-100 ease-in duration-200'
                    : 'opacity-0 ease-out duration-100'
                "
                class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                aria-hidden="true"
              >
                <svg
                  class="bg-white h-3 w-3 text-indigo-600"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path
                    d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                  />
                </svg>
              </span>
            </span>
          </button>
        </div>

        <div class="ml-2 w-3/4 text-gray-400 pt-6">
          Make Respose Anonymous
        </div>
      </div>

      <div
        @click="submitResponse(pulse)"
        class="hover:bg-purple-600 cursor-pointer transition duration-500 ease-in-out p-4 w-full bg-primaryBlue rounded-full text-white text-center text-lg"
      >
        Post Response
      </div>
    </div>

    <transition name="flip">
      <div
        v-if="!loading && activeQuestion == 1"
        class="flex flex-wrap bg-white w-full items-center rounded-xl"
      >
        <div class="min-h-4 w-full flex flex-wrap py-10">
          <div class="w-full text-center">
            <h2 class="text-3xl font-bold lleading-none text-dkBlue">
              Hooray!
            </h2>
          </div>
          <div class="px-20 w-full text-center">
            <h2 class="text-xl font-bold leading-none text-dkBlue">
              You're all done here.
            </h2>
            <div
              @click="closeModal()"
              class="w-1/2 mx-auto mt-6 hover:bg-purple-600 cursor-pointer transition duration-500 ease-in-out p-4 w-full bg-primaryBlue rounded-full text-white text-center text-lg"
            >
              Have a great day
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import loader from "@/components/pages/TemplateLoaderDark";
import { store } from "../../store/index";
import moment from "moment";

export default {
  mixins: [globalMixin],
  data() {
    return {
      errored: "",
      lastEvaluatedKey: "na",
      loading: false,
      activeQuestion: 0,
      pulseItems: [],
      inputData: {
        body: "",
        anonymous: false,
        timestamp: "",
      },
    };
  },
  computed: {
    pulse() {
      return store.state.pulse;
    },
  },
  methods: {
    async submitResponse() {
      this.loading = true;
      this.errored = "";

      if (!this.inputData.body) {
        this.errored = "You can't submit an empty response";
        this.loading = false;
        return;
      }

      // Update the timestamp
      this.inputData.timestamp = moment();
      // Save user data to inputData
      this.inputData.fullName = this.userData.metaData.fullName;
      this.inputData.email = this.userData.metaData.email;

     

      // Build params for post
      let lastIndex = this.pulse.pk.lastIndexOf("#");

      console.log('============================');
      
      console.log(this.pulse.pk.substr(lastIndex));

      console.log('============================');


      let body = {
        type: "comment",
        postId: this.pulse.pk.substr(lastIndex),
        metaData: {
          postData: this.inputData,
        },
      };

      let config = {
        headers: {
          Authorization: this.token.IdToken,
        },
      };
      console.log("config", `${process.env.VUE_APP_API_URL}/posts`);
      await this.axios
        .post(`${process.env.VUE_APP_API_URL}/posts`, body, config)
        .then(async () => {
          console.log("Made the response");
          await this.deleteNotification();
        })
        .catch(async (error) => {
          this.errored = error;
        })
        .finally(() => (this.loading = false));
    },

    async deleteNotification() {

      console.log('==================');
      console.log(store.state.pulse.pk)
      console.log(store.state.pulse.sk)
      console.log('==================');
      
      let config = {
        headers: {
          Authorization: this.token.IdToken,
        },
        params: {
          pk: store.state.pulse.pk,
          sk: store.state.pulse.sk,
        },
      };

      await this.axios
        .delete(`${process.env.VUE_APP_API_URL}/notifications`, config)
        .then(async (response) => {
          if (response.status == 200) {
            await this.getNotificationData();
            this.activeQuestion = 1
          }
        })
        .catch(async (error) => {
          this.errored = error;
        })
        .finally(() => (this.loading = false));
    },
  },

  components: {
    loader,
  },
};
</script>
