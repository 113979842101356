<template>
  <div class="w-full h-full rounded-lg overflow-y-scroll" :class="appearance.background">
    <main class="relative flex">
      <div class="w-full h-full">
        <div
          class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
        >
          <div class="divide-y divide-gray-200 lg:col-span-12">
            <!-- Profile section -->
            <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
              <div>
                <h2 class="text-lg font-bold leading-6 text-primaryBlue">
                    Edit resource category
                </h2>
              </div>

              <div class="grid grid-cols-12 gap-6">
                <div class="sm:col-start-1 col-span-12">
                  <label
                    for="title"
                    class="block text-sm font-medium leading-5"
                    :class="appearance.textColour"
                    >Title of your category</label
                  >
                  <input
                    v-model="title"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5"
                  />
                </div>

                <!-- <div class="col-span-12 col-span-12">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5"
                    :class="appearance.textColour"
                    >Color your category</label
                  >
                  <v-swatches v-model="color" inline></v-swatches>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div class="flex px-6 mb-6">
          <div
            @click="update('This feature is not available yet')"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Update Resource Category
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
// import VSwatches from "vue-swatches";
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css";

export default {
  mixins: [globalMixin],
  data() {
    return {
      title: store.state.selectCategory.metaData.title,
      color: store.state.selectCategory.metaData.color,
      errored: ""
    };
  },
  computed:{
    category(){
        return {
          // display: store.state.selectCategory.display,
          metaData:{
            color: this.color,
            title: this.title
          },
          pk: store.state.selectCategory.pk,
          sk: store.state.selectCategory.sk
        }
    },
    appearance(){
      return store.state.light ?  this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
    },
  },
  // components: { VSwatches },
  methods: {

    async update() {
      const res = await this.$InternalResourceCategoryService.update(this.category)
      if(res.status == true)this.closeModal()
    }
  }
};
</script>
<style>
  .vue-swatches__container.vue-swatches--inline{
    background: transparent !important;
  }
</style>