// import { store } from "@/store/index.js"

import { ApiRepository } from "@/repo/ApiRepo.js";
const apiRepo = new ApiRepository();
export default class ApiService {

    async fetch(args) {
        return apiRepo.fetch(args)
    }

    async create(args) {
        return apiRepo.create(args)
    }

    async delete(args) {
        return apiRepo.delete(args)
    }

    async update(args) {
        return apiRepo.update(args)
    }
}