import { store } from "@/store/index.js"
// import { EventBus } from "@/services/bus.js"
import { InterventionRepo } from "@/repo/InterventionRepo.js"
const interventionRepo = new InterventionRepo();

export default class InterventionService {

    async create(metaData){
        let result = {
            status: true
        }
        try {
            const res = await interventionRepo.create(metaData)
            if(res.status == 200){
                //DO ACTION WHEN ALL IS OK
            }
            return result
        } catch (error) {
            result.status = false;
            result.msg = error;
            console.log(error)
            return result
        }
    }

    async fetch(lastEvaluatedKey){
        const res = await interventionRepo.fetch(lastEvaluatedKey)
        let result = {
            status: true,
            lastEvaluatedKey: "na"
        }
        try {
            console.log(res.data.Items)
            if (res.data.LastEvaluatedKey) {
                result.lastEvaluatedKey = res.data.lastEvaluatedKey;
            } else {
                result.lastEvaluatedKey = "na"
            }
            store.commit("interventions", [])
            res.data.Items.forEach(x => {
                store.commit("addInterventions", x);
            });
        } catch (error) {
            result.status = false
            result.error = error;
        }
        return result
    }

    async update(updatedIntervention){
        let result = {
            status: true
        }
        try {
            const res = await interventionRepo.update(updatedIntervention)
            if (res.status == 200) {
                //action goes here
                console.log(res)
            }
            return result
        } catch (error) {
            result.status = false;
            result.msg = error;
            console.log(error)
            return result
        }
    }

}