<template>
  <div class="flex flex-wrap bg-white w-full p-4 rounded">
    <div class="p-10 flex w-full" v-if="loading">
    <loader  class="mx-auto" />
    </div>
  <div
      v-if="!loading"
      class="bg-white px-10 space-y-3 mt-10 lg:pb-8 text-gray-900"
    >
      <h2 class="font-bold text-2xl">Create your own system</h2>
      <p class="text-gray-500 text-sm leading-tight">
        Quickly integrate systematic solutions into your organisation to prevent
        wellbeing incidents from occuring. Systems are made up of actions. If an
        user triggers an action the users manager is notified.
      </p>

      <div class="mt-10">
        <label for="title" class="text-primaryBlue font-bold text-lg"
          >Let's start with a name for the system</label
        >
      </div>
      <div class="mt-2">
        <input
          type="text"
          v-model="title"
          placeholder="ie: Emotional stress leave"
          class="border-b-2 rounded-t border-gray-400 focus:border-primaryBlue p-4 w-full text-dkBlue hover:bg-gray-100 outline-none"
        />
      </div>
      <router-link to="/system-builder-form">
        <div
          @click="createItem()"
          class="mt-4 text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
        >
          Start building
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import loader from "@/components/pages/TemplateLoaderDark";
import { store } from "../../store/index";

export default {
  mixins: [globalMixin],
  data() {
    return {
      loading: false,
      title: ""
    };
  },
  methods: {
    createItem() {
      this.loading = true;

      let body = {
        metaData: {
          title: this.title
        }
      };

      let config = {
        headers: {
          Authorization: this.token.IdToken
        }
      };

      this.axios
        .post(
          `${process.env.VUE_APP_API_URL}/systems`,
          body,
          config
        )
        .then(response => {
          store.commit("system", response.data);
          this.closeModal();
          this.$router.push("/system-builder-form");
        })
        .catch(async (error) => {
          this.errored = error;
        })
    }
  },
  components: {
    loader
  }
};
</script>
