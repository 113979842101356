<template>
    <div>
    
        <div class="-mt-10 mb-10 w-full flex justify-start">
            <!-- Step-->
            <div class="w-4/12 grid grid-cols-1 gap-y-1">
                <div v-if="step >= 1">
                    <span @click="jumpTo(step - 1)" class="text-xs text-gray-500 cursor-pointer">
                        Back
                    </span>
                </div>
                <div class="text-xs text-gray-600">Step {{ step + 1}} / 3</div>
                <div class="h-1 w-full bg-gray-300 rounded relative rounded-xl">
                    <div class="absolute h-1 bg-teal-400 rounded-xl" :class="stepSlider"></div>
                </div>
            </div>
        </div>
        <transition name="translateXFull">
            <div v-if="step == 0">
                <div class="w-full mb-10">
                    <h1 class="text-gray-800 header text-3xl leading-none sm:text-4xl">
                        {{ stepData[step].header }}
                    </h1>
                    <h2 class="text-gray-600 header textxl sm:text-2xl leading-none mt-4 sm:mt-0">
                        {{ stepData[step].subHeader }}
                    </h2>
                </div>
                <div class="w-full h-72 flex items-center justify-center mb-10">
                    <div class="w-full">
                        <div class="grid grid-cols-8 w-full">
                            <div class="col-span-8 flex">
                                <label class="pt-3 text-gray-500 text-sm">First Name</label>
                            </div>
                            <div class="w-full col-span-8 bg-b relative">
                                <div class="bg-gray-400 text-white font-bold tracking-wide rounded-full p-1 absolute z-50 right-0 mt-3 mr-2 flex" style="font-size:9px;">Required</div>
                                <input :type="'text'" v-model="firstName" class="pr-16 w-full mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                            </div>
                        </div>
    
                        <div class="grid grid-cols-8 w-full">
                            <div class="col-span-8 flex">
                                <label class="pt-3 text-gray-500 text-sm">Last Name</label>
                            </div>
                            <div class="w-full col-span-8 bg-b relative">
                                <div class="bg-gray-400 text-white font-bold tracking-wide rounded-full p-1 absolute z-50 right-0 mt-3 mr-2 flex" style="font-size:9px;">Required</div>
                                <input :type="'text'" v-model="lastName" class="pr-16 w-full mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                            </div>
                        </div>
    
    
                        <div class="grid grid-cols-8 w-full">
                            <div class="col-span-8 flex">
                                <label class="pt-3 text-gray-500 text-sm">Phone Number</label>
                            </div>
                            <div class="w-full col-span-8 bg-b relative">
                                <div class="bg-gray-400 text-white font-bold tracking-wide rounded-full p-1 absolute z-50 right-0 mt-3 mr-2 flex" style="font-size:9px;">Required</div>
                                <input :type="'text'" v-model="phone" class="pr-16 w-full mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                            </div>
                        </div>
    
                    </div>
                </div>
                <div class="grid grid-cols-1 w-full">
                    <button class="w-full px-3 py-2 rounded-lg text-white" @click="jumpTo(1)" :class="`${firstName.length > 0 && lastName.length > 0 && phone.length > 0 ? 'bg-teal-400' : 'bg-gray-400' }`">
                        Next
                    </button>
                </div>
                <!-- <div class="grid grid-cols-1 w-full mt-10" v-if="">
                    <div class="text-xs text-gray-600">
                        By signing up here you agree to Euda's
                        <a class="underline" href="https://euda-resources.s3.ap-southeast-2.amazonaws.com/Euda+Privacy+Policy.pdf" target="_blank">Privacy Policy</a>
                        and
                        <a class="underline" href="https://euda-resources.s3.ap-southeast-2.amazonaws.com/End+User+License+Agreement.pdf" target="_blank">End User Licence Agreement</a>.
                    </div>
                </div> -->
    
            </div>
    
            <div v-if="step == 1">
                <div class="w-full mb-10">
                    <h1 class="text-gray-800 header text-3xl leading-none sm:text-4xl">
                        {{ stepData[step].header }}
                    </h1>
                    <h2 class="text-gray-600 header textxl sm:text-2xl leading-none mt-4 sm:mt-0">
                        {{ stepData[step].subHeader }}
                    </h2>
                </div>
                <div class="w-full h-72 flex items-center justify-center overflow-scroll mb-10">
                    <div class="w-full">
                        <div v-for="(element, index) in interests" v-bind:key="index" class="w-full my-1">
                            <div class="grid grid-cols-6 w-full border rounded cursor-pointer" :class="element.selected ? 'bg-teal-400' : 'bg-white hover:bg-gray-100'" @click="element.selected = !element.selected">
                                <div class="col-span-6 grid grid-cols-6">
                                    <label class="py-3 px-2 text-xs col-span-5" :class="element.selected ? 'text-white' : 'text-gray-500'">
                                        {{ element.title }}
                                    </label>
                                    <div class="flex justify-end col-span-1 p-2">
                                        <input type="checkbox" v-model="element.selected" class="rounded-full" />
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-1 w-full">
                    <button class="w-full px-3 py-2 rounded-lg text-white" @click="jumpTo(2)" :class="`${interests.filter(el => el.selected == true).length > 0 ? 'bg-teal-400' : 'bg-gray-400' }`">
                        Next
                    </button>
                </div>
    
                <!-- <div class="grid grid-cols-1 w-full mt-10">
                    <div class="text-xs text-gray-600">
                        By signing up here you agree to Euda's
                        <a class="underline" href="https://euda-resources.s3.ap-southeast-2.amazonaws.com/Euda+Privacy+Policy.pdf" target="_blank">Privacy Policy</a>
                        and
                        <a class="underline" href="https://euda-resources.s3.ap-southeast-2.amazonaws.com/End+User+License+Agreement.pdf" target="_blank">End User Licence Agreement</a>.
                    </div>
                </div> -->
    
            </div>
    
            <div v-if="step == 2">
                <div class="w-full mb-10">
                    <h1 class="text-gray-800 header text-3xl leading-none sm:text-4xl">
                        {{ stepData[step].header }}
                    </h1>
                    <h2 class="text-gray-600 header textxl sm:text-2xl leading-none mt-4 sm:mt-0">
                        {{ stepData[step].subHeader }}
                    </h2>
                </div>
                <div class="w-full h-72 flex items-center justify-center overflow-scroll mb-10">
                    <div class="w-full">
                        <div class="grid grid-cols-8 w-full">
                            <div class="col-span-8 flex">
                                <label class="pt-3 text-gray-500 text-sm">Your Euda username</label>
                            </div>
                            <div class="w-full col-span-8 bg-b relative">
                                <div class="bg-gray-400 text-white font-bold tracking-wide rounded-full p-1 absolute z-50 right-0 mt-3 mr-2 flex" style="font-size:9px;">Required</div>
                                <input :type="'text'" :disabled="true" v-model="username" class="pr-16 w-full mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                            </div>
                        </div>
    
                        <div class="grid grid-cols-8 w-full">
                            <div class="col-span-8 flex">
                                <label class="pt-3 text-gray-500 text-sm">Password</label>
                            </div>
                            <div class="w-full col-span-8 bg-b relative">
                                <div class="absolute z-50 right-0 mt-3 mr-3 bg-white cursor-pointer hover:text-primaryBlue flex h-6 w-6 text-xs text-gray-300" @click="showPassword()" v-html="passwordTypeIcon"></div>
                                <input :type="passwordType" v-model="password" class="pr-16 w-full mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                            </div>
                        </div>
    
                        <div class="grid grid-cols-8 w-full">
                            <div class="col-span-8 flex">
                                <label class="pt-3 text-gray-500 text-sm">Password Confirmation</label>
                            </div>
                            <div class="w-full col-span-8 bg-b relative">
    
                                <input :type="passwordType" v-model="passwordConfirmation" class="pr-16 w-full mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                            </div>
                        </div>
    
                    </div>
                </div>
                <div class="grid grid-cols-1 w-full">
                    <button v-if="!submitting" class="w-full px-3 py-2 rounded-lg text-white" @click="submit()" :class="`${interests.filter(el => el.selected == true).length > 0 ? 'bg-teal-400' : 'bg-gray-400' }`">
                        Sign Up
                    </button>
                    <button v-else class="w-full px-3 py-2 rounded-lg text-white bg-teal-400">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#transparent;display:block;" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" :stroke="'#fff'"  stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.4925373134328357s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                        </svg>
                    </button>
                </div>
    
                <div v-if="step == 2" class="grid grid-cols-1 w-full mt-10">
                    <div class="text-xs text-gray-600">
                        By signing up here you agree to Euda's
                        <a class="underline" href="https://euda-resources.s3.ap-southeast-2.amazonaws.com/Euda+Privacy+Policy.pdf" target="_blank">Privacy Policy</a>
                        and
                        <a class="underline" href="https://euda-resources.s3.ap-southeast-2.amazonaws.com/End+User+License+Agreement.pdf" target="_blank">End User Licence Agreement</a>.
                    </div>
                </div>
    
            </div>
    
        </transition>
    
    </div>
    </template>
    
    <script>
    import {
        store
    } from "@/store/index";
    import {
        EventBus
    } from "@/services/bus.js"
    export default {
        props: {
            usernameData: String,
            passwordSession: String
        },
      
        data() {
            return {
                submitting: false,
                username: this.usernameData,
                step: 0,
                stepSlider: 'w-1/3',
                passwordType: 'password',
                passwordTypeIcon: '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>',
                stepData: [{
                        header: "What's your name?",
                        subHeader: "Let's get to know each other"
                    },
                    {
                        header: `I am interested in..`,
                        subHeader: `Select as many as you want`,
    
                    },
                    {
                        header: `Let's set up your account password`,
                        subHeader: `Password requires at least 8 characters`,
                    }
                ],
    
                firstName: "",
                lastName: "",
                phone: "",
     
                password: "",
                passwordConfirmation: "",
    
                interests: [{
                        title: 'Mental and emotional wellbeing',
                        description: "",
                        selected: false,
                    },
                    {
                        title: 'Physical wellbeing',
                        description: "",
                        selected: false,
                    },
                    {
                        title: 'Leadership & growth',
                        description: "",
                        selected: false,
                    },
                    {
                        title: 'Meaning & purpose',
                        description: "",
                        selected: false,
                    },
    
                    {
                        title: "Presence & mindfulness",
                        description: "",
                        selected: false,
                    },
                ]
            }
        },
    
        methods: {
            async submit() {
                this.submitting = true;
                if(this.password.length <= 6){
                    alert('Password should be at least 7 characters')
                } else if(this.password != this.passwordConfirmation){
                    alert('The password entered does not match the password confirmation.')
                }else if (this.password.length >= 7 && this.password == this.passwordConfirmation) {
                    let res = await this.axios.post(`${process.env.VUE_APP_API_URL}/auth/RespondToAuthChallenge`, {
                        username: this.username,
                        newPassword: this.password,
                        newPasswordSession: this.passwordSession
                    })
    
                    store.commit("token", res.data.AuthenticationResult);
                    store.commit("refreshToken", res.data.AuthenticationResult.RefreshToken);
                    
                    //Get User
                    let config = {
                        headers: {
                            Authorization: res.data.AuthenticationResult.IdToken
                        },
                        params: {
                            type: "employees"
                        }
                    };
                    let user = await this.axios.get(`${process.env.VUE_APP_API_URL}/employees`, config)
                    
                    //Update values
                    // console.log(user)
                    await this.axios
                        .put(
                            `${process.env.VUE_APP_API_URL}/api`,
                            {
                                pk: user.data.pk,
                                metaData:{
                                    email: this.username,
                                    phone: this.phone,
                                    profile_image: "",
                                    firstName: this.firstName,
                                    lastName: this.lastName,
                                    interests: this.interests.filter(el => el.selected == true).map(el => el.title)
                                },
                            },
                            {
                                headers: {
                                    Authorization: res.data.AuthenticationResult.IdToken
                                }, 
                            }
                        )
                        .then(result => {
                            console.log(result);
                            store.commit("userData", result.data);
                             this.$router.push('/authorization')
                            // window.location.href = '/auth'
                    })
                    
                   
                }
                this.submitting = false;
            },
            jumpTo(index) {
                if (index == 0) {
                    this.step = 0;
                    this.stepSlider = "w-1/3"
                } else if (index == 1) {
                    if (this.firstName.length > 0 && this.lastName.length > 0 && this.phone.length > 0) {
                        this.step = 1;
                        this.stepSlider = 'w-2/3';
                    } else {
                        return false;
                    }
                } else if (index == 2) {
                    if (this.interests.filter(el => el.selected == true).length > 0) {
                        this.step = 2;
                        this.stepSlider = 'w-full';
                    } else {
                        return false;
                    }
                }
            },
            showPassword() {
                if (this.passwordType === "password") {
                    this.passwordType = "text";
                    this.passwordTypeIcon =
                        '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>';
                } else {
                    this.passwordType = "password";
                    this.passwordTypeIcon =
                        '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>';
                }
            },
    
        },
        created() {
            EventBus.$on('setPasswordSession', data => {
                this.passwordSession = data.passwordSession
            });
    
            EventBus.$on('setData', data => {
                console.log('---------')
                console.log(data.username)
                console.log('---------')
                this.username = data.username
            });
    
    
        },
        beforeDestroy(){
            EventBus.$off('setPasswordSession');
            EventBus.$off('setData');
        },
        mounted() {
            if(this.$route.query.username != undefined){
                let _username = this.$route.query.username
                if (_username && _username.includes("")) _username = _username.split(/[ ]+/).join('+')
                this.username = _username
            }
        }
    }
    </script>