<template>
  <div class="bg-white rounded-lg w-full">
    <div class="p-10 flex w-full" v-if="loading">
      <loader class="mx-auto" />
    </div>

    <div class="p-10" v-if="currentSlide == 1 && !loading">
      <h2 class="text-2xl text-primaryBlue">
        Assign to team
      </h2>

      <select
          id="title"
          v-model="team"
          class="focus:shadow-outline form-input mt-1 bg-gray-100 text-gray-600 block w-full rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5"
        >
          <option :value="t" v-for="(t, index) in teams.Items" :key="index" :style="{color: t.metaData.color}">
             {{t.metaData.title}}
          </option>
        </select>
      
      <h3 class="my-4 text-gray-600 leading-tight" v-if="!demoData">
        Enter one or more emails to assign them to
        {{ selectedTeam.metaData.title }}. We'll send your team an
        introductory email to get started.

         <div class="mt-2 text-gray-600">Please separate multiple emails with a comma ( , )</div>
      </h3>
      <h3 class="my-4 text-gray-400 leading-tight" v-else>
        Whilst you are in demo mode you can not recruit users. However you can test the invitation process by sending and email to yourself.
      </h3>
      

      <div class="w-full flex flex-wrap mt-4 rounded-full">
        <textarea
        v-if="!demoData"
          v-model="emails"
          class="w-full bg-gray-200 rounded p-4 text-sm text-gray-600 outline-none"
          placeholder="Copy and paste emails here"
          cols="30"
          rows="4"
        ></textarea>

        <div
          v-if="!demoData"
          
          @click="emails.length > 0 ? create() : ''"
          
          class="w-full mt-4 text-white p-4 mt-2 rounded-lg text-center shadow-lg font-bold"
          :class="emails.length == 0 ? 'bg-gray-700' : 'bg-primaryBlue hover:bg-purple-600 cursor-pointer' "
        >
          Invite Team
        </div>
        <div
        v-else
          @click="saveEmails()"
          class="w-full bg-primaryBlue hover:bg-purple-600 mt-4 text-white p-4 mt-2 rounded-lg text-center shadow-lg cursor-pointer font-bold"
        >
          Test Invite
        </div>
      </div>

  
    </div>

    <transition name="flip">
      <div class="p-10" v-if="currentSlide == 2 && !loading">
        <h2 class="text-2xl text-primaryBlue leading-none ">
        We have sent an invitation to each one of your team members.
      </h2>
      <p class="text-gray-600 text-sm mt-4">
        Be sure they check their spam folder if they don't see it right away. Your team will be instructed on how to sign up. After which their data will be accessible by {{ userData.metaData.fullName.firstName }} and admin users. 
      </p>

      <div
          @click="closeModal()"
          class="w-full bg-primaryBlue hover:bg-purple-600 mt-4 text-white p-4 mt-2 rounded-lg text-center shadow-lg cursor-pointer font-bold"
        >
          Finished
        </div>

      </div>
    </transition>

    <transition name="flip">
      <div class="p-10" v-if="currentSlide == 'failed' && !loading">
        <h2 class="text-2xl text-primaryBlue leading-none ">
        We're sorry
      </h2>
        <p class="text-gray-600 text-sm mt-4">
        One or more emails already exist within Euda. Please try again with another email. 
        </p>

      <div
          @click="closeModal()"
          class="w-full bg-primaryBlue hover:bg-purple-600 mt-4 text-white p-4 mt-2 rounded-lg text-center shadow-lg cursor-pointer font-bold"
        >
          Close
        </div>

      </div>
    </transition>

    
     <div class="p-10 text-gray-600 text-center  rounded-md text-white text-lg" v-if="currentSlide == 3 && !loading">
       Sorry you can't assign any more people to your account as you are in trial mode.
       Would you like to upgrade?
       <div @click="openModal('bookDemo')"
      class="bg-primaryBlue rounded text-white hover:bg-purple-600 mt-4 p-2 text-center cursor-pointer"
    >
      Yes Please!
    </div>
     </div>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import { store } from "../../store/index";
import chunks from "split-array-into-chunks";
import loader from "@/components/pages/TemplateLoaderDark";
export default {
  mixins: [globalMixin],
  data() {
    return {
      team: store.state.selectedTeam,
      currentSlide: 1,
      emails: "",
      loading: false
    };
  },
  methods: {

    async create(){
      
      // this.currentSlide = 2
      if(this.demoData) this.emails = this.userData.metaData.email
        this.loading = true
        const _emails = this.emails.match(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g);
        const _chunks = chunks(_emails, 20);
        let status = 200
        var sendEmail = new Promise((resolve) => { _chunks.forEach(async (_chunk) => {
            //create an employee object that contains required data
            let employee = {metaData: {email: _chunk}, team: this.team, companyData: store.state.companyData}
            
            //create a user in aws cognito and DB (EMP#)
            const res = await this.$EmployeeService.create(employee)
            if(res.status != 200){
              status = 500
            }
            resolve();

            let _currentTeam = store.state.selectedTeam;
            store.commit("selectedTeam", null)
            store.commit("selectedTeam", _currentTeam)
          });
        });

        sendEmail.then(() => {
          if(status == 200){
            this.loading = false
            this.currentSlide = 2
          }else{
            this.loading = false
            this.currentSlide = 'failed'
          }
            
        });
    },

    loadTeams() {
      this.loading = true;

      let config = {
        headers: {
          Authorization: this.token.IdToken,
        },
        params: {
          key: {},
          LastEvaluatedKey: this.lastEvaluatedKey,
        },
      };

      this.axios
        .get(
          `${process.env.VUE_APP_API_URL}/teams`,
          config
        )
        .then((response) => {
          if (response.data.LastEvaluatedKey) {
            this.lastEvaluatedKey = response.data.LastEvaluatedKey;
            this.loading = false;
          } else {
            this.lastEvaluatedKey = "na";
            this.loading = true;
          }

          store.commit("teams", response.data);
        })
        .catch(async (error) => {
          this.errored = error;
        })
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    employeeCount(){
      return store.state.employeeCount;
    },
    selectedTeam(){
      return store.state.selectedTeam;
    },
    teams(){
      return store.state.teams;
    },
    demoData() {
      return store.state.demoData;
    },
  },
  mounted(){
     this.loadTeams()

    // if(this.employeeCount >= 5 && this.companyData.metaData.trial) this.currentSlide = 3
    // else if(!store.state.teams) this.loadTeams()
  },
  components:{
    loader
  }
};
</script>
