<template>
<div class="bg-white rounded-lg w-full h-full overflow-y-scroll p-10">
    <transition name="fade">
        <div class="p-10 flex w-full" v-if="loading">
            <loader class="mx-auto" />
        </div>
    </transition>

    <div v-if="!loading && !slide" class="w-full">
        <div class="flex w-full text-primaryBlue cursor-pointer">
            <div>
                <h2 class="text-2xl">Employee Settings</h2>
            </div>
        </div>

      
        <div v-if="employee" class="mt-6 grid grid-cols-12">
            <div class="col-span-4 items-center flex">
                <label for="email" class="block font-medium leading-5 text-gray-400">Teams</label>
            </div>
            <div class="col-span-8">
                <select v-model="team" class="text-gray-500 mt-2 focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:sm:leading-5">
                    <option v-for="(team, index) in teams.Items" :key="index" :value="team.pk">
                        {{ team.metaData.title }}
                    </option>
                </select>
            </div>

            <div class="col-span-4 items-center flex">
                <label for="email" class="block font-medium leading-5 text-gray-400">Full name</label>
            </div>
            <div class="col-span-8">
                <input v-model="fullName" id="fullName" class="text-gray-500 focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:sm:leading-5" />
            </div>

           
            <div class="col-span-4 items-center flex">
                <label for="phone" class="block font-medium leading-5 text-gray-400">Phone</label>
            </div>
            <div class="col-span-8">
                <input v-model="phone" id="phone" class="text-gray-500 focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:sm:leading-5" />
            </div>
            <div class="col-span-12 mt-10">
                <div @click="update()" class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer">
                    Update
                </div>
            </div>

        </div>

        <div @click="changeSlide('deleteEmployee')" class="bg-red-100 p-2 text-red-600 text-sm cursor-pointer text-center w-full mt-10">Delete User</div>
    </div>

    <transition name="flip">
        <div v-if="slide == 'deleteEmployee'" class="grid grid-cols-12 gap-6 py-4">
            <div class="col-span-12">
                <div @click="changeSlide('')" class="cursor-pointer">
                    <svg class="w-6 h-6 float-left mt-2 mr-1 text-primaryBlue" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"></path>
                    </svg>
                    <h2 class="text-2xl">Delete user</h2>
                </div>
            </div>
            <div class="col-span-12 text-red-500 text-lg mt-4 bg-red-100 tracking-tight leading-tight p-5 rounded">
                Are you sure you want to delete this user
            </div>
            <div class="col-span-12">
                <div @click="deleteEmployee()" class="w-full mx-auto bg-red-600 hover:bg-red-700 text-white p-4 rounded-lg text-center shadow-lg cursor-pointer font-bold">
                    Delete User Permanently
                </div>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import Loader from "@/components/pages/TemplateLoaderDark";
import { store } from "../../store/index";


export default {
    mixins: [globalMixin],
    data() {
        return {
            slide: "",
            loading: false,
            clients: [],
            lastEvaluatedKey: "na",
            selectedManager: null,
            team: store.state.employee.team,
            fullName: '',
            phone: ''
        };
    },
    computed: {
        teams() {
            return store.state.teams
        },
        employee() {
            return store.state.employee;
        },
    },
    methods: {
        async update(){
          //show loader
          this.loading = true;
          //Update the employee's team 
          let employeeTeamDataChange = {pk: this.employee.pk, team: this.team}
          await this.$EmployeeService.update(employeeTeamDataChange,'team');  

          //Update the employee's metaData
          const human = require("humanparser");
          let parsedFullName = human.parseName(this.fullName);
          let employeeMetaDataChnage = {pk: this.employee.pk, 
                                        metaData: {
                                          avatar: this.employee.metaData.avatar, 
                                          email: this.employee.metaData.email, 
                                          fullName: parsedFullName, 
                                          phone: this.phone}
                                        }
          await this.$EmployeeService.update(employeeMetaDataChnage,'settings');
          await this.$EmployeeService.fetch();  
          //close modal & remove loading bar
          store.commit('showModal', null)
          this.loading = false;
        },
        changeSlide(x) {
            this.slide = x;
        },
        getClientsAndManagers() {
            let endpoints;
            let _manager = "";
            if (this.isManager) {
                endpoints = ["managers"];
                _manager = this.manager.pk;
            } else {
                endpoints = ["clients", "managers"];
            }

            endpoints.forEach((i) => {
                let request = {
                    headers: {
                        Authorization: this.token.IdToken,
                    },
                    params: {
                        LastEvaluatedKey: this.lastEvaluatedKey,
                        manager: _manager,
                    },
                };

                this.axios
                    .get(
                        `${process.env.VUE_APP_API_URL}/${i}`,
                        request
                    )
                    .then((response) => {
                        if (response.data.LastEvaluatedKey) {
                            this.lastEvaluatedKey = response.data.LastEvaluatedKey;
                            this.loadingData = false;
                        } else {
                            this.lastEvaluatedKey = "na";
                            this.loadingData = true;
                        }

                        if (response.data.Items) {
                            response.data.Items.forEach((e) => {
                                this.clients.push(e);
                                if (
                                    e.pk.includes(`${store.state.employee.manager.split("#")[1]}`)
                                )
                                    this.selectedManager = e;
                            });
                        } else {
                            response.data.forEach((e) => {
                                this.clients.push(e);
                                if (
                                    e.pk.includes(`${store.state.employee.manager.split("#")[1]}`)
                                )
                                    this.selectedManager = e;
                            });
                        }
                    })
                    .catch(async (error) => {
                        this.errored = error;
                    });
            });
            this.loading = false;
        },

        updateEmployee(type) {
            let body = this.employee;

            this.loading = true;
            body.manager = `MAN#${
        this.selectedManager.pk.split("#")[1]
      }`;

            // Assume employee settings and parse the fullName
            const human = require("humanparser");
            let parsedFullName = human.parseName(
                this.employee.metaData.fullName.fullName
            );
            body.metaData.fullName = parsedFullName;

            // Also save the type so we can use it serverside
            body.type = type;

            // Update survey array in tenant
            let config = {
                headers: {
                    Authorization: this.token.IdToken,
                },
            };
            this.axios
                .put(
                    `${process.env.VUE_APP_API_URL}/employees`,
                    body,
                    config
                )
                .then(() => {
                    store.commit("employee", body);
                    this.loading = false;
                })
                .catch(async (error) => {
                    this.errored = error;
                });
        },
        deleteEmployee() {
            let config = {
                headers: {
                    Authorization: this.token.IdToken,
                },
                params: {
                    pk: this.employee.pk,
                    sk: this.employee.sk,
                },
            };

            this.axios
                .delete(
                    `${process.env.VUE_APP_API_URL}/employees`,
                    config
                )
                .then(() => {
                    store.commit("employee", null);
                    // reload selected Team
                    let _selectedTeam = store.state.selectedTeam
                    store.commit('selectedTeam', null)
                    store.commit('selectedTeam', _selectedTeam)
                    this.closeModal();
                })
                .catch(async (error) => {
                    this.errored = error;
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        Loader,
    },
    mounted() {
        if(store.state.employee.metaData.fullName && store.state.employee.metaData.fullName.fullName) this.fullName = store.state.employee.metaData.fullName.fullName
        if(store.state.employee.metaData.phone) this.phone = store.state.employee.metaData.phone
        // this.getClientsAndManagers();
    },
};
</script>
