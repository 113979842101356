<template>
  <div class="h-full w-full flex flex-wrap overflow-hidden rounded-lg">
    <transition name="flip">
      <div class="p-10 flex w-full bg-white" v-if="loading">
    <loader  class="mx-auto" />
    </div>
    </transition>

    <transition name="flip">
      <div v-if="!loading && !submitted" class="bg-white h-full flex flex-col">
        <div class="w-full">
          <img src="https://res.cloudinary.com/eudawellbeing/image/upload/v1626328156/assets/events/eventPopup_pctjkv.png" alt="" />
        </div>
        <div class="p-4 bg-white">
          <div class="w-full text-lg font-bold text-tealPrimary">
            Reserve a virtual seat
          </div>
          <div class="w-full text-2xl font-bold leading-none text-dkBlue">
            Talking Balance
          </div>
          <div class="h-32 overflow-scroll pr-4">
            <div
              class="text-tealPrimary font-bold text-sm py-2 my-2 border-b border-t border-gray-200"
            >
              <div class="flex">
                <div class="w-1/2 pt-1">1st July 5:45pm - 1hr</div>
                <div class="w-1/2">
                  <a
                    href="https://hopin.to"
                    class="float-right inline-block text-tealPrimary underline cursor-pointer pt-1"
                    target="_blank"
                    ><img src="@/assets/hopin.png" alt="Hopin" width="150"
                  /></a>
                </div>
              </div>
            </div>
            <div class="w-full text-sm mt-2 text-gray-600">
              Join Kerri Linn whilst she talks the importance of balance. Known for her best selling book "Get on track", you'll gain valuable insights and tips on how to instantly re-balance your work and home life. This event is not to be missed out on.
            </div>
          </div>
          <div class="flex flex-wrap py-4 -m-2 mt-2 border-t border-gray-200">
            <div class="w-full sm:w-2/3 p-2">
              <div
                @click="reserveSeat"
                class="transition duration-500 ease-in-out hover:bg-purple-600 bg-tealPrimary rounded-full px-2 py-4 text-white cursor-pointer text-center"
              >
                <svg
                  class="w-6 h-6 inline-block mr-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                  ></path>
                </svg>
                Reserve Your Virtual Seat
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="flip">
      <div
        v-if="!loading && submitted"
        class="bg-white flex flex-wrap h-full w-full items-center text-center py-10"
      >
        <div class="min-h-20">
          <h1 class="text-dkBlue font-bold text-2xl leading-none">
            <span class="text-4xl">Hooray!</span><br />
            You've successfully reserved<br />
            your virtual seat.
          </h1>
          <p class="leading-none mt-3">
            We'll validate your pass and send you attendance instructions.
            Please note that this event is a live event hosted on the
            <a
              href="https://www.hopin.to"
              target="_blank"
              class="text-tealPrimary cursor-pointer"
              >hopin.to</a
            >
            platform.
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
import loader from "@/components/pages/TemplateLoaderDark";
export default {
  mixins: [globalMixin],
  data() {
    return {
      submitted: false,
      loading: false
    };
  },
  methods: {
    reserveSeat() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.submitted = true;
      }, 3000);
    }
  },
  components: {
    loader
  }
};
</script>
