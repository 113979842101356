import axios from "axios";
import { store } from "@/store/index.js";

export class ApiRepository {
    async fetch(args) {
      const authorisation = store.state.token.IdToken;
      let config = {
        headers: {Authorization: authorisation},
        params: args
      };

      switch(args.type){
        case 'course':
          return axios
          .get(`${process.env.VUE_APP_API_URL}/api`, config)

        case "chapter":

            return axios
            .get(`${process.env.VUE_APP_API_URL}/api`, config)

        case "lesson":
            return axios
            .get(`${process.env.VUE_APP_API_URL}/api`, config)
        
        case "completedLesson":
            return axios
            .get(`${process.env.VUE_APP_API_URL}/api`, config)
      }
    }


    async create(args) {
        const authorisation = store.state.token.IdToken;
        let config = {headers: {Authorization: authorisation}};
        
        console.log(args)
        console.log(args.type)

        switch(args.type){
            
        case 'course':
            return axios
            .post(
                `${process.env.VUE_APP_API_URL}/api`,
                args,
                config
            )
        case 'chapter':
            return axios
            .post(
                `${process.env.VUE_APP_API_URL}/api`,
                args,
                config
            )

        case 'lesson':
            return axios
            .post(
                    `${process.env.VUE_APP_API_URL}/api`,
                    args,
                    config
            )
        case 'completedLesson':
            return axios
            .post(
                    `${process.env.VUE_APP_API_URL}/api`,
                    args,
                    config
            )

        case 'lesson_video_upload':
            return axios
            .post(
                    `${process.env.VUE_APP_API_URL}/vimeos`,
                    args,
                    config
            )
        case 'attach_video':

            return axios
            .post(
                    `${process.env.VUE_APP_API_URL}/vimeos`,
                    args,
                    config
            )
        }
    }

    async delete(args) {
        const authorisation = store.state.token.IdToken;
        let config = {headers: {Authorization: authorisation},
            params: {pk: args.pk,sk: args.sk}
        };
        switch(args.type){
            
        case 'course':
            return axios
            .delete(
                `${process.env.VUE_APP_API_URL}/api`,
                config
            )
        case 'chapter':
            return axios
            .delete(
                `${process.env.VUE_APP_API_URL}/api`,
                config
            )
        
        case 'lesson':
            return axios
            .delete(
                `${process.env.VUE_APP_API_URL}/api`,
                config
            )
        
        case 'completedLesson':
            return axios
            .delete(
                `${process.env.VUE_APP_API_URL}/api`,
                config
            )
            

        }
        

        
    }


    async update(args) {
        const authorisation = store.state.token.IdToken;
        let config = {headers: {Authorization: authorisation}};

        switch(args.type){
            
        case 'course':
            return axios
            .put(
                `${process.env.VUE_APP_API_URL}/api`,
                args,
                config
            )
        case 'lesson':
            return axios
                .put(
                    `${process.env.VUE_APP_API_URL}/api`,
                    args,
                    config
            )

        case 'lesson_video_upload':
            console.log(args.upload_url)
            // console.log(args.params)

            return axios
            .patch(
                `${args.upload_url}`,
                args.params,
                {
                    'Tus-Resumable': '1.0.0',
                    'Upload-Offset': '0',
                    'Content-Type': 'application/offset+octet-stream',
                    'Accept': 'application/vnd.vimeo.*+json;version=3.4'
                }
            )
        }
    }
}