<template>
<div class="h-screen w-full bg-cover relative" :style="`background-image:url(${bgCoverPath}); background-repeat: no-repeat;`">
    <div class="absolute flex items-center justify-center h-screen right-0 w-1/2">
        <img :src="`https://euda-resources.s3.ap-southeast-2.amazonaws.com/mob_bg.png`" class="hidden lg:inline-block" style="height: 65vh;" />
    </div>

    <div class="w-full lg:w-8/12 h-screen flex items-center">
        <div class="absolute top-0 pl-10 lg:pl-16 py-10">
            <img :src="eudaLogo" class="h-10" />
        </div>
        <div class="w-full lg:w-8/12 text-4xl font-bold grid-cols-1 pl-10 lg:pl-32">
            <div>
                It's time to download the Euda wellbeing app on your phone
             
            </div>
            <div class="text-base">
                <span>
                    To get started, simply search 'Euda' in your app store or click the button below to download the app.
                </span>
            </div>
            <div class="w-full mt-10 h-24 flex gap-x-2">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.euda.euda_app" title="Download the Euda android App from Google Play" class="app-icon">
                    <Icon :target="{name: 'download-android'}" />
                </a>

                <a target="_blank" href="https://apps.apple.com/au/app/euda/id1598073737" title="Download the Euda IOS App from AppleStore" class="app-icon">
                    <Icon :target="{name: 'download-apple'}" />
                </a>
            </div>
        </div>
    </div>

    <footer class="bottom-0 absolute bottom-0 w-full grid grid-cols-1 text-light gap-y-5 my-5">
   
        <div class="w-full flex items-center justify-center">
            © Euda Pty Ltd 2023
        </div>
        
    </footer>

</div>
</template>

<script>
import Icon from "@/components/pages/TemplateIcon.vue"
import { store } from "../store/index";
export default {
    computed: {
        eudaLogo() {
            return require('@/assets/logoDark2.png')
        },
        bgPhonePath() {
            return require('@/assets/bg-phone.png')
        },
        bgCoverPath() {
            return require('@/assets/bg-landing2.png')
        },
        downloadAndroid() {
            return require('@/assets/android-download.svg')
        }
    },

    components: {
        Icon
    },

    mounted(){
      store.commit("showModal", null);
      store.commit("showModalMedium", null);
      store.commit("showModalLarge", null);
      store.commit("refreshToken", null);
      store.commit("token", null);
      store.commit("authorized", false);
      store.commit("userData", null);
      store.commit("companyData", null);
      store.commit("notificationData", null);
      store.commit("email", "");
      store.commit("pluginSettings", null)
    }
}
</script>

<style>

</style>
