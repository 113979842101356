<template>
<div>
    <div class="w-full z-50 absolute top-0 left-0">
        <transition name="listVertical">
            <div v-if="errors" class="w-full text-center bg-red-100 p-6 text-red-600 text-sm">
                {{ errors }}
            </div>
        </transition>
    </div>

    <div class="w-full flex h-screen">
        <div class="w-full sm:w-1/2 h-full flex items-center bg-white overflow-y-scroll relative">
            <transition name="translateXFull">
                <div v-if="loading" class="h-full w-full flex items-center bg-opacity-75 bg-white absolute top-0 left-0 z-50">
                    <loader-dark class=" mx-auto flex-shrink" />
                </div>
            </transition>
            <transition name="translateXFull">
                <div v-if="!loading" class="px-10 sm:px-20 flex flex-wrap w-full">
                    <div class="w-full mb-10">
                        <h1 class="text-gray-800 header text-3xl leading-none sm:text-4xl" v-html="headerLrg"></h1>
                        <h2 class="text-gray-600 header textxl sm:text-2xl leading-none mt-4 sm:mt-0" v-html="headerSml"></h2>
                    </div>

                    <div class="w-full" v-if="step == 1">
                        <!-- Forgot Password -->
                        <div class="w-full mb-10">
                            <h1 class="text-gray-800 header text-3xl leading-none sm:text-4xl">
                                Reset Password?
                            </h1>
                            <h2 class="text-gray-600 header textxl sm:text-2xl leading-none mt-4 sm:mt-0">
                                Please enter your email address. A confirmation code will be sent to you.
                            </h2>
                        </div>
                        <div class="w-full">
                            <div class="grid grid-cols-6 w-full">
                                <div class="col-span-6 lg:col-span-2 flex">
                                    <label class="pt-3 text-gray-500 text-sm">
                                        Email
                                    </label>
                                </div>
                                <div class="relative col-span-6 lg:col-span-4 bg-b">
                                    <input type="text" v-model="email" class="pr-16 mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                                </div>
                            </div>
                        </div>
                        <div class="w-full grid grid-cols-6 ">
                            <div @click="sendConfirmationCode()" class="col-span-6 sm:col-span-4 sm:col-start-3 rounded-md cursor-pointer bg-primaryBlue hover:bg-purple-600 text-white text-center p-4 header" style="background-color: #41C8C0;">
                                Send Confirmation Code
                            </div>
                        </div>
                    </div>

                    <div class="w-full" v-if="step == 2">
                        <!-- Confirmation Code  -->
                        <div class="w-full mb-10">
                            <h1 class="text-gray-800 header text-3xl leading-none sm:text-4xl">
                                Confirmation
                            </h1>
                            <h2 class="text-gray-600 header textxl sm:text-2xl leading-none mt-4 sm:mt-0">
                                Enter your confirmation code.
                            </h2>
                        </div>

                        <!-- Forgot Password -->
                        <div class="w-full">
                            <div class="grid grid-cols-6 w-full">
                                <div class="col-span-6 lg:col-span-2 flex">
                                    <label class="pt-3 text-gray-500 text-sm">
                                        Confirmation Code
                                    </label>
                                </div>
                                <div class="relative col-span-6 lg:col-span-4 bg-b">
                                    <input autocomplete="off" type="text" v-model="confirmationCode" class="pr-16 mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                                </div>
                            </div>
                            <div class="grid grid-cols-6 w-full">
                                <div class="col-span-6 lg:col-span-2 flex">
                                    <label class="pt-3 text-gray-500 text-sm">
                                        Password
                                    </label>
                                </div>
                                <div class="relative col-span-6 lg:col-span-4 bg-b">
                                    <div class="absolute z-50 right-0 mt-3 mr-3 bg-white cursor-pointer hover:text-primaryBlue flex h-6 w-6 text-xs text-gray-300" @click="showPassword()" v-html="passwordTypeIcon"></div>

                                    <input :type="passwordType" v-model="password" class="pr-16 mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                                </div>
                            </div>

                            <div class="grid grid-cols-6 w-full">
                                <div class="col-span-6 lg:col-span-2 flex">
                                    <label class="pt-3 text-gray-500 text-sm">
                                        Password Confirmation
                                    </label>
                                </div>
                                <div class="relative col-span-6 lg:col-span-4 bg-b">
                                    <input :type="passwordType" v-model="passwordConfirmation" class="pr-16 mb-2 appearance-none rounded w-full p-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none z-10 focus:z-50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                                </div>
                            </div>

                        </div>
                        <div class="w-full grid grid-cols-6 ">
                            <div @click="confirmForgotPassword()" class="col-span-6 sm:col-span-4 sm:col-start-3 rounded-md cursor-pointer bg-primaryBlue hover:bg-purple-600 text-white text-center p-4 header" style="background-color: #41C8C0;">
                                Change Password
                            </div>
                        </div>
                    </div>

                    <div class="w-full" v-if="step == 3">
                        <!-- Confirmation Code  -->
                        <div class="flex justify-center items-center text-gray-800 header text-3xl leading-none sm:text-4xl  text-center">
                            <img src="https://euda-resources.s3.ap-southeast-2.amazonaws.com/euda-navy_360x.png" class="h-8" />
                        </div>

                        <div class="w-full mb-10 grid grid-cols-1 items-center jusity-center">
                            <div class="w-full grid grid-cols-1 justify-center items-center">

                                <div class="mt-10 flex justify-center items-center text-gray-800 header text-3xl leading-none sm:text-4xl  text-center">
                                    <span>Password Changed.</span>
                                </div>

                                <div class="mt-5 text-gray-600 mt-1 header textxl sm:text-2xl w-full flex justify-center items-center text-center">
                                    <span>Your password has been changed successfully.</span>
                                </div>
                            </div>
                            <!-- <button @click="openApp()">
                                Open Euda
                            </button> -->
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="showImg" class="hidden sm:flex w-1/2 h-full bg-white bg-cover bg-gray-400">
            <img :src="`https://res.cloudinary.com/eudawellbeing/image/upload/c_scale,q_64,w_1349/v1628640716/assets/websiteImages/pexels-michael-burrows-7129690_zihqkv.jpg`" class="w-full h-full object-cover" />
        </div>
    </div>
</div>
</template>

<script>
import {
    store
} from "../store/index";
import globalMixin from "../mixins/globalMixin"; // used to hit the initiateRefreshToken method
import LoaderDark from "../components/pages/TemplateLoaderDark";

export default {
    mixins: [globalMixin],
    data() {
        return {
            step: 1,
            email: "",
            confirmationCode: "",
            password: "",
            passwordConfirmation: "",

            showImg: window.innerWidth < 641 ? false : true,
            loading: true,
            errors: "",
            automate: false, // If true the endpoint watcher will auto submit the api
            headerLrg: "",
            headerSml: "",
            image: "",
            btnLabel: "Sign In",
            passwordType: "password",
            passwordTypeIcon: '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>',
            paramsForSubmission: {},
            endpoint: "", // Used to hit the correct endpoint when using the submitApi func
            newPasswordSession: "", // Used to store password reset session
            // Schema must not change order as we reference indexes in watcher

        };
    },

    methods: {
        openApp() {
          const url = "euda://";
          window.location.replace(url);
        },
        sendConfirmationCode() {
            this.loading = true;

            this.axios
                .post(`${process.env.VUE_APP_API_URL}/auth/forgotPassword`, {
                    username: this.email.toLowerCase()
                })
                .then(async () => {
                    this.loading = false
                    this.step = 2

                }).catch((error) => {
                    this.loading = false;
                    this.errors = error.response.data.message;
                });
        },
        confirmForgotPassword() {
            this.loading = true;
            if (this.password == this.passwordConfirmation) {
                this.axios
                    .post(`${process.env.VUE_APP_API_URL}/auth/confirmForgotPassword`, {
                        username: this.email.toLowerCase(),
                        password: this.password,
                        confirmationCode: this.confirmationCode
                    })

                    .then(async () => {
                        this.loading = false
                        this.step = 3

                    }).catch((error) => {
                        this.loading = false;
                        this.errors = error.response.data.message;
                    });
            } else {
                this.errors = "Please ensure that the password matches the confirmation."
                this.loading = false;
            }
        },

        showPassword() {
            if (this.passwordType == 'password') {
                this.passwordType = 'text'
                this.passwordTypeIcon =
                    '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>';
            } else {
                this.passwordType = 'password'
                this.passwordTypeIcon =
                    '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>';
            }
            // if (this.schema[_index].type === "password") {
            //     this.schema[_index].type = "text";

            // } else {
            //     this.schema[_index].type = "password";

            // }
        },
        toggleField(_model) {
            // Use this function to toggle an array of fields in the schema
            this.schema.forEach((f) => {
                if (f.model == _model) f.enabled = true; // Enables field if it matches the incomming param
            });
        },
        getParams() {
            // Loops through all enabled fileds in the schema and creates params ready to send to the api
            let result = {};
            this.schema.forEach((f) => {
                if (f.enabled) result[f.model] = f.value;
            });

            return result;
        },
        validateUrl(url) {
            let x = url
            return "https://" + x.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
        },
        submitApi() {

            // ZReset Errors
            this.errors = ""
            // set preloader
            this.loading = true;
            // First get the params based of enabled fields
            let _params = this.getParams();
            // Cavets
            if (_params.website) {
                _params.website = this.validateUrl(_params.website)
            }
            console.log("website", _params)
            if (_params.fullName) _params.fullName = this.parseFullName(_params.fullName);
            if (this.newPasswordSession) _params.newPasswordSession = this.newPasswordSession;
            // Make the call - note all auth requests are POST
            this.axios
                .post(`${process.env.VUE_APP_API_URL}/auth/${this.endpoint}`, _params)
                .then(async (response) => {
                    // reset newPasswordSession
                    this.newPasswordSession = ""
                    this.newPassword = ""
                    // Work on responses - broken out into a function for each
                    console.log("Response to auth", response.data);
                    if (this.endpoint == "initiateAuth") await this.initiateAuthResponse(response);
                    else if (this.endpoint == "signUp") await this.signUpResponse(response);
                    else if (this.endpoint == "resendConfirmationCode") await this.resendConfirmationCodeResponse();
                    else if (this.endpoint == "confirmSignUp") await this.confirmSignUpResponse();
                    else if (this.endpoint == "forgotPassword") await this.forgotPasswordResponse(response);
                    else if (this.endpoint == "confirmForgotPassword") await this.confirmForgotPasswordResponse(response);
                    else if (this.endpoint == "RespondToAuthChallenge") await this.respondToAuthChallengeResponse(response);
                })
                .catch((error) => {
                    this.loading = false;
                    this.errors = error.response.data.message;
                });
        },
        parseFullName(_fullName) {
            const human = require("humanparser");
            const parsedFullName = human.parseName(_fullName);
            return parsedFullName;
        },
        async initiateAuthResponse(response) {
            if (response.data.ChallengeName == "NEW_PASSWORD_REQUIRED") {
                console.log("response to initiateAuth", response)
                this.password = ''
                this.newPasswordSession = response.data.Session;
                this.endpoint = "RespondToAuthChallenge";
                console.log("endpoint after initiateAuth", this.endpoint)
                this.loading = false
            } else {
                // Save the idToken and AccessToken together as a refresh key will never renew
                if (response.data.AuthenticationResult) {
                    store.commit("token", response.data.AuthenticationResult);
                    store.commit("refreshToken", response.data.AuthenticationResult.RefreshToken);
                }
                this.$router.push('/authorization')
            }
        },
        async respondToAuthChallengeResponse(response) {
            if (response.data.AuthenticationResult) {
                store.commit("token", response.data.AuthenticationResult);
                store.commit("refreshToken", response.data.AuthenticationResult.RefreshToken);
                this.schema[5].value = this.schema[6].value
            }

            this.endpoint = "initiateAuth";
            this.automate = true
        },
        // async signUpResponse(response) {
        //   if (response.data.pk) {
        //     this.endpoint = "confirmSignUp";
        //     this.loading = false
        //   }
        // },
        async resendConfirmationCodeResponse() {
            this.endpoint = "confirmSignUp";
            this.loading = false
        },
        async confirmSignUpResponse() {
            this.loading = false
            this.automate = true
            this.endpoint = "initiateAuth";
        },
        async forgotPasswordResponse(response) {
            if (response.data) {
                this.endpoint = "confirmForgotPassword";
                this.loading = false
            }
        },
        async confirmForgotPasswordResponse(response) {
            if (response.data) {
                this.automate = true
                this.loading = false
                this.endpoint = "initiateAuth";
            }
        },

        responsiveImage() {
            if (window.innerWidth > 640) {
                this.showImg = true
            } else {
                this.showImg = false
            }
        }
    },
    mounted() {
        if (this.$route.query.signUp) this.endpoint = "signUp";
        // SignUp
        else if (this.$route.query.confirmSignUp) this.endpoint = "confirmSignUp";
        // Confirmation SignUp Code
        else if (this.$route.query.confirmForgotPassword)
            this.endpoint = "confirmForgotPassword";
        // Confirmation confirmForgotPassword Code
        else if (this.$route.query.forgotPassword) this.endpoint = "forgotPassword";
        // Confirmation confirmForgotPassword Code

        else this.endpoint = "initiateAuth"; // Login
        this.loading = false
        // set any hardcoded params

        let _username = this.$route.query.username
        if (_username && _username.includes("")) _username = _username.split(/[ ]+/).join('+')
        if (this.$route.query.username) this.schema[4].value = _username
        if (this.$route.query.password) this.schema[5].value = this.$route.query.password
        if (this.$route.query.automate) this.automate = true
    },
    components: {
        LoaderDark,
    },
    created() {

        window.addEventListener("resize", this.responsiveImage);
    },
    destroyed() {
        window.removeEventListener("resize", this.responsiveImage);
    },
};
</script>
