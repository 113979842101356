<template>
<div class="w-full h-full rounded-lg overflow-y-scroll" :class="`${appearance.background} ${appearance.textColour}`">
    <main class="relative flex">
        <div class="w-full h-full ">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <div class="divide-y divide-gray-200 lg:col-span-12">
                    <!-- Profile section -->
                    <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
                        <div>
                            <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                                Create recording
                            </h2>
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                Create a recording and distribute it to your users.
                            </p>
                        </div>

                        <div v-if="loading" class="items-center h-full w-full flex mt-10">
                            <loader class="mx-auto" />
                        </div>

                        <div v-if="!loading" class="grid grid-cols-12 gap-6">
                            <div class="sm:col-start-1  col-span-12">
                                <label for="title" class="block text-sm font-medium leading-5">Title of your recording</label>
                                <input v-model="title" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5 text-black" />
                            </div>

                            <div class="sm:col-start-1  col-span-12">
                                <label for="label" class="block text-sm font-medium leading-5">Assign to a collection</label>
                                <select v-model="collection" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black">
                                    <option v-for="(collection, cIndex) in collections" :key="cIndex" :value="collection" class="text-dkBlue">{{ collection.metaData.title }}</option>
                                </select>
                            </div>

                            <div class="sm:col-start-1 col-span-12">
                                <label for="description" class="block text-sm font-medium leading-5">Description of the resource</label>
                                <textarea rows="4" v-model="description" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black" />
                                </div>
                <div class="sm:col-start-1 col-span-12 flex">
                  <div class="flex-shrink">
                    <img
                      :src="resizedImage"
                      alt="Uploaded Image"
                      class="w-20 h-20 rounded object-cover"
                    />
                  </div>
                  <div class="flex-grow px-4">
                    <div class="flex w-full items-center justify-start h-full">
                      <label
                        v-if="attachmentUploadStatus != 'uploading'"
                        class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white hover:bg-purple-600 hover:text-white"

                      >
                        <span class="text-xs leading-normal"
                          >Upload a thumbnail</span
                        >
                        <input
                          type="file"
                          multiple
                          label="Add your files"
                          @change="uploadFileToCloudinary($event)"
                          class="hidden"
                        />
                      </label>
                       <label
                        v-else
                        class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white"

                      >
                        <span class="text-xs leading-normal">
                          <Icon :target="{name: `preloader`, style: `margin:auto;background:#transparent;display:block;width:20px;height:20px`}" />
                        </span
                        >
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  v-if="isError"
                  class="bg-red-100 text-red-500 text-sm rounded p-2"
                >
                  {{ errorText }}
                </div>
                
                <div class="sm:col-start-1 col-span-12">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-5"
                    >Link to your recording</label
                  >
                  <div class="flex flex-wrap w-full">
                    <input
                      v-model="link"
                      id="link"
                      class="focus:shadow-outline mt-1 block w-full border border-gray-300 rounded-tr-md rounded-br-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                     
                    />
                  </div>
                  <span class="text-xs text-blue-500"
                    >Paste a link to your recording</span
                  >
                </div>

             
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div v-if="!loading" class="flex px-6 mb-6">
          <div
            @click="create()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Create Recording
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import loader from "@/components/pages/TemplateLoaderDark";
import {
    store
} from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import moment from "moment";
import Icon from "@/components/pages/TemplateIcon"
export default {
    mixins: [globalMixin],
    data() {
        return {
          
            imgstatus: '',
            uploadedImage: "https://res.cloudinary.com/eudawellbeing/image/upload/v1629899523/assets/websiteImages/iu_njcmbo.png",
            resizedImage: "https://res.cloudinary.com/eudawellbeing/image/upload/v1629899523/assets/websiteImages/iu_njcmbo.png",
            // sourceType: 'vimeo',
            // videoType: 'private',
            step: 1,
            guide: {
                text: "Paste the Youtube or Vimeo link",
                class: 'text-blue-500'
            },
            loading: false,
            title: "",
            description: "",
            visibleLink: "",
            link: "",
            collection: "",
            featuresString: [],
            features: [{
                title: "Downloadable"
            }],
            active: false,
            errored: "",
            lastEvaluatedKey: "na",
            thumbnail: "",
            isError: false,
            errorText: "",
            duration: ""
        };
    },

    methods: {
        // setFocus: function () {
        //     this.$refs.source.focus();
        // },
        // resetVideoType(){
        //     if(this.sourceType == 'youtube'){
        //         this.videoType = 'public'
        //     }
        // },
        uploadFileToCloudinary(evt) {
            this.imgstatus = 'uploading'

            return new Promise((resolve, reject) => {
                //Ideally these to lines would be in a .env file
                const CLOUDINARY_URL =
                    "https://api.cloudinary.com/v1_1/eudawellbeing/upload";
                const CLOUDINARY_UPLOAD_PRESET = "myyaups8";
                let formData = new FormData();
                formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
                formData.append("folder", "resourceImages");
                formData.append("file", evt.target.files[0]);
                let request = new XMLHttpRequest();
                request.open("POST", CLOUDINARY_URL, true);
                request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
                request.onreadystatechange = () => {
                    // File uploaded successfully
                    if (request.readyState === 4 && request.status === 200) {
                        let response = JSON.parse(request.responseText);
                        let _img = response.secure_url.split("upload/");
                        _img = _img[0] + "/upload/c_scale,w_500/" + _img[1];
                        this.uploadedImage = _img;
                        this.resizedImage = _img;
                        this.imgstatus = "uploaded"
                        resolve(response);
                    }
                    // Not successfull, let find our what happened
                    if (request.status !== 200) {
                        this.imgstatus = "errored"
                        let response = JSON.parse(request.responseText);
                        let error = response.error.message;
                        this.errorText = "error uploading files " + error;
                        this.isError = true;
                        reject(error);
                    }
                };
                request.onerror = (err) => {
                    alert("error: " + err);
                    reject(err);
                };
                request.send(formData);
            });
        },
        // async validateSource() {
        //     const res = await this.$RecordingService.validateSource(this.visibleLink, this.videoType)
        //     if (res.status == 200) {
        //         // this.manageStep(2)

        //         this.link = res.link
        //         // this.thumbnail = res.thumbnail
        //         this.visibleLink = res.visibleLink

        //         this.guide.text = res.msg
        //         this.guide.class = res.class
        //     } else {
        //         this.guide.text = res.msg
        //         this.guide.class = res.class
        //     }
        // },
        manageStep(index) {
            this.step = index
        },

        // validateLink() {
        //     this.link = 'https://youtu.be/' + this.link
        // },
        async create() {
            try{
              this.errored = ""
               if(this.link.length > 0){
                  this.loading = true
                  const res = await this.$RecordingService.validateSource(this.link)
                  this.link = res.link
                  
                  if(this.link && this.link.includes('vimeo')){
                    let item = this.link.split('/')
                    const video = await this.$RecordingService.getPirvateVimeoThumb(item.at(-1))
                    this.duration = this.secondsToHms(video.data.duration)
                    
                  }
                  await this.$RecordingService.create(this.item);
                  store.commit('showModal', null)
                  this.loading = false
                }else{
                  confirm("Recording link is a required field.")
                }

            }catch(e){

              this.loading = false;
              this.errored = "We're sorry, something went wrong. Please make sure that the link is entered correctly"

     
            }
           
        },

        secondsToHms(d) {
            d = Number(d);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);

            var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            return hDisplay + mDisplay + sDisplay; 
        }

    },
    computed: {
        attachmentUploadStatus: {
            get() {
                return this.imgstatus
            },
            set(status) {
                return status
            }
        },
        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        },
        collections() {

            return store.state.recording.collections
        },
        item() {
            return {
                title: this.title,
                collection: this.collection,
                description: this.description,
                link: `${this.link}`,
                author: `${store.state.userData.metaData.fullName.fullName}`,
                createdAt: moment().format("MMM DD, YYYY"),
                thumbnail: this.uploadedImage,
                duration: this.duration
            };
        }
    },
    components: {
        loader,
        Icon
    },

    created() {
        // setTimeout(() => {
        //     this.$nextTick(() => this.setFocus()); // this works great, just watch out with going to fast !!!
        // }, 10);
        // store.commit('showModalLarge', '.....')
    }
};
</script>
