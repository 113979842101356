export default class AppearanceService {
    init(mode){
    //inialise the appearance class and it gets mode as an argumenet and depending on the value, it returns relevant data.
        switch (mode) {
            case "light":
                return this.lightMode();
            case "dark":
                return this.darkMode();
            default:
                return this.lightMode();
        }
    }


    darkMode(){
        const styleSheet = {
            mode: 'dark',
            background: "bg-gray-900", 
            textColour: "text-gray-200",
            tileColour: "bg-dkGray",
            buttonColour: "bg-dkGray",
            borderColour: "border-gray-200",
            sideBarColour: "bg-sideBar",
            hoverBgColour: "hover:bg-dkGray",
            inputColour: "bg-sideBar",
            secondBorderColour: "border-gray-800",

            sideBarColourToText: "text-white",
            mainBackground: "bg-gray-900",
            mainBodyBackground: "bg-gray-800",
            mainBodyBackgroundHover: "hover:bg-gray-700",
        }


        return styleSheet
    }

    lightMode(){
        const styleSheet = {
            mode: 'light',
            hoverBgColour: "hover:bg-blue-100",
            background: "bg-white",
            textColour: "text-gray-700",
            tileColour: "bg-gray-100",
            buttonColour: "bg-gray-200",
            borderColour: "border-dkGray",
            sideBarColour: "bg-purple-900",

            inputColour: "bg-white",

            secondBorderColour: "border-gray-400",

            sideBarColourToText: "text-sideBar",
            mainBackground: "bg-gray-200",
            mainBodyBackground: "bg-white",
            mainBodyBackgroundHover: "hover:bg-gray-100",
            
        }

        return styleSheet
    }


    screenSize(){
        let width = window.innerWidth;

        console.log(width)
        if(width < 640){
            return 'xs'
        }else if(width < 1024){
            return 'sm'
        }else if(width < 1280){
            return 'md'
        }else if(width < 1538){
            return 'lg'
        }else if(1538 < width ){
            return 'xl'
        }
    }
}
