import { store } from "@/store/index.js"
import axios from 'axios'

export class InterventionRepo {

    async fetch(lastEvaluatedKey) {
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                key: {},
                LastEvaluatedKey: lastEvaluatedKey
            }
        };

        return axios.get(
            `${process.env.VUE_APP_API_URL}/interventions`,
            config
        )
    }

    async create(metaData) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            metaData: metaData
        };
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/interventions`,
                body,
                config
            )
    }
    
    async update(updatedIntervention) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            pk: updatedIntervention.pk,
            metaData: updatedIntervention.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/interventions`,
                body,
                config
            )
    }
}