import { store } from "@/store/index.js"
import { TenantRepo } from "@/repo/TenantRepo.js"
const tenantRepo = new TenantRepo();

export default class TenantService {
    async update(newCompanyData) {
        let result = {
            status: true
        }
        try {
            const res = await tenantRepo.update(newCompanyData)
            if (res.status == 200) {
                let oldCompanyData = store.state.companyData
                oldCompanyData.metaData = newCompanyData.metaData
                store.commit('companyData', oldCompanyData)
            }
            return result

        } catch (error) {
            result.status = false;
            result.msg = error;
            return result
        }
    }
}