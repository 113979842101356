<template>
<div class="flex flex-wrap items-center h-full w-full rounded-lg" :class="appearance.background">

    <div @click="closeModal()" class="w-full min-h-10 py-5">
    </div>


    <div v-if="loading" class="items-center h-full w-full flex my-10">
        <loader class="mx-auto" />
    </div>  

    <div v-if="!loading && error.length == 0" class="w-full text-center py-3 tracking-tight mb-2 font-bold text-lg" :class="appearance.textColour">
        Are you sure?
    </div>

    <div v-if="!loading && error.length == 0" class="w-full flex flex-wrap justify-center">
        <div class="w-1/2 mb-10">
            <div @click="destroy()" class="text-base rounded text-center rounded-l relative p-2 cursor-pointer bg-primaryBlue hover:bg-purple-600 text-white">
                Yes
            </div>
        </div>
    </div>

    <div v-if="!loading && error.length > 0" class="w-full text-center py-3 tracking-tight my-4 font-bold text-xl" :class="appearance.textColour">
        {{ error }}
    </div>

</div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import {
    store
} from "../../store/index";
import loader from "@/components/pages/TemplateLoaderDark";
export default {
    mixins: [globalMixin],
    data() {
        return {
            loading: false,
            error: ""
        };
    },
    computed: {
        demoData() {
            return store.state.demoData
        },
        appearance(){
            return store.state.light ?  this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        }
    },

    methods: {
        async destroy() {
            this.loading = true;
            const res = await this.$RecordingCollectionService.delete()
            console.log(res.status)
            if(res.status == false){
                 this.loading = false;
                 this.error = res.error
            }else{
                store.commit("showModal", null)
                this.loading = false;
            }
           
        },
    },
    components:{
        loader
    }
};
</script>
