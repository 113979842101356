<template>
  <div
    class="w-full h-full rounded-lg overflow-y-scroll"
    :class="`${appearance.background} ${appearance.textColour}`"
  >
    <main class="relative flex">
      <div class="w-full h-full ">
        <div
          class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
        >
          <div class="divide-y divide-gray-200 lg:col-span-12">
            <!-- Profile section -->
            <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
              <div>
                <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                  Create a resource
                </h2>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  Create a resource and distribute it to your users.
                </p>
              </div>

              <div class="grid grid-cols-12 gap-6">
                <div class="sm:col-start-1  col-span-12">
                  <label for="title" class="block text-sm font-medium leading-5"
                    >Title of your resource</label
                  >
                  <input
                    v-model="title"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5 text-black"
                  />
                </div>

            

                <div
                  v-if="isError"
                  class="bg-red-100 text-red-500 text-sm rounded p-2"
                >
                  {{ errorText }}
                </div>

                <div class="sm:col-start-1  col-span-12">
                  <label for="label" class="block text-sm font-medium leading-5"
                    >Assign to a category</label
                  >
                  <select
                    v-model="category"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                  >
                    <option
                      v-for="(c, cIndex) in categories"
                      :key="cIndex"
                      :value="c"
                      class="text-dkBlue"
                      >{{ c.metaData.title }}</option
                    >
                  </select>
                  <span
                    @click="openModal('createCategory')"
                    class="cursor-pointer hover:text-purple-600 text-xs text-blue-500"
                    >Or create a new one</span
                  >
                </div>

                <div class="sm:col-start-1 col-span-12">
                  <label
                    for="description"
                    class="block text-sm font-medium leading-5"
                    >Description of the resource</label
                  >
                  <textarea
                    rows="4"
                    v-model="description"
                    id="title"
                    class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                  />
                </div>

                <div class="sm:col-start-1 col-span-12">
                  <label for="email" class="block text-sm font-medium leading-5"
                    >Link to your resource
                  </label>
                  <div class="flex flex-wrap w-full">
                    <input
                      v-model="link"
                      id="link"
                      class="focus:shadow-outline mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black"
                    />
                  </div>
                  <span class="text-xs text-blue-500"
                    >Paste a link to your resource</span
                  >
                </div>

                    <div class="sm:col-start-1 col-span-12 flex">
                    <div class="flex-shrink">
                      <img
                      :src="resizedImage"
                      alt="Uploaded Image"
                      class="w-20 h-20 rounded object-cover"
                    />
                    </div>
                  <div class="flex-grow px-4">
                    <div class="flex w-full items-center justify-start h-full">
                      <label
                        v-if="attachmentUploadStatus != 'uploading'"
                        class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white hover:bg-purple-600 hover:text-white"
                      >
                        <span class="text-xs leading-normal">Upload a thumbnail</span>
                        <input
                          type="file"
                          multiple
                          label="Add your files"
                          @change="uploadFileToCloudinary($event)"
                          class="hidden"
                        />
                      </label>
                      <label
                        v-else
                        class="text-sm  flex flex-col items-center px-4 py-2 rounded-full shadow-lg tracking-wide uppercase border border-blue cursor-pointer bg-primaryBlue text-white">
                        <span class="text-xs leading-normal">
                          <Icon :target="{name: `preloader`, style: `margin:auto;background:#transparent;display:block;width:20px;height:20px`}" />
                        </span>
                      </label>

                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div class="flex px-6 mb-6">
          <div
            @click="createResource()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Create Resource
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import moment from "moment";
import Icon from "@/components/pages/TemplateIcon";
export default {
  mixins: [globalMixin],
  data() {
    return {
      imgstatus: '',
      uploadedImage: "https://res.cloudinary.com/eudawellbeing/image/upload/v1629899523/assets/websiteImages/iu_njcmbo.png",
      resizedImage:"https://res.cloudinary.com/eudawellbeing/image/upload/v1629899523/assets/websiteImages/iu_njcmbo.png",
      files: [],
      isError: false,
      errorText: "",
      title: "",
      description: "",
      link: "",
      category: "",
      active: false,
      errored: "",
      lastEvaluatedKey: "na",
    };
  },
  methods: {
    async loadCategories() {
      this.loading = true;
      setTimeout(() => {
        let config = {
          headers: {
            Authorization: this.token.IdToken,
          },
          params: {
            key: {},
            type: "categories",
            LastEvaluatedKey: this.lastEvaluatedKey,
          },
        };

        this.axios
          .get(`${process.env.VUE_APP_API_URL}/resources`, config)
          .then((response) => {
            if (response.data.LastEvaluatedKey) {
              this.lastEvaluatedKey = response.data.LastEvaluatedKey;
              this.loading = false;
            } else {
              this.lastEvaluatedKey = "na";
              this.loading = true;
            }
            response.data.Items.forEach((x) => {
              store.commit("addCategories", x);
            });
          })
          .catch(async (error) => {
            this.errored = error;
          })
          .finally(() => (this.loading = false));
      }, 1000);
    },
    validateUrl() {
      let x = this.link;
      this.link = "https://" + x.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
    },
    createResource() {
      // Validation
      this.validateUrl();
      if (
        this.title == "" ||
        this.description == "" ||
        this.link == "" ||
        this.category == ""
      ) {
        this.errored = "All fields are required";

        return;
      }

      let config = {
        headers: {
          Authorization: this.token.IdToken,
        },
      };

      let body = {
        type: "internal",
        metaData: this.item,
      };

      this.axios
        .post(`${process.env.VUE_APP_API_URL}/resources`, body, config)
        .then((res) => {
          if (res.data) {
            if(store.state.selectedCategory == null){
              res.data.display = true;
            }else if(res.data.metaData.categoryData.pk == store.state.selectCategory.pk){
              res.data.display = true;
            }else{
              res.data.display = false;
            }
            
            // store.state.internalResources.forEach((el) => (el.display = true));
            this.closeModal();
            store.commit("addInternalResources", res.data);
          }
        })
        .catch(async (error) => {
          this.errored = error;
        })
        .finally(() => (this.loading = false));
    },
    uploadFileToCloudinary(evt) {
      this.imgstatus = 'uploading'

      return new Promise((resolve, reject) => {
        //Ideally these to lines would be in a .env file
        const CLOUDINARY_URL =
          "https://api.cloudinary.com/v1_1/eudawellbeing/upload";
        const CLOUDINARY_UPLOAD_PRESET = "myyaups8";
        let formData = new FormData();
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        formData.append("folder", "resourceImages");
        formData.append("file", evt.target.files[0]);
        let request = new XMLHttpRequest();
        request.open("POST", CLOUDINARY_URL, true);
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          // File uploaded successfully
          if (request.readyState === 4 && request.status === 200) {
            this.imgstatus = "uploaded"
            let response = JSON.parse(request.responseText);
            let _img = response.secure_url.split('upload/');
            _img = _img[0] + "/upload/c_scale,w_500/" + _img[1]
            this.uploadedImage = _img
            this.resizedImage = _img
            resolve(response);
          }
          // Not successfull, let find our what happened
          if (request.status !== 200) {
            this.imgstatus = "errored"
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },
  },
  computed: {
    attachmentUploadStatus: {
           get(){
             return this.imgstatus
           },
           set(status){
             return status
           } 
    },

    appearance() {
      return store.state.light
        ? this.$AppearanceService.init("light")
        : this.$AppearanceService.init("dark");
    },
    categories() {
      return store.state.categories;
    },
    item() {
      return {
        title: this.title,
        image: this.uploadedImage,
        categoryData: this.category,
        description: this.description,
        link: `${this.link}`,
        author: `${this.userData.metaData.fullName}`,
        createdAt: moment().format("MMM DD, YYYY"),
        active: false,
      };
    },
  },
  mounted() {
    store.commit("categories", []);
    this.loadCategories();
  },

  components:{
    Icon
  }
};
</script>
