import { store } from "@/store/index.js"
import axios from 'axios'

export class InternalResourceCategoryRepo {
    async fetch(lastEvaluatedKey) {
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                key: {},
                type: "categories",
                LastEvaluatedKey: lastEvaluatedKey
            }
        };
        
        return axios.get(
            `${process.env.VUE_APP_API_URL}/resources`,
            config
        )
    }

    async update(edittedItem) {
        let authorisation = store.state.token.IdToken;
        let pk = edittedItem.pk;

        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            pk: pk,
            metaData: edittedItem.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/resources`,
                body,
                config
            )
    }

    async delete(category){
        let authorisation = store.state.token.IdToken;

        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                pk: category.pk,
                sk: category.sk
            }
        }
        
        return axios.delete(
                `${process.env.VUE_APP_API_URL}/resources`,
                config
            )
    }
    
}