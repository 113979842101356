<template>
  <div class="w-full rounded-xl relative">
    <div class="mobileRotate sm:mt-0">
      <div v-if="snaps.length > 0" class="flex bg-gray-900 rounded-xl w-full flex-wrap p-4 sm:p-0">
        <permah-boxes
          v-if="level == 'employee'"
          :employee="userData"
          class="rounded-t-md"
        />

        <div id="chart" class="w-full">
          <vue-apex-chart
            toggleSeries="Positive Emotion"
            type="line"
            ref="myChart"
            height="350"
            :options="chartOptions"
            :series="initialSeries"
          ></vue-apex-chart>
        </div>
      </div>
      <div v-else class="flex w-full h-full relative bg-white">
        <div class="absolute top-0 left 0 w-full flex">
          <span class="mx-auto mt-10 bg-gray-100 p-4 rounded text-gray-400 text-xl">
            No Data Found
          </span>
        </div> 
        <img src="https://res.cloudinary.com/eudawellbeing/image/upload/v1628202376/assets/81084d04dbcadec0b75a7d494b253d7d_hi7mll.gif" class="w-full h-full object-cover opacity-25">
      </div>
    </div>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin";
import PermahBoxes from "@/components/pages/TeamsPermahBoxes";
import VueApexChart from "vue-apexcharts";
import moment from 'moment-timezone'
import { store } from "@/store/index";

export default {
  mixins: [globalMixin],
  components: {
    VueApexChart,
    PermahBoxes,
  },
  data() {
    return {
      loadingData: false,
      initialSeries: [],
      chartOptions: {
        grid: {
          show: true,
          borderColor: "#9ca4dd",
          opacity: 0.5,
          strokeDashArray: 3,
          position: "back",
        },
        chart: {
           toolbar: {
        show: false},
          height: 350,
          type: "line",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: [0.35, 1],
        },
        stroke: {
          width: [3, 3, 3, 3, 3, 3, 3],
          curve: "smooth",
        },
        title: {
          text: "",
          align: "left",
          offsetX: 15,
          style: {
            color: "#ffffff",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: undefined,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#ffffff",
            },
          },
        },
        yaxis: [
          {
            min: 0,
            max: 60,
            axisTicks: {
              show: false,
            },
            labels: {
              style: {
                colors: "#ffffff",
              },
            },
            title: {
              text: "PERMA Score",
              style: {
                color: "#ffffff",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          labels: {
            colors: "#fff",
          },
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  },
  watch: {
    snaps() {
      this.filterData();
    },
  },
  computed: {
    employee() {
      return store.state.employee;
    },
    level() {
      return store.state.level;
    },
    snaps() {
      return store.state.snaps;
    },
  },
  methods: {
    filterData() {
      this.loadingData = true;
      let _xaxis = [];
      let _series = [
        {
          name: "Average Permah Score",
          color: "#FFFFFF",
          data: [],
        },
        {
          name: "Positive Emotion",
          color: "#FFFFFF",
          data: [],
        },
        {
          name: "Engagement",
          color: "#AFCEB8",
          data: [],
        },
        {
          name: "Relationships",
          color: "#FCBD18",
          data: [],
        },
        {
          name: "Meaning",
          color: "#7ECDEE",
          data: [],
        },
        {
          name: "Accomplishment",
          color: "#3ED6D9",
          data: [],
        },
        {
          name: "Health",
          color: "#FD9F8E",
          data: [],
        },
      ];

      let _updatedSeries;
      // Where is the data coming from
      const _snaps = this.snaps;
      // Loop through each item
      _snaps.forEach((snap) => {
        // format("MMM, YYYY")
        // See if the current snap fits within the dates supplied
        console.log("Snap here", snap)
        const obj = this.defineDates(snap, 31, 1);
        console.log("Snap date", obj)
        // The current data found is between the start and end dates - ie within the month
        if (obj.isBetween) {
          // Push each of the permah scores into the array
          _xaxis.push(obj.date.format("DD MMM")); // this.chartOptions

          if (this.level == "admin" || this.level == "team") {
            
            _updatedSeries = this.mapData(
              [
                (snap.permahScore / snap.loginCount).toFixed(),
                (snap.pScore / snap.loginCount).toFixed(),
                (snap.eScore / snap.loginCount).toFixed(),
                (snap.rScore / snap.loginCount).toFixed(),
                (snap.mScore / snap.loginCount).toFixed(),
                (snap.aScore / snap.loginCount).toFixed(),
                (snap.hScore / snap.loginCount).toFixed(),
              ],
              _series
            );
          } else {
            console.log("Made it here")
            _updatedSeries = this.mapData(
              [
                obj.arr[1],
                obj.arr[2],
                obj.arr[3],
                obj.arr[4],
                obj.arr[5],
                obj.arr[6],
                obj.arr[7],
              ],
              _series
            );
          }
        }
      });

      // Update the labels grey out all labels except for permahScore by default
      this.$refs.myChart.updateSeries(_updatedSeries);
      this.$refs.myChart.hideSeries("Positive Emotion");
      this.$refs.myChart.hideSeries("Relationships");
      this.$refs.myChart.hideSeries("Engagement");
      this.$refs.myChart.hideSeries("Meaning");
      this.$refs.myChart.hideSeries("Accomplishment");
      this.$refs.myChart.hideSeries("Health");

      // update the xaxis with the dates
      this.chartOptions = {
        xaxis: {
          categories: _xaxis,
        },
      };

      this.loadingData = false;
    },

    defineDates(snap, fromDate, toDate) {
      let _arr;
      let _date;

      if (this.level == "admin") {
        _arr = snap.pk.split("#");
        _date = moment(_arr[1]).tz('Australia/Sydney');
      } else if (this.level == "team") {
        _arr = snap.pk.split("#");
        _date = moment(_arr[2]).tz('Australia/Sydney');
      } else {
        _arr = snap.split("#");
        _date = moment(_arr[0]).tz('Australia/Sydney');
      }

      const _fromDate = moment().tz('Australia/Sydney').subtract(fromDate, "days");
      const _toDate = moment().tz('Australia/Sydney').add(toDate, "days");
      const _isBetween = _date.isBetween(_fromDate, _toDate);
      return { isBetween: _isBetween, arr: _arr, date: _date };
    },

    mapData(x, _series) {
      _series[0]["data"].push(x[0]);
      _series[0]["type"] = "area";
      _series[1]["data"].push(x[1]);
      _series[1]["type"] = "line";
      _series[2]["data"].push(x[2]);
      _series[2]["type"] = "line";
      _series[3]["data"].push(x[3]);
      _series[3]["type"] = "line";
      _series[4]["data"].push(x[4]);
      _series[4]["type"] = "line";
      _series[5]["data"].push(x[5]);
      _series[5]["type"] = "line";
      _series[6]["data"].push(x[6]);
      _series[6]["type"] = "line";

      return _series;
    },
  },
  mounted() {
    this.getUserData();

      store.commit("snaps", this.userData.snaps)
      store.commit("level", "employee")
  
    this.filterData();
  },
};
</script>
