import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import {PulseCheckModule} from "./modules/PulseCheckModule";
import {RecordingModule} from "./modules/RecordingModule"
import {UtilModule} from "./modules/UtilModule"
import {EventModule} from "./modules/EventModule"


Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    supportLinkTarget: 'AU',
    // Demo Data
    demoData: false,
    // Theme
    light: true,
    // Authorized data
    temporaryLoginDetails: { email: "", password: "" },
    authorized: false,
    userData: null,
    companyData: null,
    notificationData: null,
    refreshToken: null,
    token: null,
    // Preloader
    loading: false,
    // Pulse Survey
    posts: [],
    showReport: false,
    showResource: false,
    // Tour
    tour: -1,
    // General
    email: "",
    // Employees
    employeeCount: 0,
    teams: null,
    employee: null,
    employees: [],
    selectedManager: null,
    corporateOrg: [],
    level: 'admin',
    // Meditations
    audio: null,
    // Pulse Surveys
    pulse: null,
    // Graphs
    snaps: null,
    averageScores: {
      EUDA: 0,
      PHSYC: 0
    },
    // Routing - Navigation
    selectedPage: "",
    // Modals
    showModal: null,
    showModalMedium: null,
    showModalLarge: null,
    showModalFull: null,
    // Invitations
    invitation: null,
    invitations: [],
    // Resources
    internalResources: [],
    categories: [],
    // Systems
    systems: [],
    actionItems: [],
    system: null,
    action: null,
    conditions: [],
    // Employee SPA
    appTemplate: "dashboard",
    appTitle: "Hi",
    pushNotifications: [],
    //CONSOLE ACTIVE MENU
    consoleActiveItem: 0,
    selectedTeam: null,
    selectedPost:null,
    selectedResource: null,
    selectCategory: null,
    selectedPermah: {"keyword": "P", "title": "Positive Emotions"},
    selectedIntervention: null,
    interventions: [],
    plugins: null,
    toggleBusinessSidebar: false
  },
  mutations: {
    setSupportLinkTarget(state, payload) {
      state.supportLinkTarget = payload;
    },
    toggleBusinessSidebar(state, payload){
      state.toggleBusinessSidebar = payload
    },
    employeeCount(state, payload){
      state.employeeCount = payload
    },
    pluginSettings(state, payload){
      state.plugins = payload
    },
    plugin(state, payload) {
      state.plugins[payload].installed = !state.plugins[payload].installed // toggle installed
    },
    level(state, payload){
      state.level = payload
    },
    corporateOrg(state, payload) {
      // expected payload example = {action: 'addItem', index: 0, items: []}
      if (payload.action === 'addItem') {
        state.corporateOrg.items[payload.index].unshift(payload)
      } else if (payload.action === 'removeItem') {
        state.corporateOrg.splice(state.corporateOrg[0], 1);
      } else {
        state.corporateOrg = payload
      }
      if(!payload.action) state.corporateOrg = payload
    },
    snaps(state, payload) {
      state.snaps = payload
    },
    demoData(state, payload) {
      state.demoData = payload
    },
    light(state, payload) {
      state.light = payload
    },
    interventions(state, payload){
      state.interventions = payload
    },
    
    addInterventions(state, payload) {
      state.interventions.unshift(payload);
    },

    addNotifyPopup(state, payload) {
      state.pushNotifications.unshift(payload);
    },
    removeNotifyPopup(state) {
      state.pushNotifications.splice(state.pushNotifications[0], 1);
    },

    notifyPopup(state, payload) {
      state.notifyPopup = payload;
    },
    tour(state, payload) {
      state.tour = payload;
    },
    temporaryLoginDetails(state, payload) {
      state.temporaryLoginDetails = payload;
    },
    appTitle(state, payload) {
      state.appTitle = payload;
    },
    appTemplate(state, payload) {
      state.appTemplate = payload;
    },
    invitations(state, payload) {
      if (payload != null) {
        state.invitations.unshift(payload);
      } else {
        state.invitations = [];
      }
    },
    action(state, payload) {
      state.action = payload;
    },
    system(state, payload) {
      state.system = payload;
    },
    internalResources(state, payload) {
      state.internalResources = payload;
    },
    addInternalResources(state, payload) {
      state.internalResources.unshift(payload);
    },
    removeInternalResources(state, payload) {
      state.internalResources.splice(payload, 1);
    },
    removeInternalResourceCategories(state, payload) {
      state.categories.splice(payload, 1);
    },
    categories(state, payload) {
      state.categories = payload;
    },
    addCategories(state, payload) {
      state.categories.unshift(payload);
    },
    invitation(state, payload) {
      state.invitation = payload;
    },
    showModal(state, payload) {
      state.showModal = payload;
    },
    showModalMedium(state, payload){
      state.showModalMedium = payload;
    },
    showModalLarge(state, payload) {
      state.showModalLarge = payload;
    },
    showModalFull(state, payload) {
      state.showModalFull = payload;
    },
    selectedPage(state, payload) {
      state.selectedPage = payload;
    },
    averageScores(state, payload) {
      state.averageScores = payload;
    },
    pulse(state, payload) {
      state.pulse = payload;
    },
    posts(state, payload) {
      state.posts = payload
    },
    addPosts(state, post) {
      state.posts.push(post)
    },
    removePosts(state, post) {
      state.posts.splice(post, 1);
    },
    systems(state, payload) {
      state.systems = payload
    },
    addSystems(state, post) {
      state.systems.push(post)
    },
    removeSystems(state, post) {
      state.systems.splice(post, 1);
    },
    actionItems(state, payload) {
      state.actionItems = payload
    },
    addAction(state, post) {
      state.actionItems.push(post)
    },
    removeAction(state, post) {
      state.actionItems.splice(post, 1);
    },
    conditions(state, payload) {
      state.conditions = payload;
    },
    addCondition(state, post) {
      state.conditions.push(post)
    },
    removeCondition(state, post) {
      state.conditions.splice(post, 1);
    },
    audio(state, payload) {
      state.audio = payload;
    },
    authorized(state, payload) {
      state.authorized = payload;
    },
    email(state, payload) {
      state.email = payload;
    },
    userData(state, payload) {
      state.userData = payload;
    },
    companyData(state, payload) {
      state.companyData = payload;
    },
    notificationData(state, payload) {
      state.notificationData = payload;
    },
    loading(state, payload) {
      state.loading = payload;
    },
    refreshToken(state, payload) {
      state.refreshToken = payload;
    },
    token(state, payload) {
      state.token = payload;
    },
    selectedManager(store, payload) {
      store.selectedManager = payload;
    },
    teams(store, payload) {
      store.teams = payload;
    },
    addTeams(store, payload){
      store.teams.Items.unshift(payload)
    },
    selectedTeam(store, payload) {
      store.selectedTeam = payload;
    },
    employee(store, payload) {
      store.employee = payload;
    },
    employees(state, payload) {
      if (payload != null) {
        state.employees.unshift(payload);
      } else {
        state.employees = [];
      }
    },
    setEmployeesItems(state, payload){
      state.employees.Items[0] = payload 
    },
    showReport(store, payload) {
      store.showReport = payload;
    },

    selectedPost(state, payload){
      state.selectedPost = payload
    },
    selectCategory(state, payload){
      state.selectCategory = payload
    },
    deletePost(store){
      let index = store.posts.findIndex(item => item.pk == store.selectedPost.pk)
      store.posts.splice(index, 1);
    },

    selectedResource(state, payload){
      state.selectedResource = payload
    },
    showResource(store, payload) {
      store.showResource = payload;
    },

    consoleActiveItem(store, payload){
      store.consoleActiveItem = payload;
    },
    selectedPermah(store, payload){
      store.selectedPermah = payload;
    },
    selectedIntervention(store, payload){
      store.selectedIntervention = payload
    }

  },
  actions: {},
  modules: {
    recording: RecordingModule,
    util: UtilModule,
    pulseCheck: PulseCheckModule,
    event: EventModule
  }
});
