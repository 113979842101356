<template>
  <div class="w-full h-full rounded-lg overflow-y-scroll" :class="appearance.background">
    <main class="relative flex">
      <div class="w-full h-full">
        <div
          class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
        >
          <div class="divide-y divide-gray-200 lg:col-span-12 h-64 p-5 overflow-scroll" :class="appearance.textColour">
                <div class="items-center h-full w-full flex">
                    <loader class="mx-auto" />
                </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { store } from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import loader from "@/components/pages/TemplateLoaderDark";

export default {
  mixins: [globalMixin],
  computed:{
    appearance(){
      return store.state.light ?  this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
    },

    selectedResource(){
      return store.state.selectedResource
    }
  },

  components:{
      loader
  }
}
</script>