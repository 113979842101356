<template>
<div class="w-full h-full rounded-lg overflow-y-scroll" :class="`${appearance.background} ${appearance.textColour}`">
    <main class="relative flex">
        <div class="w-full h-full ">
            <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <div class="divide-y divide-gray-200 lg:col-span-12">
                    <!-- Profile section -->
                    <div class="py-6 px-4 space-y-6 sm:p-6 lg:pb-8">
                        <div>
                            <h2 class="text-lg font-bold leading-6  text-primaryBlue">
                                Create a category
                            </h2>

                        </div>

                        <div v-if="loading" class="items-center h-full w-full flex mt-10">
                            <loader class="mx-auto" />
                        </div>

                        <div v-if="!loading" class="grid grid-cols-12 gap-6">
                            <div class="sm:col-start-1  col-span-12">
                                <label for="title" class="block text-sm font-medium leading-5">Title of your category</label>
                                <input @focus="hideDropdown()" v-model="title" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:leading-5 text-black" />
                            </div>

                            <!-- Select Videos -->
                            <!-- <div class="relative w-full flex flex-wrap col-span-12">
                                <div class="w-full flex flex-wrap">
                                    <div class="w-10/12 border px-2 py-2 rounded-l-lg shadow" @click="toggleDropDwon()">
                                        <div v-if="recordings.filter(item => item.showForCollectionOption == false).length > 0">
                                            {{ recordings.filter(item => item.showForCollectionOption == false).length}} option(s) are selected
                                        </div>
                                        <div v-else>
                                            Select option
                                        </div>
                                    </div>
                                    <div class="w-2/12 flex justify-center items-center border text-center py-2 rounded-r-lg shadow cursor-pointer" @click="toggleDropDwon()">

                                        <Icon v-if="!recordingOptions" :target="{name: 'chevron-down', width: 'w-5', height: 'h-5'}"></Icon>
                                        <Icon v-else :target="{name: 'chevron-up', width: 'w-5', height: 'h-5'}"></Icon>

                                    </div>
                                </div>
                                <div v-if="recordingOptions" class="absolute w-full flex flex-wrap border rounded-bl-lg rounded-br-lg shadow" 
                                                             style="top:38px">
                                    <div class="w-full h-48 overflow-y-scroll">
                                        <div v-for="(recording, index) in recordings" v-bind:key="index" class="w-full flex flex-wrap bg-white">
                                            <div :class="`${appearance.background} ${appearance.hoverBgColour}`" class="text-xs w-full cursor-pointer py-2 px-1" @click="manageOption(recording)">
                                                <span class="flex">
                                                    <Icon v-if="recording.showForCollectionOption == false" :target="{name: 'check', width: 'w-5', height: 'h-5'}" class="text-green-600" style="font-size:small"></Icon>
                                                    {{ recording.metaData.title }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div> -->

                            <div class="sm:col-start-1 col-span-12">
                                <label for="description" class="block text-sm font-medium leading-5">Description of the category</label>
                                <textarea rows="4" v-model="description" id="title" class="focus:shadow-outline form-input mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out  sm:leading-5 text-black" />
                                </div>
            
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="errored"
          class="mx-6 text-center mb-4 bg-red-100 rounded p-2 text-red-500 text-sm"
        >
          {{ errored }}
        </div>

        <div v-if="!loading" class="flex px-6 mb-6">
          <div
            @click="create()"
            class="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primaryBlue hover:bg-purple-600 cursor-pointer"
          >
            Create a collection
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import Icon from "@/components/pages/TemplateIcon";
import loader from "@/components/pages/TemplateLoaderDark";
import {
    store
} from "../../store/index";
import globalMixin from "../../mixins/globalMixin";
import moment from "moment";

export default {
    mixins: [globalMixin],
    data() {
        return {
            loading: false,
            title: "",
            description: "",
            link: "",
            category: "",
   
    
            active: false,
            errored: "",
            lastEvaluatedKey: "na",
            recordingOptions: false
        };
    },
    methods: {
        // async hideDropdown(){
        //     this.recordingOptions = false
        // },
        // async manageOption(recording) {
        //     this.recordings.filter(item => (item.pk == recording.pk ? item.showForCollectionOption = !item.showForCollectionOption : null))
        //     let getCurrnet = this.recordings
        //     store.commit('recording/recordings', [])
        //     store.commit('recording/recordings', getCurrnet)
        // },

        // toggleDropDwon() {
        //     this.recordingOptions = !this.recordingOptions
        // },

        // makeOptionsVisible(){
        //     // let storeRecordings = store.state.recording.recordings
        //     // let recordings = storeRecordings
        //     store.state.recording.recordings.forEach(element => {
        //         element.showForCollectionOption = true
        //     });
        //     // this.recordings = recordings
        // },

        async create() {
            this.loading = true
            await this.$RecordingCollectionService.create(this.item);
            store.commit('showModal', null)
            this.loading = false
        }

    },
    computed: {
        // selectedRecordingPks(){
        //     let mapped = []
        //     let selected = store.state.recording.recordings.filter(item => item.showForCollectionOption == false)
        //     selected.forEach(element => {
        //         mapped.push({
        //             pk: element.pk
        //         })
        //     });
        //     return selected
        // },
        // recordings(){
        //     return  store.state.recording.recordings
        // },
        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        },
        categories() {

            return store.state.recording.categories
        },
        item() {
            return {
                title: this.title,
                description: this.description,
                author: `${store.state.userData.metaData.fullName.fullName}`,
                createdAt: moment().format("MMM DD, YYYY")
            };
        }
    },
    components: {
        loader,
        // Icon
    },

    // created(){
    //     this.makeOptionsVisible();
    // }
};
</script>
