import { HubSpotRepo } from "@/repo/HubSpotRepo.js";
const hubSpotRepo = new HubSpotRepo();

export default class HubSpotService {

    async create(element) {
        let result = {
            status: 200
        }
        try {
            const res = await hubSpotRepo.create(element)
            return res

        } catch (error) {
            result.status = 500;
            result.msg = error;
            console.log(error)
            return result
        }
    }
}