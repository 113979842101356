<template>
    <div class="flex w-full rounded bg-white flex-wrap -mt-16">
        <iframe src="https://player.vimeo.com/video/586601302?title=0&byline=0&portrait=0" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        <div class="text-center p-4 w-full">
            <h2 class="text-2xl text-gray-900 header font-bold">{{title}}</h2>
            <p class="text-lg text-gray-900">{{description}}</p>
            <div class="w-full my-4 hidden sm:block">
                <span @click="submitAction()" class="cursor-pointer rounded hover:bg-purple-600 bg-primaryBlue text-white header font-bold px-4 py-2">
                    {{btn}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
export default {
    mixins: [globalMixin],
    data(){
        return{
            title: "",
            description: "",
            btn: "",
            gotoUrl: "/settings"
        }
    },
    methods:{
        submitAction(){
            this.closeModal();
            if(this.$route.query.firstAdmin){
                this.$router.push(this.gotoUrl)
            }
        }
    },
    mounted(){
        if(this.$route.query.firstAdmin){
            this.title = "Welcome to Euda",
            this.description = "This is a new description"
            this.btn = "Start using Euda"
        } else {
            this.title = "Welcome to your wellbeing toolkit",
            this.description = "This is a new description"
            this.btn = "Explore your wellbeing tools"
        }
    }
}
</script>