//import { store } from "@/store/index.js"
import { EventRepo } from "@/repo/EventRepo.js";
import { store } from "../store";
const eventRepo = new EventRepo();

export default class EventService {
    async create(event) {
        let result = {
            status: 200
        }
        try {
            const res = await eventRepo.create(event)
            store.commit('event/addEvent', res.data)
            console.log(res)
            return res

        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }

    async fetch(){
        let result = {
            status: 200
        }
        try {

            let loadData = true
            let lastEvaluatedKey = 'na'
            store.commit('event/events', [])
            while (loadData == true) {
                const res = await eventRepo.fetch(lastEvaluatedKey)
                let events = store.state.event.events

                res.data.Items.forEach(el => {
                    let exist = events.filter(data => data.pk == el.pk)
                    if(exist.length == 0){
                        el.display = true
                        store.commit('event/addEvent', el)
                    }
                });

                if(res.data.LastEvaluatedKey == 'na' || res.data.LastEvaluatedKey == undefined){
                    loadData = false
                }else{
                    lastEvaluatedKey = res.data.LastEvaluatedKey
                }
            }
            
            store.state.event.events.sort(function(previous,next){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(next.metaData.when) - new Date(previous.metaData.when);
            });

        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }

    async update(element){
        let result = {
            status: 200
        }
        try {
            const res = await eventRepo.update(element)
            
            if(res.status == 200){
                let events = store.state.event.events.filter(el => el.pk != element.pk)
                store.commit('event/events', [])
                store.commit('event/events', events)
                store.commit('event/addEvent', element)
            }    
        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }


    async delete(element){
        let result = {
            status: 200
        }
        try {
            console.log(element)
            const res = await eventRepo.delete(element)
            
            if(res.status == 200){
                let events = store.state.event.events.filter(el => el.pk != element.pk)
                store.commit('event/events', [])
                store.commit('event/events', events)
            } 
            console.log(res)

        } catch (error) {
            result.status = 500;
            result.msg = error;
            return result
        }
    }
}   