import { store } from "@/store/index.js"
// import { EventBus } from "@/services/bus.js"
import { RecordingCollectionRepo } from "@/repo/RecordingCollectionRepo.js"
const recordingCollectionRepo = new RecordingCollectionRepo();

export default class RecordingCollectionService {
    async create(metaData){
        let result = {
            status: true
        }
        try {
            const res = await recordingCollectionRepo.create(metaData)
            if(res.status == 200){
                store.commit("recording/addcollections", res.data);
            }
            return result
        } catch (error) {
            result.status = false;
            result.msg = error;
            console.log(error)
            return result
        }
    }

    async fetch(lastEvaluatedKey){
        const res = await recordingCollectionRepo.fetch(lastEvaluatedKey)
        let result = {
            status: true,
            lastEvaluatedKey: "na"
        }
        try {
            console.log(res.data.Items)
            if (res.data.LastEvaluatedKey) {
                result.lastEvaluatedKey = res.data.lastEvaluatedKey;
            } else {
                result.lastEvaluatedKey = "na"
            }
            store.commit("recording/collections", [])
            res.data.Items.forEach(x => {
                store.commit("recording/addcollections", x);
            });
        } catch (error) {
            result.status = false
            result.error = error;
        }
        return result
    }

    async update(updatedRecordingCollection){
        let result = {
            status: true
        }
        try {
            const res = await recordingCollectionRepo.update(updatedRecordingCollection)
            if (res.status == 200) {
                //action goes here
                // console.log(res)
                let item = store.state.recording.collections.filter(item => item.pk == updatedRecordingCollection.pk)[0]
                item.metaData = updatedRecordingCollection.metaData
            }
            return result
        } catch (error) {
            result.status = false;
            result.msg = error;
            console.log(error)
            return result
        }
    }


    async delete() {
       
        //prepare variables
        let recordingCollection = store.state.recording.activeCollection;
        let demoData = store.state.demoData;
        let result = {
            status: true
        }
        console.log(store.state.recording.recordings)
        let anyRecordings = store.state.recording.recordings.filter(el => el.metaData.collection.pk == recordingCollection.pk)
        if(anyRecordings.length > 0){
            console.log('failed to delete')
            result.status = false
            result.error = "The collection must be empty before it can be deleted."
            return result
        }else{
            if (!demoData) {
                try {
                    const res = await recordingCollectionRepo.delete(recordingCollection)
                    let finalRecordingCollections = store.state.recording.collections.filter(item => item.pk != recordingCollection.pk)
    
                    if(res.status == 200){
                        store.commit('recording/collections',[])
                        store.commit('recording/collections', finalRecordingCollections)
                    
                    }
                    
                    return result 
                    
                } catch (error) {
                    result.error = error;
                }
            } else {
                return true
            }
        }

    }

}