import { store } from "@/store/index.js"
import axios from 'axios'

export class TeamsRepo {
    async fetch(lastEvaluatedKey) {
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                key: {},
                LastEvaluatedKey: lastEvaluatedKey
            }
        };

        return axios.get(
            `${process.env.VUE_APP_API_URL}/teams`,
            config
        )
    }

    async update(element) {
        const authorisation = store.state.token.IdToken;
       
        let config = {
            headers: {
                Authorization: authorisation
            }
        };

        let body = {
            pk: element.pk,
            metaData: element.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/teams`,
                body,
                config
            )
    }


    async delete(element) {
        const authorisation = store.state.token.IdToken;
       
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                pk: element.pk,
                sk: element.sk
            }
        };
        return axios
            .delete(
                `${process.env.VUE_APP_API_URL}/teams`,
                config
            )
    }
}