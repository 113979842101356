<template>
<div class=" w-full flex-wrap rounded-lg flex bg-transparent overflow-y-hidden">

    <div class="w-full px-1">
        <div class="flex-wrap w-full" style="height: 400px">
            <div
                 v-show="!iframe.loaded"
                 class="flex rounded-t-xl bg-gray-900 h-full items-center">
                 <span class="m-auto" :class="`text-white`">
                    Loading...
                 </span>
            </div>
            <div class='embed-container bg-black'>
            <iframe v-show="iframe.loaded"
                    class="w-full rounded-t-xl"
                    :src="iframe.src"
                    @load="load"
                    height="400"  
                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
            </iframe>
            </div>
        </div>
        <div class="rounded-b-xl p-4 w-full h-24 overflow-y-scroll" :class="`${appearance.background} ${appearance.textColour}`">
            <div class="w-full flex flex-wrap mt-2">
                <h2>{{recording.activeRecording.metaData.title}}</h2>
                <div class="w-full text-xs">
                    Created: {{recording.activeRecording.metaData.createdAt}}
                </div>
                <div class="w-full">
                    <span class="text-xs text-white rounded-full p-1 bg-gray-400">
                        {{recording.activeRecording.metaData.collection.metaData.title}}
                    </span>
                </div>
                <div class="text-sm text-gray-600">{{recording.activeRecording.metaData.description}}</div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    store
} from "@/store/index";

export default {
    data(){
        return{
            iframe:{
                src: "",
                loaded: false
            }
        }
    },
    methods:{
        load: function(){
    	    this.iframe.loaded = true;
            console.log('data loaded')
        },

    },
    computed: {
        appearance() {
            return store.state.light ? this.$AppearanceService.init("light") : this.$AppearanceService.init("dark")
        },
        recording() {
            return store.state.recording
        }
    },
    created(){
        this.iframe.src = this.recording.activeRecording.metaData.link
    }
}
</script>
<style scoped>
.vimeo-full-width {
    padding: 56.25% 0 0 0;
    position: relative;
}

.vimeo-full-width iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
