import axios from 'axios'
import { store } from "@/store/index.js"

export class HubSpotRepo {
    async create(element){
 
        let authorisation = store.state.token.IdToken;
  
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
                subject: element.subject,
                content: element.content,
                from: element.from,
                contact: element.contact
            };
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/tickets`,
                body,
                config
            )
    }
}
