import { store } from "@/store/index.js"
import axios from 'axios'

export class RecordingRepo {
    async fetch(lastEvaluatedKey) {
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                key: {},
                LastEvaluatedKey: lastEvaluatedKey
            }
        };

        return axios.get(
            `${process.env.VUE_APP_API_URL}/recordings`,
            config
        )
    }

    async create(metaData) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            metaData: metaData
        };
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/recordings`,
                body,
                config
            )
    }
    
    async update(updatedRecording) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            pk: updatedRecording.pk,
            metaData: updatedRecording.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/recordings`,
                body,
                config
            )
    }


    async delete(recording){
        let authorisation = store.state.token.IdToken;

        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                pk: recording.pk,
                sk: recording.sk
            }
        }
        
        return axios.delete(
                `${process.env.VUE_APP_API_URL}/recordings`,
                config
            )
    }


    async getVimeoThumb(video_id){
        let config = {
            headers:{
                Authorization: 'bearer e59e6be2ae606fce6c79fe7d3bfc725a',
                Accept: 'application/vnd.vimeo.*+json;version=3.4'
            }
        }
        return axios.get(
            `https://api.vimeo.com/videos/${video_id}`,
            config
        )
    }

    async getPirvateVimeoThumb(video_id){
        // let config = {
        //     headers:{
        //         Authorization: 'bearer e59e6be2ae606fce6c79fe7d3bfc725a',
        //         Accept: 'application/vnd.vimeo.*+json;version=3.4',
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
        //     }
        // }
        return axios.get(
            `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${video_id}`
        )
    }

}