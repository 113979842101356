<template>
  <div class="bg-gray-900 rounded-lg w-full">
    <div class="my-10 p-10">
      <h2 class="text-2xl text-primaryBlue">Assign a team to {{employee.metaData.fullName.firstName}}</h2>
      <h3 class="my-4 text-gray-400 leading-tight">
        Below is {{employee.metaData.fullName.firstName}}'s team link. To attach team members click the link to copy and send it to the relevent people.
      </h3>

       <div class="w-full flex mt-4 rounded-full " v-if="isManager">
        <div class="flex-none bg-primaryBlue text-sm p-3 rounded-l-full text-white">
          Invite your team
        </div>

        <div @click="clickToCopy('clickToCopyEmployeeInvite')" ref="clickToCopyEmployeeInvite" class="cursor-pointer flex-shrink  hover:bg-primaryBlue hover:bg-opacity-25 hover:text-blue-200 transition duration-500 ease-in-out bg-gray-800 text-sm p-3 text-gray-100 truncate">
          https://app.euda.co/accept-invite?tok={{
                  employee.sk.split("#")[1]
                }}-EU-{{ employee.pk.split("#")[1] }}
        </div>

        <div class="flex-shrink bg-gray-800 border-l border-gray-900 text-sm p-3 rounded-r-full text-gray-500">
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"></path><path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z"></path></svg>
        </div>

      </div>

      <h3 class="my-4 text-gray-500 text-xs leading-tight pt-4">
        Each team member that signs up via this link can be managed by {{employee.metaData.fullName.firstName}}. This includes updating details, viewing wellbeing data and sending pulse surveys.
      </h3>
    </div>
  </div>
</template>

<script>
import globalMixin from "../../mixins/globalMixin";
import { store } from "../../store/index";
export default {
  mixins: [globalMixin],
  data() {
    return {
      invitation: store.state.invitation.pk.split("#")[1],
      tenant: store.state.invitation.sk.split("#")[1]
    };
  },
  computed: {
    employee() {
      return store.state.employee;
    }
  }
};
</script>
