import { store } from "@/store/index.js"
import axios from 'axios'

export class RecordingCollectionRepo {
    async fetch(lastEvaluatedKey) {
        const authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                key: {},
                LastEvaluatedKey: lastEvaluatedKey
            }
        };

        return axios.get(
            `${process.env.VUE_APP_API_URL}/recording_collections`,
            config
        )
    }

    async create(metaData) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            metaData: metaData
        };
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/recording_collections`,
                body,
                config
            )
    }
    
    async update(updatedRecording) {
        let authorisation = store.state.token.IdToken;
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body = {
            pk: updatedRecording.pk,
            metaData: updatedRecording.metaData
        };
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/recording_collections`,
                body,
                config
            )
    }


    async delete(recording){
        let authorisation = store.state.token.IdToken;

        let config = {
            headers: {
                Authorization: authorisation
            },
            params: {
                pk: recording.pk,
                sk: recording.sk
            }
        }
        
        return axios.delete(
                `${process.env.VUE_APP_API_URL}/recording_collections`,
                config
            )
    }

}