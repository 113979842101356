import { store } from "@/store/index.js"
import axios from 'axios'

export class EmployeeRepo {
    async fetch(){
       
      let config = {
        headers: { Authorization: store.state.token.IdToken },
        params: {
          type: "team",
          team: store.state.selectedTeam.pk,
          LastEvaluatedKey: "na",
        },
      };

      return axios
        .get(`${process.env.VUE_APP_API_URL}/employees`, config)
       
    }
    async create(employee, creatorData){
        let authorisation = store.state.token.IdToken;
  
        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body;

            body = {
                status: 'pending',
                team: employee.team,
                metaData: employee.metaData,
                company: employee.companyData,
                creatorData: creatorData
            };
        
        
        return axios
            .post(
                `${process.env.VUE_APP_API_URL}/employees`,
                body,
                config
            )
    }

    async update(edittedItem, type) {

        let authorisation = store.state.token.IdToken;
        let pk = edittedItem.pk;

        let config = {
            headers: {
                Authorization: authorisation
            }
        };
        let body;

        if(type == 'settings'){
            body = {
                pk: pk,
                type: type,
                metaData: edittedItem.metaData
            };
        }else if(type == 'team'){
            body = {
                pk: pk,
                type: type,
                team: edittedItem.team
            };
        }else if(type == 'hasRole'){
            body = {
                pk: pk,
                type: type,
                hasRole: edittedItem.hasRole
            };
        }else if(type == 'nudge'){
            body = {
                pk: pk,
                type: type
            };
        }
        
        return axios
            .put(
                `${process.env.VUE_APP_API_URL}/employees`,
                body,
                config
            )
    }
}